import { notify } from './notify.js';
import { slides } from './slides.js';

export const project = {
    cellMap: {},

    createNew(name, sdtData) {
        let { cells, stimuli, stimuliLogicList } =
            project.parseSDTData(sdtData);

        let newProject = {
            name,
            cancelLink: '',
            redirectLink: '',
            referrerLink: 1,
            language: 'en',
            active: 0,
            isArchived: false,
            stimuliLogicList,
            cells,
            stimuli,
            tasks: [],
            groups: [],
            // tasksRandomization: 'all',
            slides,
        };
        return newProject;
    },
    /**
     *
     * @param {Object} sdtData
     * @returns {Object}
     */
    parseSDTData(sdtData) {
        let cells = [];
        let stimuli = [];
        let stimuliLogicList = [];

        for (let i in sdtData) {
            cells.push(project.createNewCell(`cell${i}`));
            stimuli = [
                ...stimuli,
                ...project.updateStimuliList(sdtData[i], i, stimuli),
            ];
            stimuliLogicList = [
                ...project.updateStimuliLogic(sdtData[i], i, stimuliLogicList),
            ];
        }

        return {
            stimuliLogicList,
            cells,
            stimuli,
        };
    },
    /**
     * @param {Object} cellData
     * @param {number} cellNumber
     * @param {Array} stimuliLogicList
     * @returns {Array}
     */
    updateStimuliLogic(cellData, cellNumber, stimuliLogicList) {
        for (let i in cellData) {
            if (!stimuliLogicList[i - 1]) {
                stimuliLogicList[i - 1] = {};
            }

            stimuliLogicList[i - 1][`cell${cellNumber}`] = cellData[i].s;
        }
        return stimuliLogicList; //stimuliLogicListWithoutEmptyValues
    },
    /**
     * @param {task_stimuli_logic} stimuliLogic
     * @param {number} cell
     * @param {Array.<task_stimuli>} stimuliList
     * @returns {Array.<task_stimuli>}
     */
    updateStimuliList(stimuliLogic, cell, stimuliList) {
        const newStimuliList = [];

        for (let i in stimuliLogic) {
            let path = stimuliLogic[i].s;
            const isWatermarked = stimuliLogic[i].w;
            /** @type task_stimuli */
            let stimuli = stimuliList.find((el) => el.path === path);

            if (path) {
                if (!stimuli) {
                    newStimuliList.push(
                        this.createStimuliObject(
                            path,
                            cell,
                            stimuliLogic[i]?.url,
                            i,
                            isWatermarked,
                        ),
                    );
                } else {
                    if (stimuli.logic == i) {
                        stimuli.cells.push(Number(cell));
                    } else {
                        newStimuliList.push(
                            this.createStimuliObject(
                                path,
                                cell,
                                stimuliLogic[i]?.url,
                                i,
                                isWatermarked,
                            ),
                        );
                    }
                }
            }
        }

        return newStimuliList;
    },
    /**
     *
     * @param {string} path
     * @param {number} cell
     * @param {url} url
     * @param {string} stimuliLogic
     * @param {boolean} isWatermarked
     * @returns {task_stimuli}
     */
    createStimuliObject(path, cell, url, stimuliLogic, isWatermarked = false) {
        const newStim = {
            path,
            name: path.substring(path.lastIndexOf('/') + 1),
            cells: [Number(cell)],
            logic: stimuliLogic,
            isWatermarked,
        };

        if (url) {
            newStim.url = url;
        }

        return newStim;
    },
    /**
     *
     * @param {String} name
     * @returns {project_cell}
     */
    createNewCell(name) {
        return {
            id: `${name}-${Date.now()}`,
            name,
            language: 'en',
            cancelLink: '',
            redirectLink: '',
            videoQualityRange: '50',
            clickCalibration: 1,
            faceIllumination: '40',
            faceSize: 1,
            glasses: 1,
            blinkingEnabled: 1,
            processingOption: 0,
            active: 1,
        };
    },

    mergeNewListOfStimuliFromSDT(sdtData) {
        const arrOfStimuli = [];

        for (let cellKey in sdtData) {
            for (let stimuliLogicKey in sdtData[cellKey]) {
                const sdtStimPath = sdtData[cellKey][stimuliLogicKey]?.s;
                const isWatermarked = sdtData[cellKey][stimuliLogicKey]?.w;

                /** @type task_stimuli */
                const stimuliThatAlreadyExists = arrOfStimuli.find(
                    (el) =>
                        el.path == sdtStimPath && el.logic == stimuliLogicKey,
                );

                if (sdtStimPath) {
                    if (stimuliThatAlreadyExists) {
                        if (stimuliThatAlreadyExists.logic == stimuliLogicKey) {
                            stimuliThatAlreadyExists.cells.push(+cellKey);
                        }

                        //* Added when realised that stimulus is only being added once with first stimuli logic that it was found with, if some stimulus exists in multiple stimuliLogics it should be displayed in all of them.
                        if (
                            !arrOfStimuli.find(
                                (el) =>
                                    el.path == sdtStimPath &&
                                    stimuliLogicKey == el.logic,
                            )
                        ) {
                            const stim = {
                                ...stimuliThatAlreadyExists,
                                cells: [+cellKey],
                                logic: stimuliLogicKey,
                            };

                            if (sdtData[cellKey][stimuliLogicKey]?.url) {
                                stim.url =
                                    sdtData[cellKey][stimuliLogicKey].url;
                            }

                            arrOfStimuli.push(stim);
                        }
                    } else {
                        arrOfStimuli.push(
                            this.createStimuliObject(
                                sdtStimPath,
                                cellKey,
                                sdtData[cellKey][stimuliLogicKey].url,
                                stimuliLogicKey,
                                isWatermarked,
                            ),
                        );
                    }
                }
            }
        }
        // cells: [1]
        // logic: "1"
        // name: "stimuli_1.jpg"
        // path: "https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/202023102/assets/stimuli_1.jpg"

        return [...arrOfStimuli];
    },
    /**
     *
     * @param {Object} sdtData
     * @param {project} project
     * @returns {Array.<project_cell>}
     */
    mergeNewListOfCellsFromSDT(sdtData, project) {
        const determineChangeInCellAndNotify = (sdtData, project) => {
            const messages = [];
            console.log(
                '🚀 ~ file: project.js ~ line 115 ~ determineChangeInCellAndNotify ~ sdtData',
                sdtData,
            );

            project.cells.forEach((oldCell) => {
                //* If cell is not in new sdtData, then that means the cell is deleted. If not check if there is something changed in
                const existingCell = Object.keys(sdtData).find(
                    (cellNumber) => `cell${cellNumber}` == oldCell.name,
                );

                if (!existingCell) {
                    messages.push({
                        message: `${oldCell.name} has been deleted!`,
                        type: 'deleted',
                        messageDisplayLength: 22222220000,
                    });
                    // notify.deleted(`💣 ${oldCell.name} has been deleted!`, 22222220000);
                } else {
                    const cellNumber = oldCell.name.split(' ')[1];

                    for (let stimLogicNum in sdtData[cellNumber]) {
                        project.stimuliLogicList.forEach((stimLogic, index) => {
                            const stimuliLogicNumFromProject = index + 1;

                            if (stimuliLogicNumFromProject == stimLogicNum) {
                                for (let cellName in stimLogic) {
                                    if (oldCell.name == cellName) {
                                        if (
                                            sdtData[cellNumber][stimLogicNum]
                                                .s != stimLogic[cellName]
                                        ) {
                                            // messages.push({
                                            //   message: `${oldCell.name} has changed stimulus in stimuli logic ${stimuliLogicNumFromProject}!`,
                                            //   type: 'changed',
                                            //   messageDisplayLength: 22222220000
                                            // })
                                            // notify.changed(`💫💡 ${oldCell.name} has changed stimulus in stimuli logic ${stimuliLogicNumFromProject}!`, 22222220000);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            });
            notify.displayMessages(messages);
        };
        const newCells = [];
        const oldCells = [...project.cells]; //* [1,2,3] -> [1,2]
        //* Check if cell with that name already exists, if it exists then I should leave the cell as it is, if not create new one

        determineChangeInCellAndNotify(sdtData, project);
        const messages = [];

        for (let key in sdtData) {
            const sameCell = oldCells.find((c) => c.name == `cell${key}`);

            if (sameCell) {
                newCells.push(JSON.parse(JSON.stringify(sameCell)));
            } else {
                newCells.push(this.createNewCell(`cell${key}`));
                messages.push({
                    type: 'success',
                    message: `New cell created: Cell${key}`,
                    messageDisplayLength: 22222220000,
                });
                // notify.created(`🚀 New cell created: Cell${key}`, 22222220000)
            }
        }
        notify.displayMessages(messages);
        return newCells;
    },
    /**
     *
     * @param {Object} sdtData
     * @returns {Array.<task_stimuli_logic>}
     */
    mergeNewStimuliLogicListFromSDT(sdtData) {
        const arrOfStimuliList = [];
        // debugger;
        for (let cellKey in sdtData) {
            for (let stimuliLogicKey in sdtData[cellKey]) {
                if (sdtData[cellKey][stimuliLogicKey]?.s) {
                    arrOfStimuliList.push({
                        cell: cellKey,
                        stimuliLogicNum: stimuliLogicKey,
                        path: sdtData[cellKey][stimuliLogicKey]?.s,
                        isWatermarked: sdtData[cellKey][stimuliLogicKey]?.w,
                    });
                }
            }
        }
        const objOfStimuliList = {};

        arrOfStimuliList.forEach((el) => {
            if (objOfStimuliList[el.stimuliLogicNum]) {
                objOfStimuliList[el.stimuliLogicNum] = {
                    ...objOfStimuliList[el.stimuliLogicNum],
                    ['cell' + el.cell]: el.path,
                };
            } else {
                objOfStimuliList[el.stimuliLogicNum] = {
                    ['cell' + el.cell]: el.path,
                };
            }
        });
        const resultArr = [];

        // for(let key in objOfStimuliList) {
        //   resultArr.push(objOfStimuliList[key])
        // }
        //! there are situations when they create stimuli logics after deleting some. so there is a hole in stimuli logic arr, exapmple 1: {...}, 3: {...}
        //! To avoid message that some stimuli logic is deleted even if it was not I went for the same solution as in creating project
        for (let key in objOfStimuliList) {
            resultArr[key - 1] = objOfStimuliList[key];
        }
        console.log(
            '🚀 ~ file: project.js ~ line 218 ~ mergeNewStimuliLogicListFromSDT ~ resultArr',
            resultArr,
        );

        // 0:
        //   cell1: "https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/202023102/assets/stimuli_1.jpg"
        //   cell2: "https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/202023102/assets/stimuli_2.jpg"
        // 1:
        //   cell1: "https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/202023102/assets/stimuli_6.mp4"
        //   cell2: "https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/202023102/assets/stimuli_6.mp4"

        return [...resultArr];
    },
};
