import './../typedefs';
import {
    findAllMinTimeouts,
    findAllRedirectAndCancelLinks,
    findAllStimuliExposures,
    findAllTaskNames,
    findAllUrlsInProject,
    findAllWebTasks,
    findAllClickTasks,
    findAllStimuliInWebTasks,
} from './getters';
/**
 * Project validation starts here
 * @param { project } project
 * @returns boolean
 */
function validateProject(project) {
    const links = [...findAllUrlsInProject(project)];
    if (links.find((link) => !isValidHttpUrl(link))) {
        return false;
    }

    const linksWithHttp = [...findAllRedirectAndCancelLinks(project)];
    if (linksWithHttp.find((link) => !isValidHttpUrl(link, true))) {
        return false;
    }

    const allStimuliExposures = [
        ...findAllStimuliExposures(project),
        ...findAllMinTimeouts(project),
    ];

    if (allStimuliExposures.filter((timeout) => timeout < 1).length > 0) {
        return false;
    }

    const allClickAndWebTasks = [
        ...findAllWebTasks(project),
        ...findAllClickTasks(project),
    ];
    if (
        allClickAndWebTasks.find(
            (task) => !isTimeoutGreatherThanMinExposure(task),
        )
    ) {
        return false;
    }

    const allTaskNames = findAllTaskNames(project);
    if (allTaskNames.find((name) => isSpaceInString(name))) {
        return false;
    }

    if (twoTasksWithSameName(allTaskNames)) {
        return false;
    }

    const allWebStimuli = findAllStimuliInWebTasks(project);
    if (allWebStimuli.find((s) => Number(s.screenHeight) < 0)) {
        return false;
    }

    return true;
}
/**
 * @param {Array.<String>} allTaskNames
 * @returns { boolean }
 */
function twoTasksWithSameName(allTaskNames) {
    return (
        allTaskNames.filter(
            (name) =>
                allTaskNames.filter((nameInner) => name == nameInner).length >
                1,
        ).length > 0
    );
}

/**
 * Function which displays error label if input is not valid
 * @param { Event } e
 * @param { Array } namesToCheck
 * @param { Function } validationFunction
 * @param { HTMLElement[] } [errorMsgDivs]
 */
const validateElement = (
    e,
    namesToCheck,
    validationFunction,
    errorMsgDivs = null,
) => {
    if (namesToCheck.includes(e.target.name)) {
        if (!validationFunction(e.target.value)) {
            if (!errorMsgDivs) {
                e.target.previousElementSibling.classList.remove('hidden');
            } else {
                Array.from(errorMsgDivs).forEach((el) => {
                    el.classList.remove('hidden');
                });
            }
        } else {
            if (!errorMsgDivs) {
                e.target.previousElementSibling.classList.add('hidden');
            } else {
                Array.from(errorMsgDivs).forEach((el) => {
                    el.classList.add('hidden');
                });
            }
        }
    }
};
/**
 *
 * @param {HTMLElement} element
 * @param {String} className
 * @returns {HTMLElement}
 */
const findParentElement = (element, className) => {
    if (
        element.tagName.toLowerCase() === 'html' ||
        element.tagName.toLowerCase() === 'body' ||
        element.tagName.toLowerCase() === 'head'
    ) {
        return false;
    }

    if (element.classList.contains(className)) {
        return element;
    }

    return findParentElement(element.parentElement, className);
};
/**
 * Checks is string a valid URL address
 * @param {String} string
 * @returns {Boolean}
 */
const isValidHttpUrl = (string, http = false) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    if (http) {
        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    return url.protocol === 'https:';
};

/**
 * @param {task} task
 * @returns Boolean
 */
const isTimeoutGreatherThanMinExposure = (task) => {
    return (
        Number(task.timeout == undefined ? 60 : task.timeout) >=
        Number(
            task.minExposureTimeout == undefined ? 0 : task.minExposureTimeout,
        )
    );
};

/**
 * Checks is project object valid before saving
 * @param { project } project
 * @returns {Boolean}
 */
const isProjectValid = function (project) {
    let eachStimuliLogicGroupHasStimuli = (task) => {
        for (let i = 0; i < task.stimuliLogicGroups.length; i++) {
            let group = task.stimuliLogicGroups[i];
            if (group.stimuliLogicList.length === 0) return false;
        }
        return true;
    };

    let taskHasStimuliLogicGroups = (task) => {
        return task.stimuliLogicGroups.length > 0;
    };

    let eachTaskGroupHasStimuli = (groups) => {
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.tasks.length === 0) return false;
            if (!eachTaskHasStimuli(group.tasks)) return false;
        }
        return true;
    };

    /**
     * 
     * @param {task[]} tasks 
     * @returns {Boolean}
     */
    let eachTaskHasStimuli = (tasks) => {
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.type === 'survey') {
                if (!task.iframeURL) return false;
            } 
            else if(task.type === 'VS') {
                if(!task.links || !task.links.length || task.links.length !== project.cells.length) {
                    return false
                }
            }
            else {
                if (
                    !taskHasStimuliLogicGroups(task) &&
                    task.stimuliLogicList.length === 0
                )
                    return false;
                if (
                    taskHasStimuliLogicGroups(task) &&
                    !eachStimuliLogicGroupHasStimuli(task)
                )
                    return false;
            }
        }
        return true;
    };

    return (
        eachTaskHasStimuli(project.tasks) &&
        eachTaskGroupHasStimuli(project.groups)
    );
};
/**
 *
 * @param {HTMLElement} wrapper
 * @param {string} errorMsgClass
 */
function clearErrorMessagesInWrapper(wrapper, errorMsgClass = '') {
    [...wrapper.querySelectorAll(errorMsgClass || '.error-msg')].forEach(
        (errMsgElement) => {
            errMsgElement.classList.add('hidden');
        },
    );
}
/**
 *
 * @param {HTMLElement} wrapper
 * @param {string} errorMsgClass
 */
function displayErrorMessagesInWrapper(wrapper, errorMsgClass = '') {
    [...wrapper.querySelectorAll(errorMsgClass || '.error-msg')].forEach(
        (errMsgElement) => {
            errMsgElement.classList.remove('hidden');
        },
    );
}
/**
 * Searches through task to find if all stimuli logics are preloaded
 * @param {task} task
 * @returns {Boolean}
 */
function areAllStimuliLogicsPreloaded(task) {
    return (
        !!!task.stimuliLogicList.find((logic) => !logic.preload) &&
        task.stimuliLogicList.length > 0
    );
}
/**
 * @param {String} str
 * @returns {Boolean}
 */
function isSpaceInString(str) {
    return str.indexOf(' ') > -1;
}

const urlValidator = (e) =>
    !isValidHttpUrl(e.target.value) && e.target.value !== ''
        ? 'URL must be valid and also contain https protocol'
        : '';
        
const nameValidator = (e) =>
    e.target.value.includes(' ') ? 'No spaces allowed' : '';

export {
    validateProject,
    isValidHttpUrl,
    validateElement,
    isTimeoutGreatherThanMinExposure,
    isProjectValid,
    clearErrorMessagesInWrapper,
    displayErrorMessagesInWrapper,
    findParentElement,
    areAllStimuliLogicsPreloaded,
    isSpaceInString,
    urlValidator,
    nameValidator,
};
