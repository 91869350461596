import React from 'react';
import ProjectContext from '../../context/ProjectContext';
import InputToggle from '../InputToggle';
import GroupTasks from './GroupTasks';
import Introslide from './Introslide';

let randomizationOptions = [
    {
        key: 'randomizeAll',
        text: 'Randomize all tasks',
    },
    {
        key: 'selectOne',
        text: 'Randomly select one task',
    },
];

export default function TaskGroup({
    group,
    setGroupsAndTasksInProject,
    addTaskToGroup,
    removeFromGroup,
}) {
    const { project, updateGroup } = React.useContext(ProjectContext);

    let changeRandomizationType = (e) => {
        let groupUpdated = { ...group };
        groupUpdated.type = e.target.value;
        updateGroup(groupUpdated);
    };

    let toggleGroupCalibration = (e) => {
        let groupUpdated = { ...group };
        groupUpdated.recalibrate = e.target.checked;
        updateGroup(groupUpdated);
    };

    const toggleShowGenericInstructions = (checked, prop) => {
        let groupUpdated = { ...group };
        groupUpdated[prop] = checked;
        updateGroup(groupUpdated);
    };

    return (
        <div className="segment task-group-container">
            <div className="text-input segment-section">
                <div className="randomization-options flex task-group--align-center">
                    {randomizationOptions.map((option, i) => (
                        <p key={i}>
                            <label>
                                <input
                                    className="with-gap"
                                    value={option.key}
                                    type="radio"
                                    checked={group.type == option.key}
                                    onChange={changeRandomizationType}
                                    disabled={!!project.ptIntegration}
                                />
                                <span>{option.text}</span>
                            </label>
                        </p>
                    ))}

                    <div className="move-recalibration-checkbox-left">
                        <label>
                            <input
                                type="checkbox"
                                className={`filled-in ${
                                    !!project.ptIntegration && 'disabled'
                                }`}
                                name="group-recalibration"
                                checked={!!group.recalibrate}
                                onChange={toggleGroupCalibration}
                                disabled={!!project.ptIntegration}
                            />
                            <span>Group recalibration</span>
                        </label>
                    </div>

                    <div className="text-input segment-section">
                        <label>Show generic instructions</label>
                        <InputToggle
                            isChecked={group.showGenericInstructions}
                            disabled={!!project.ptIntegration}
                            handleChange={(checked) =>
                                toggleShowGenericInstructions(
                                    checked,
                                    'showGenericInstructions',
                                )
                            }
                            labels={['Hide', 'Show']}
                        />
                    </div>
                </div>
            </div>

            <Introslide element={group} update={updateGroup} type="group" />

            <GroupTasks
                group={group}
                // project={project}
                setGroupsAndTasksInProject={setGroupsAndTasksInProject}
                addTaskToGroup={addTaskToGroup}
                removeFromGroup={removeFromGroup}
            />
        </div>
    );
}
