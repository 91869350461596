import React from 'react';
import { useContext } from 'react';
import ProjectContext from '../../context/ProjectContext';
import { setTaskTypeAndTypeDependentProps } from '../../helpers';
import './../../typedefs';

export default function TaskType({ task, disabled }) {
    const types = ['watch', 'video', 'click', 'web', 'survey', 'VS'];
    const { updateTask, project } = useContext(ProjectContext);

    let changeType = (e) => {
        updateTask(setTaskTypeAndTypeDependentProps(e, task, project.cells));
    };

    const getType = (type) => {
        if (type.indexOf('_') > -1) {
            return String(type).replace('_', ' ');
        }

        return type;
    };

    return (
        <div className="text-input task-type">
            <label>Type</label>
            <select
                className="browser-default"
                value={task.type}
                name={'type'}
                onChange={changeType}
                disabled={disabled}
            >
                {types.map((type) => (
                    <option value={type} key={type}>
                        {' '}
                        {getType(type)}{' '}
                    </option>
                ))}
            </select>
        </div>
    );
}
