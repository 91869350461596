import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Projects from './components/Projects/Projects.jsx';
import Project from './components/Project/Project.jsx';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './api/query';

function App() {
    return (
        <div id="app">
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Projects} />
                        <Route
                            exact
                            path={`/project/:id`}
                            component={Project}
                        />
                    </Switch>
                </Router>
                {/**<ReactQueryDevtools initialIsOpen={false}/>**/}
            </QueryClientProvider>
        </div>
    );
}

export default App;
