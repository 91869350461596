import React from 'react';
import UserMenu from './UserMenu.jsx';
import "./navigation.css";
import logo from "./images/logo.png";

export default function Navigation(props) {
  return (
    <div id="navigation" className="eyesee-header">
      <div className="logo">
        <img src={props.appLogo || logo} alt={props.title} />
      </div>

      {
        <UserMenu user={props.user} appName={props.appName}/>
      }

    </div>
  )
}
