import {
    setAtPositionForStimuliLogicInsideTheGroup,
    sortByAtPosition,
} from './at-position-helpers';
import { getNextNameNumber } from './task-naming-helpers';
import { areAllStimuliLogicsPreloaded } from './validators';

/**
 *
 * @param {task_stimuli_logic} stimuliLogic
 * @param {Number} logicIndex
 * @param {*} stimuliMap
 * @returns
 */
function getStimuliFromLogic(stimuliLogic, logicIndex, stimuliMap) {
    let list = [];

    for (let key in stimuliLogic) {
        let path = stimuliLogic[key];
        let name = path.substring(path.lastIndexOf('/') + 1);
        let newStimulus = {
            id: name,
            name,
            path,
            cells: [key],
            exitTrigger: 'clickOnExit',
            logic: logicIndex + 1,
        };

        if (!stimuliMap[newStimulus.path + ' ' + newStimulus.logic]) {
            stimuliMap[newStimulus.path + ' ' + newStimulus.logic] = true;
            list.push(newStimulus);
        } else {
            let st = list.find((el) => el.path === newStimulus.path);
            st?.cells.push(key);
        }
    }

    return list;
}
/**
 *
 * @param {project} project
 * @param {Array<string>} selected
 */
export function getStimuliForProject(project, selected, stimuliMap) {
    let stimuli = [];

    for (let i = 0; i < selected.length; i++) {
        let logicIndex = Number(selected[i]);
        /** @type { Array.<task_stimuli_logic> } */
        let stimuliLogic = project.stimuliLogicList[logicIndex];
        stimuli.push(
            ...getStimuliFromLogic(stimuliLogic, logicIndex, stimuliMap),
        );
    }

    return stimuli;
}
/**
 *
 * @param {project} project
 * @param {task} taskUpdated
 * @param {Array<task_stimuli>} stimuli
 * @returns {Array<task_stimuli>}
 */
export function mapStimuliToMatchOld(project, taskUpdated, stimuli) {
    return stimuli.map((s) => {
        const { url } = project.stimuli.find(
            (stimulus) => stimulus.name === s.name,
        );
        const elBeforeChange = taskUpdated?.stimuli?.find(
            (taskStim) => s.name == taskStim.name,
        );
        if (elBeforeChange) {
            const retObj = {
                ...elBeforeChange,
                ...s,
            };

            if (url) {
                retObj.url = url;
            }

            return retObj;
        }

        const retObj = {
            ...s,
        };

        if (url) {
            retObj.url = url;
        }

        return retObj;
    });
}

/**
 * This fn will get all selected stimuli logics with its props ( excluding those which are in some group). This is used so we can display stimuli logics beneath.
 * @param {task} taskUpdated
 * @param {Array<string>} selected
 * @returns { }
 */
export function mapStimuliLogicToMatchSelected(taskUpdated, selected) {
    return selected
        .map((index) => {
            const stimuliAlreadyDisplayed = taskUpdated.stimuliLogicList.find(
                (st) => st.index == index,
            );
            if (stimuliAlreadyDisplayed) {
                return {
                    ...stimuliAlreadyDisplayed,
                };
            }
            /** @type { task_stimuli_logic } */
            const objToReturn = {
                index: Number(index),
                id: Number(index) + 1,
                atPosition: null,
                _draggableType: 'stimuli_logic',
                _type: 'stimuli_logic',
                _isPinned: false,
                _name: `Stimuli logic ${Number(index) + 1}`,
            };

            if (taskUpdated.type === 'video') {
                objToReturn.preload = taskUpdated.options.preload;
            }

            return objToReturn;
        })
        .filter(
            (st) =>
                !taskUpdated.stimuliLogicGroups.find((g) =>
                    g.stimuliLogicList?.find((stl) => st._name == stl._name),
                ),
        ); //* Remove those who are in some stimuliLogicGroup
}

/**
 *
 * @param { Array.<task_stimuli_logic> } stimuliLogicList
 * @param { Array.<task_stimuli_logic_group> } stimuliLogicGroups
 * @param {task} taskToUse
 * @param {Function} updateTaskFN
 */
export const setStimuliLogicListAndGroupsInProject = (
    stimuliLogicList,
    stimuliLogicGroups,
    taskToUse,
    updateTaskFN,
) => {
    /** @type {task} */
    var taskUpdated = { ...taskToUse, stimuliLogicList, stimuliLogicGroups };
    updateTaskFN(taskUpdated); //, taskUpdated.id
};
/**
 *
 * @param {project} project
 * @param {task} task
 * @returns {Object}
 */
export function createStimuliLogicObj(project, task) {
    /** @type {task} */
    let taskUpdated = { ...task };

    const num = getNextNameNumber(
        task.stimuliLogicGroups.length > 0 ? task.stimuliLogicGroups : [],
        '_name',
    );

    /** @type {task_stimuli_logic_group} */
    let newStimuliLogicGroup = {
        _name: `Group SL ${num}`,
        _draggableType: 'stimuli_logic',
        _type: 'stimuli_logic_group',
        _isPinned: false,
        index: project.stimuliLogicList.length + num + 100,
        id: project.stimuliLogicList.length + num + 100,
        atPosition: null,
        stimuliLogicList: [],
    };

    taskUpdated.stimuliLogicGroups.push(newStimuliLogicGroup);

    return {
        taskUpdated,
        newStimuliLogicGroup,
    };
}
/**
 *
 * @param {project} project
 * @param {task} task
 * @param {Array<string>} selected
 * @returns
 */
export function modifyTaskAndSortStimuliLogics(project, task, selected) {
    let stimuliMap = {};

    /** @type { task } */
    let taskUpdated = { ...task };
    let stimuli = [...getStimuliForProject(project, selected, stimuliMap)];

    taskUpdated.stimuli = mapStimuliToMatchOld(project, taskUpdated, stimuli);
    taskUpdated.stimuliLogicList = mapStimuliLogicToMatchSelected(
        taskUpdated,
        selected,
    );
    taskUpdated.stimuliLogicGroups = taskUpdated.stimuliLogicGroups.map(
        (g) => ({
            ...g,
            stimuliLogicList: setAtPositionForStimuliLogicInsideTheGroup(g, {
                stimuliGroupChanged: g.stimuliLogicList.filter((stl) =>
                    selected.find((index) => stl.index == index),
                ),
            }),
        }),
    );

    return {
        taskUpdated,
        sortedSL: sortByAtPosition([
            ...taskUpdated.stimuliLogicList,
            ...taskUpdated.stimuliLogicGroups,
        ]),
    };
}
/**
 *
 * @param {task} task
 * @param {task_stimuli_logic} draggedElement
 * @param {task_stimuli_logic_group} onDroppedElement
 */
export function putStimuliLogicInGroup(task, draggedElement, onDroppedElement) {
    /** @type { task } */
    const newTask = { ...task };

    draggedElement._isPinned = false;
    draggedElement.atPosition = null;

    onDroppedElement.stimuliLogicList.push(draggedElement);
    newTask.stimuliLogicGroups = newTask.stimuliLogicGroups.map((g) =>
        g.id == onDroppedElement.id ? onDroppedElement : g,
    );
    newTask.stimuliLogicList = newTask.stimuliLogicList.filter(
        (st) => st.id != draggedElement.id,
    );

    return newTask;
}
/**
 *
 * @param {task_stimuli_logic_group} slGroup
 * @param {task} task
 * @param {Array.<task_stimuli_logic | task_stimuli_logic_group} stimuliLogicsAndGroups
 * @returns {Array.<task_stimuli_logic | task_stimuli_logic_group}
 */
export function removeStimuliLogicsFromGroupAndMergeWithOthers(
    slGroup,
    task,
    stimuliLogicsAndGroups,
) {
    /** @type {task_stimuli_logic_group } */
    const deletedStimuliLogicList = task.stimuliLogicGroups
        .find((g) => g.id == slGroup.id)
        .stimuliLogicList.map((sg) => ({
            ...sg,
            atPosition: null,
            _isPinned: false,
        }));

    const newStimuliLogicsAndGroups = stimuliLogicsAndGroups.filter(
        (t) => t.id != slGroup.id,
    );
    return [...newStimuliLogicsAndGroups, ...deletedStimuliLogicList];
}

export const determineProps = (task, name) => {
    const props = {
        task,
        inputName: name,
        defaultValue: task.type === 'watch' ? 10 : 60,
        labelText:
            name === 'minExposureTimeout'
                ? 'Min time before next button appears'
                : 'Stimuli exposure',
        errorLabelText:
            task.type === 'watch'
                ? 'No numbers less than 1'
                : 'No numbers less than 1 and min exposure must be less than stimuli timeout',
        wrapperClasses: `text-input ${
            task.type === 'web' ? 'web-timeout-input' : 'stimulus-visibility'
        }`,
    };

    return props;
};

/**
 * Changes stimuli inside of task and calls updateTask function
 * @param {task_stimuli} stimuli
 * @param {task} task
 * @param {Function} updateTaskFN
 */
export const updateStimulus = (stimuli, task, updateTaskFN) => {
    const taskUpdated = {
        ...task,
        stimuli: task.stimuli.map((s) =>
            s.id == stimuli.id && s.logic == stimuli.logic ? stimuli : s,
        ),
    };

    updateTaskFN(taskUpdated);
};

const areStimuliLogicsSame = (logic, stimuliLogic) =>
    logic.id === stimuliLogic.id && logic.index === stimuliLogic.index;

export const updateStimuliLogicPreload = (
    e,
    task,
    stimuliLogic,
    updateTaskFN,
) => {
    let updatedTask = {
        ...task,
        stimuliLogicList: task.stimuliLogicList.map((logic) =>
            areStimuliLogicsSame(logic, stimuliLogic)
                ? {
                      ...logic,
                      preload: e.target.checked,
                  }
                : logic,
        ),
    };

    const isTaskPreloaded = areAllStimuliLogicsPreloaded(updatedTask);
    updatedTask.options.preload = isTaskPreloaded;
    updateTaskFN(updatedTask);
};

export function removeStimuliLogicFromGroup(task, stimulusLogic) {
    const newGroupList = task.stimuliLogicGroups.map((g) => ({
        ...g,
        stimuliLogicList: sortByAtPosition(
            g.stimuliLogicList.filter((stl) => stl.id != stimulusLogic.id),
        ),
    }));

    stimulusLogic._isPinned = false;
    stimulusLogic.atPosition = null;

    const stimuliLogicList = [...task.stimuliLogicList, stimulusLogic];

    const mergedGroupsAndLogics = [...stimuliLogicList, ...newGroupList];
    const sortedGroupsAndTask = sortByAtPosition(mergedGroupsAndLogics);
    return {
        sortedGroupsAndTask,
        stimuliLogicList,
        newGroupList,
    };
}

/**
 * @param {Array.<task_stimuli_logic | task_stimuli_logic_group>} stimuliLogicsAndGroups
 * @returns {Array.<task_stimuli_logic | task_stimuli_logic_group>}
 */
export function sortStimLogicsAndStimGroups(stimuliLogicsAndGroups) {
    /** @type { Array.<task_stimuli_logic_group> } */
    const groups = stimuliLogicsAndGroups
        .filter((s) => s._type == 'stimuli_logic_group')
        .map((g) => ({
            ...g,
            stimuliLogicList: sortByAtPosition(g.stimuliLogicList),
        }));

    /** @type { Array.<task_stimuli_logic | task_stimuli_logic_group> } */
    const lists = stimuliLogicsAndGroups.filter(
        (s) => s._type == 'stimuli_logic',
    );
    const groupsAndListsMerged = [...groups, ...lists];
    const sortedStimuliLogicsAndGroups = sortByAtPosition(groupsAndListsMerged);

    return sortedStimuliLogicsAndGroups;
}
