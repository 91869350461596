import React, { useEffect, useLayoutEffect, useState } from 'react';
import M from 'materialize-css';

import DraggableContainer from './DraggableContainer.jsx';
import StimuliLogicView from './StimuliLogicView.jsx';
import {
    sortByAtPosition,
    sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted,
    setAtPositionForStimuliLogicAndGroups,
    setAtPositionForStimuliLogicInsideTheGroup,
} from '../../helpers/at-position-helpers.js';
import ProjectContext from '../../context/ProjectContext';
import AfterSyncInfoContext from '../../context/AfterSyncInfoContext';
import {
    createStimuliLogicObj,
    determineProps,
    modifyTaskAndSortStimuliLogics,
    putStimuliLogicInGroup,
    removeStimuliLogicFromGroup,
    removeStimuliLogicsFromGroupAndMergeWithOthers,
    setStimuliLogicListAndGroupsInProject,
    sortStimLogicsAndStimGroups,
} from '../../helpers/TaskStimuli.js';
import StimuliLogicSelect from './StimuliLogicSelect.jsx';
import TaskExposureInput from './TaskExposureInput.jsx';
import './../../typedefs';

export default function TaskStimuli({ task }) {
    const [stimuliLogicsAndGroups, setStimuliLogicsAndGroups] = useState(() => {
        /** @type { Array.<task_stimuli_logic | task_stimuli_logic_group} */
        const mergedArr = [
            ...task.stimuliLogicList,
            ...task.stimuliLogicGroups.map((slGroup) => ({
                ...slGroup,
                stimuliLogicList: sortByAtPosition(slGroup.stimuliLogicList),
            })),
        ];

        //* This will be executed only on initial load ( which does not change anything ), and more importanly when I copy some task or group! Than I need to sort stimuli logics inside them.
        if (mergedArr.length > 0) {
            return sortByAtPosition(mergedArr);
        }
        return [];
    });
    const { project, updateTask } = React.useContext(ProjectContext);
    const { canSortTasksAndGroups, isProjectSynced } =
        React.useContext(AfterSyncInfoContext);

    let [elem, setElem] = useState(null);

    useEffect(() => {
        var elems = document.querySelectorAll(`.multiselect`);
        M.FormSelect.init(elems, {});
        for (let i = 0; i < elems.length; i++) {
            if (elems[i].classList[1] == task.id) setElem(elems[i]);
        }
    }, [project.ptIntegration, task.type, isProjectSynced]);

    useLayoutEffect(() => {
        /** @type { Array.<task_stimuli_logic | task_stimuli_logic_group> } */
        const existingArrayWithTaskType = [
            ...task.stimuliLogicList,
            ...(task.stimuliLogicGroups ? task.stimuliLogicGroups : []),
        ];

        const sort =
            sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
                existingArrayWithTaskType,
                stimuliLogicsAndGroups,
            );
        // const sortedWithAtPosition = sortByAtPosition(sort) //! THIS WAS HERE FOR SORTING STIMULIS LOGIC AND GROUPS AFTER SYNC
        setStimuliLogicsAndGroups(
            sort.length > 0 ? sort : existingArrayWithTaskType,
        );
    }, [task]);

    useLayoutEffect(() => {
        /** @type { Array.<task_stimuli_logic | task_stimuli_logic_group> } */
        const existingArrayWithTaskType = [
            ...task.stimuliLogicList,
            ...(task.stimuliLogicGroups ? task.stimuliLogicGroups : []),
        ];

        const sort =
            sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
                existingArrayWithTaskType,
                stimuliLogicsAndGroups,
            ); //! THIS WAS HERE FOR SORTING STIMULIS LOGIC AND GROUPS AFTER SYNC
        // const sortedWithAtPosition = sortByAtPosition(sort)
        setStimuliLogicsAndGroups(
            sort.length > 0 ? sort : existingArrayWithTaskType,
        );
    }, [project.tasks.length]);

    //* This is used for initial sorting of stimuli logics
    useEffect(() => {
        if (stimuliLogicsAndGroups.length > 0) {
            setStimuliLogicsAndGroups(
                sortStimLogicsAndStimGroups(stimuliLogicsAndGroups),
            );
        }
    }, [canSortTasksAndGroups]);

    useEffect(() => {
        if (isProjectSynced && stimuliLogicsAndGroups.length > 0) {
            let sorted = sortByAtPosition(stimuliLogicsAndGroups);
            //* Sorting elements inside of stimuli group
            sorted = sorted.map((el) => {
                if (el._type == 'stimuli_logic_group') {
                    return {
                        ...el,
                        stimuliLogicList: sortByAtPosition(el.stimuliLogicList),
                    };
                }

                return el;
            });

            setStimuliLogicsAndGroups(sorted);
        }
    }, [isProjectSynced]);

    useLayoutEffect(() => {
        setStimuliLogicsAndGroups([]);
    }, [task.type]);

    let onChange = () => {
        let selected = M.FormSelect.getInstance(elem).getSelectedValues();
        const { taskUpdated, sortedSL: sorted } =
            modifyTaskAndSortStimuliLogics(project, task, selected);
        setStimuliLogicsAndGroups(sorted);

        const stimuliLogicListAndGroupsWithAtPosition =
            setAtPositionForStimuliLogicAndGroups(project, {
                stimuliLogicListAndGroupsChanged: sortByAtPosition([
                    ...taskUpdated.stimuliLogicList,
                    ...taskUpdated.stimuliLogicGroups,
                ]),
                task: taskUpdated,
                useSentTask: true,
            });

        setStimuliLogicListAndGroupsInProject(
            stimuliLogicListAndGroupsWithAtPosition.stimuliLogicList,
            stimuliLogicListAndGroupsWithAtPosition.stimuliLogicGroups,
            taskUpdated,
            updateTask,
        );
    };

    const moveCardHandler = (dragIndex, hoverIndex) => {
        /** @type { task_stimuli_logic | task_stimuli_logic_group } */
        let onDroppedElement = stimuliLogicsAndGroups[hoverIndex];

        /** @type { task_stimuli_logic | task_stimuli_logic_group } */
        let draggedElement = stimuliLogicsAndGroups[dragIndex];

        if (
            draggedElement?._type == 'stimuli_logic' &&
            onDroppedElement?._type == 'stimuli_logic_group'
        ) {
            /** @type { task } */
            const newTask = putStimuliLogicInGroup(
                task,
                draggedElement,
                onDroppedElement,
            );
            const newArrayWithOrderedStimuli = [
                ...stimuliLogicsAndGroups.filter(
                    (el) => el.id != draggedElement.id,
                ),
            ];
            setStimuliLogicsAndGroups(newArrayWithOrderedStimuli);
            const stimuliLogicListAndGroupsWithAtPosition =
                setAtPositionForStimuliLogicAndGroups(project, {
                    stimuliLogicListAndGroupsChanged:
                        newArrayWithOrderedStimuli,
                    task: task,
                });
            setStimuliLogicListAndGroupsInProject(
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicList,
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicGroups,
                task,
                updateTask,
            );
        } else {
            let stimuliLogicsAndGroupsCopy = [...stimuliLogicsAndGroups];
            let prev = stimuliLogicsAndGroupsCopy.splice(
                hoverIndex,
                1,
                draggedElement,
            );
            stimuliLogicsAndGroupsCopy.splice(dragIndex, 1, prev[0]);

            setStimuliLogicsAndGroups(stimuliLogicsAndGroupsCopy);
            const stimuliLogicListAndGroupsWithAtPosition =
                setAtPositionForStimuliLogicAndGroups(project, {
                    stimuliLogicListAndGroupsChanged:
                        stimuliLogicsAndGroupsCopy,
                    task: task,
                });

            setStimuliLogicListAndGroupsInProject(
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicList,
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicGroups,
                task,
                updateTask,
            );
        }
    };
    /**
     * @param {task_stimuli_logic_group} group
     * @returns { Function }
     */
    const moveCardHandlerInsideTHeGroup =
        (group) => (dragIndex, hoverIndex) => {
            let dragItem = group.stimuliLogicList[dragIndex];

            if (dragItem) {
                let groupCopy = { ...group };
                let prev = groupCopy.stimuliLogicList.splice(
                    hoverIndex,
                    1,
                    dragItem,
                );
                groupCopy.stimuliLogicList.splice(dragIndex, 1, prev[0]);
                // setGroupsAndTasks(groupsAndTasksCopy);
                const taskUpdated = { ...task };
                const stimuliLogicListInGroup =
                    setAtPositionForStimuliLogicInsideTheGroup(group, {
                        stimuliGroupChanged: groupCopy.stimuliLogicList,
                    });
                groupCopy.stimuliLogicList = stimuliLogicListInGroup;
                taskUpdated.stimuliLogicGroups =
                    taskUpdated.stimuliLogicGroups.map((g) =>
                        g.id == group.id ? groupCopy : g,
                    );
                updateTask(taskUpdated);
            }
        };
    /**
     * @param {task_stimuli_logic_group} groupToDelete
     */
    const deleteStimuliLogicGroup = (groupToDelete) => {
        const merged = [
            ...removeStimuliLogicsFromGroupAndMergeWithOthers(
                groupToDelete,
                task,
                stimuliLogicsAndGroups,
            ),
        ];
        setStimuliLogicsAndGroups(merged);

        const stimuliLogicListAndGroupsWithAtPosition =
            setAtPositionForStimuliLogicAndGroups(project, {
                stimuliLogicListAndGroupsChanged: merged,
                task: task,
            });
        setStimuliLogicListAndGroupsInProject(
            stimuliLogicListAndGroupsWithAtPosition.stimuliLogicList,
            stimuliLogicListAndGroupsWithAtPosition.stimuliLogicGroups,
            task,
            updateTask,
        );
    };
    /**
     * @param {task_stimuli_logic} stimulusLogic
     */
    const removeFromGroup = (stimulusLogic) => {
        const { sortedGroupsAndTask, newGroupList, stimuliLogicList } =
            removeStimuliLogicFromGroup(task, stimulusLogic);

        setStimuliLogicsAndGroups(sortedGroupsAndTask);
        setStimuliLogicListAndGroupsInProject(
            stimuliLogicList,
            newGroupList,
            task,
            updateTask,
        );
    };

    //*This is not mistake, using closure for saving element which will be targeted for pin
    const pinElement = (element) => (e, isPinned) => {
        //?updateFn
        if (!project.ptIntegration) {
            e.stopPropagation();
            // e.nativeEvent.stopImmediatePropagation()
            const newEl = { ...element };
            newEl._isPinned = isPinned;

            const stimuliLogicListAndGroupsWithAtPosition =
                setAtPositionForStimuliLogicAndGroups(project, {
                    pinnedElement: newEl,
                    task,
                    stimuliLogicListAndGroups: stimuliLogicsAndGroups,
                });

            setStimuliLogicListAndGroupsInProject(
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicList,
                stimuliLogicListAndGroupsWithAtPosition.stimuliLogicGroups,
                task,
                updateTask,
            );
        }
    };

    const createStimuliLogicGroup = () => {
        const { taskUpdated, newStimuliLogicGroup } = createStimuliLogicObj(
            project,
            task,
        );
        setStimuliLogicsAndGroups((prevState) => [
            ...prevState,
            newStimuliLogicGroup,
        ]);
        updateTask(taskUpdated);
    };

    const pinStimuliInGroup = (stimuli, group) => (e, isPinned, updateFn) => {
        if (!project.ptIntegration) {
            e.stopPropagation();
            // e.nativeEvent.stopImmediatePropagation()
            const newEl = { ...stimuli };
            newEl._isPinned = isPinned;

            const stimuliLogicListWithAtPosition =
                setAtPositionForStimuliLogicInsideTheGroup(group, {
                    pinnedElement: newEl,
                    stimuliLogicsInsideTheGroup: group.stimuliLogicList,
                });

            const newGroup = {
                ...group,
                stimuliLogicList: stimuliLogicListWithAtPosition,
            };

            updateFn(newGroup, newGroup.id);
        }
    };

    const updateStimuliLogicInGroup = (group, groupID) => {
        const taskUpdated = {
            ...task,
            stimuliLogicGroups: task.stimuliLogicGroups.map((g) =>
                g.id == groupID ? group : g,
            ),
        };

        updateTask(taskUpdated);
    };

    return (
        <div className="segment-section stimuli-wrapper">
            <label
                className={`label-stimuli-logic ${
                    ['survey', 'VS'].includes(task.type)
                        ? 'multiselect-hidden'
                        : ''
                }`}
            >
                Stimuli logic and Stimuli logic groups
            </label>

            <div
                className={`
        input-field timeouts-wrapper
        ${
            ['watch', 'click', 'web'].includes(task.type)
                ? ['click'].includes(task.type)
                    ? 'multiselect-click-exposure-time-wrapper'
                    : 'multiselect-exposure-time-wrapper'
                : ''
        }`}
            >
                <StimuliLogicSelect task={task} onChange={onChange} />

                {task.type === 'watch' ? (
                    <TaskExposureInput {...determineProps(task, 'timeout')} />
                ) : null}

                {task.type === 'click' ? (
                    <TaskExposureInput {...determineProps(task, 'timeout')} />
                ) : null}

                {task.type === 'web' ? (
                    <div className="web-max-exposure-wrapper timeouts-wrapper">
                        {/* <TaskExposureInput {...determineProps(task, "minExposureTimeout")} /> */}
                        <TaskExposureInput
                            {...determineProps(task, 'timeout')}
                        />
                    </div>
                ) : null}
            </div>

            {['survey', 'VS'].includes(task.type) ? null : (
                <div className="stimuli">
                    {task.stimuliLogicList.length > 0 ? (
                        <div className="stimuli-logic-create-button-container">
                            <button
                                onClick={createStimuliLogicGroup}
                                className="btn btn-small eyesee-blue white-text create-stimuli-logic-button--margin"
                                disabled={!!project.ptIntegration}
                            >
                                Create stimuli logic group
                            </button>
                        </div>
                    ) : null}

                    {/* <DndProvider backend={HTML5Backend}> */}
                    {stimuliLogicsAndGroups.map((st, index) =>
                        st ? (
                            <DraggableContainer
                                element={st}
                                key={st.id}
                                index={index}
                                // hasDragIcon={true}
                                hasDragIcon={!project.ptIntegration}
                                deleteFn={() => deleteStimuliLogicGroup(st)}
                                moveCardHandler={moveCardHandler}
                                type={st._type}
                                draggableType={st._draggableType}
                                pinElement={pinElement(st)}
                                canBePined={true}
                                // canBePined={!project.ptIntegration}
                                hasDeleteIcon={
                                    !project.ptIntegration &&
                                    st._type == 'stimuli_logic_group'
                                }
                                hasCopyIcon={false}
                                iconNextToName={
                                    st._type == 'stimuli_logic_group' ? (
                                        <i className="material-icons task-icon text-eyesee-gray">
                                            <span className="material-icons">
                                                ballot
                                            </span>
                                        </i>
                                    ) : null
                                }
                            >
                                {
                                    st._type == 'stimuli_logic' ? (
                                        <StimuliLogicView
                                            stimulisToDisplay={task.stimuli?.filter(
                                                (stimulus) =>
                                                    stimulus.logic == st.id,
                                            )}
                                            stimuliLogic={st}
                                            inputsDisabled={
                                                !!project.ptIntegration
                                            }
                                            task={task}
                                        />
                                    ) : (
                                        // <div className='collapsible-body stimuli-list-in-group-container'>
                                        st.stimuliLogicList.map(
                                            (stg, index) => (
                                                <DraggableContainer
                                                    element={stg}
                                                    key={stg.id}
                                                    index={index}
                                                    // hasDragIcon={true}
                                                    hasDragIcon={
                                                        !project.ptIntegration
                                                    }
                                                    updateFn={
                                                        updateStimuliLogicInGroup
                                                    }
                                                    moveCardHandler={moveCardHandlerInsideTHeGroup(
                                                        st,
                                                    )}
                                                    type={stg._type}
                                                    draggableType={
                                                        stg._draggableType
                                                    }
                                                    pinElement={pinStimuliInGroup(
                                                        stg,
                                                        st,
                                                    )}
                                                    // canBePined={true}
                                                    canBePined={
                                                        !project.ptIntegration
                                                    }
                                                    hasDeleteIcon={false}
                                                    hasCopyIcon={false}
                                                    // hasRemoveFromGroupIcon={true}
                                                    hasRemoveFromGroupIcon={
                                                        !project.ptIntegration
                                                    }
                                                    removeFromGroup={() =>
                                                        removeFromGroup(stg)
                                                    }
                                                    iconNextToName={null}
                                                >
                                                    <StimuliLogicView
                                                        stimulisToDisplay={task.stimuli?.filter(
                                                            (stimulus) =>
                                                                stimulus.logic ==
                                                                stg.id,
                                                        )}
                                                        stimuliLogic={st}
                                                        inputsDisabled={
                                                            !!project.ptIntegration
                                                        }
                                                        task={task}
                                                    />
                                                </DraggableContainer>
                                            ),
                                        )
                                    )
                                    // </div>
                                }
                            </DraggableContainer>
                        ) : null,
                    )}
                    {/* </DndProvider> */}
                </div>
            )}
        </div>
    );
}
