import React, { useEffect } from 'react';
import InputToggle from '../InputToggle';
import ProjectContext from './../../context/ProjectContext';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { findGroupInProject, findTaskInProject } from '../../helpers/getters';

const introslideOptions = {
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'textStyle',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
            'link',
            'image',
            'fullScreen',
            'preview',
            'print',
        ],
    ],
    height: 450,
    font: ['Arial', 'Helvetica', 'Roboto', 'Calibri', 'Times New Roman'],
    imageFileInput: false,
};

const getDiffLangs = (project) => {
    const setOfDiffLangs = new Set();

    project.cells.forEach((c) => {
        setOfDiffLangs.add(c.language);
    });

    return [...setOfDiffLangs];
};

export default function Introslide({ element = {}, update, type = 'task' }) {
    const { project } = React.useContext(ProjectContext);
    const [languages, setLanguages] = React.useState(() => {
        return getDiffLangs(project);
    });
    const internalProject = React.useRef(); //* Added because of problem with SunEditor onChange having old value for lang in element

    useEffect(() => {
        const langs = getDiffLangs(project);

        if (langs.join('') != languages.join('')) {
            setLanguages(langs);
            changeLangIntroductionsInTask(langs);
        }

        internalProject.current = project;
    }, [project]);

    const changeLangIntroductionsInTask = (langs) => {
        const task = findGroupInProject(internalProject.current, element.id);

        if (task) {
            let elementUpdated = { ...task };
            elementUpdated.introslides = elementUpdated.introslides.map(
                (introslide) => {
                    const languageExistsAfterChange = langs.find(
                        (l) => introslide.lang == l,
                    );

                    return languageExistsAfterChange
                        ? introslide
                        : {
                              ...introslide,
                              lang: langs[0],
                          };
                },
            );
            update(elementUpdated, element.id);
        } else {
            throw Error(
                "This shouldn't have happened. Couldn't find task from Introslide.jsx props in project tasks list",
            );
        }
    };

    let addIntroslide = () => {
        let elementUpdated = { ...element };
        let newIntroslide = {
            id: `introslide${Date.now()}`,
            content: '',
            lang: languages[0] || '',
        };
        if (elementUpdated.introslides?.length)
            elementUpdated.introslides.push(newIntroslide);
        else elementUpdated.introslides = [newIntroslide];

        update(elementUpdated, element.id);
    };

    //Called from sun editor clojure and that makes me a problem with element prop which is always set to first value.
    let onChange = (content, i) => {
        const task = findTaskInProject(internalProject.current, element.id);

        // regex for base64
        // let base64 = content.match(/"(data:image\/[^;]+;base64[^"]+)"/)

        if (task) {
            let elementUpdated = { ...task };
            elementUpdated.introslides[i].content = content;
            update(elementUpdated, element.id);
        } else {
            const group = findGroupInProject(
                internalProject.current,
                element.id,
            );

            if (!group) {
                throw Error(
                    "This shouldn't have happened. Couldn't find task from Introslide.jsx props in project tasks list",
                );
            } else {
                let elementUpdated = { ...group };
                elementUpdated.introslides[i].content = content;
                update(elementUpdated, element.id);
            }
        }
    };

    let removeIntroslide = (i) => {
        let elementUpdated = { ...element };
        elementUpdated.introslides.splice(i, 1);
        update(elementUpdated);
    };

    let toggleShowIntroslides = (showIntroslides) => {
        let elementUpdated = { ...element };
        elementUpdated.showIntroslides = !!showIntroslides;
        if (type == 'group' && showIntroslides) {
            elementUpdated.tasks = elementUpdated.tasks.map((t) => {
                // delete t.introslides
                t.introslides = [];
                t.showIntroslides = false;
                return t;
            });
        }
        update(elementUpdated, element.id);
    };

    const onChangeLang = (e, introslide) => {
        const elementUpdated = {
            ...element,
            introslides: element.introslides.map((introsl) =>
                introsl.id === introslide.id
                    ? {
                          ...introsl,
                          lang: e.target.value,
                      }
                    : introsl,
            ),
        };

        update(elementUpdated, element.id);
    };

    return (
        <div className="segment-section introslides">
            <div className="segment-section-title flex space-between align-center">
                <label>Introslides</label>
                <div className="introslides-options flex">
                    <InputToggle
                        isChecked={element.showIntroslides}
                        disabled={!!project.ptIntegration}
                        handleChange={(checked) =>
                            toggleShowIntroslides(checked)
                        }
                        labels={['Off', 'On']}
                    />
                    {element.showIntroslides ? (
                        <i
                            className={`material-icons link ${
                                !!project.ptIntegration
                                    ? 'events-none disabled'
                                    : 'text-eyesee-blue'
                            }`}
                            onClick={addIntroslide}
                        >
                            add_circle
                        </i>
                    ) : null}
                </div>
            </div>

            {element.showIntroslides ? (
                <>
                    {element.introslides?.map((introslide, i) => (
                        <div className="introslide-wrapper" key={introslide.id}>
                            <div className="segment-section languages introslide-lang">
                                <label>Introslide language</label>
                                <select
                                    className="browser-default"
                                    value={introslide.lang}
                                    name="language"
                                    onChange={(e) =>
                                        onChangeLang(e, introslide)
                                    }
                                    disabled={!!project.ptIntegration}
                                >
                                    <option value="0" disabled>
                                        Choose language
                                    </option>
                                    {languages.map((lang, i) => (
                                        <option key={i} value={lang}>
                                            {lang}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <SunEditor
                                setContents={introslide.content}
                                enableToolbar={true}
                                showToolbar={true}
                                setDefaultStyle="font-family: Roboto; font-size: 16px;"
                                onChange={(content) => onChange(content, i)}
                                setOptions={introslideOptions}
                                disable={!!project.ptIntegration}
                                onImageUploadBefore={(files) => {
                                    //* Because we do not want to allow any file uploads
                                }}
                                onVideoUploadBefore={(files) => {
                                    //* Because we do not want to allow any file uploads
                                }}
                                onAudioUploadBefore={(files) => {
                                    //* Because we do not want to allow any file uploads
                                }}
                            />
                            <i
                                className={`material-icons link introslide-delete ${
                                    !project.ptIntegration
                                        ? 'red-text text-lighten-2'
                                        : 'events-none disabled'
                                }`}
                                onClick={() =>
                                    !project.ptIntegration &&
                                    removeIntroslide(i)
                                }
                            >
                                delete
                            </i>
                        </div>
                    ))}
                </>
            ) : null}
        </div>
    );
}
