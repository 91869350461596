import { notify } from './../notify';

const messageDisplayLength = 22222220000;

const findStimulisForStimuliLogic = (newStimuliLogicList, newStimuliList) => {
    const newStimuliListWithNewAddedStimuliForStimuliLogic = [];

    newStimuliLogicList.forEach((notUseful, i) => {
        const stimLogicId = i + 1;
        newStimuliList.forEach((s) => {
            if (stimLogicId == s.logic) {
                newStimuliListWithNewAddedStimuliForStimuliLogic.push(s);
            }
        });
    });

    return newStimuliListWithNewAddedStimuliForStimuliLogic;
};

/**
 * Function which return new stimuli if the same doesn't already exists. If it exists it combines their properties instead of returning whole new one.
 *
 * @param { Object } task
 * @param { Array[Object] } arr
 * @returns { Object }
 **/
const getAlreadyDefinedStimulisIfNotChanged = (task, arr) => {
    return arr.map((newStim) => {
        const oldStimulus = task.stimuli.find(
            (stimuli) => newStim.name == stimuli.name,
        );

        if (oldStimulus) {
            return {
                ...oldStimulus,
                ...newStim,
                cells: newStim.cells.map((c) => 'cell' + c),
                id: newStim.name,
            };
        }
        return {
            ...newStim,
            cells: newStim.cells.map((c) => 'cell' + c),
            id: newStim.name,
        };
    });
};

/**
 * Function which return new stimuli if the same doesn't already exists. If it exists it combines their properties instead of returning whole new one.
 *
 * @param { Object } stimulus
 * @param { Array[Object] } newStimuliList
 * @returns { Object }
 **/
const addNewStimuliToTasks = (stimulus, newStimuliList) => {
    const newStimulus = newStimuliList.find(
        (newStim) => newStim.name == stimulus.name,
    );

    if (newStimulus) {
        return {
            ...stimulus,
            ...newStimulus,
            cells: newStimulus.cells.map((c) => 'cell' + c),
            id: newStimulus.name,
        };
    }
    return stimulus;
};

/**
 * Function which iterates over old and new stimuli logic to find deleted and new stimuli logics.
 * It also returns new stimuli logic list and stimuli logic group list for a task.
 * It keeps old stimuli logic if it is already defined.
 *
 * @param { Object } project
 * @param { Object } task
 * @param { Object[Object] } newStimulis
 * @param { Array[Object] } messages
 * @returns { Object }
 **/
const filterStimuliLogicsIfDeleted = (project, task, newStimulis, messages) => {
    const stimuliList = [];
    const stimuliListInGroup = [];
    const newStimuliLogicSetWithDiffValues = new Set();
    const projectStimuliLogicsWithoutEmptyValues = [];

    newStimulis.forEach((s) => newStimuliLogicSetWithDiffValues.add(s.logic));

    for (let i = 0; i < project.stimuliLogicList.length; i++) {
        if (project.stimuliLogicList[i]) {
            projectStimuliLogicsWithoutEmptyValues[i + 1] =
                project.stimuliLogicList[i];
        }
    }

    Object.keys(projectStimuliLogicsWithoutEmptyValues).forEach(
        (stimLogicIndex) => {
            if (!newStimuliLogicSetWithDiffValues.has(stimLogicIndex)) {
                messages.push({
                    id: task.id,
                    name: task.name,
                    message: `Stimuli logic ${stimLogicIndex} has been deleted.`,
                    type: 'deleted',
                });
            }
        },
    );

    newStimuliLogicSetWithDiffValues.forEach((stimLogicIndex) => {
        if (
            !Object.keys(projectStimuliLogicsWithoutEmptyValues).find(
                (index) => index == stimLogicIndex,
            )
        ) {
            messages.push({
                id: task.id,
                name: task.name,
                message: `New stimuli logic ${stimLogicIndex} has been added.`,
                type: 'success',
            });
        }
    });

    newStimuliLogicSetWithDiffValues.forEach((stimLogic) => {
        const stimuliLogicWhichAlreadyExists = task.stimuliLogicList.find(
            (sl) => sl.id == stimLogic,
        );

        if (stimuliLogicWhichAlreadyExists) {
            stimuliList.push(stimuliLogicWhichAlreadyExists);
        } else {
            let stimuliLogicWhichExistsInGroup = null;
            task.stimuliLogicGroups.forEach((stimGroup) => {
                const foundStimLogic = stimGroup.stimuliLogicList.find(
                    (sl) => sl.id == stimLogic,
                );
                if (foundStimLogic) {
                    stimuliLogicWhichExistsInGroup = foundStimLogic;
                }
            });
            //* If there is new stimuli logic stimuliLogicWhichExistsInGroup will still be null because it couldn't find it in list nor the group
            if (stimuliLogicWhichExistsInGroup) {
                stimuliListInGroup.push(stimuliLogicWhichExistsInGroup);
            }
        }
    });

    return {
        stimuliList,
        stimuliListInGroup,
    };
};

/**
 * Function which checks is task affected by changes in stimuli Logic
 *
 * @param { Object } el -> Task
 * @param { Number } stimuliLogicNumFromProject
 * @returns { String[] }
 **/
const findInTask = (el, stimuliLogicNumFromProject) => {
    const affectedTasks = [];
    const foundSl = el.stimuliLogicList.find(
        (sl) =>
            sl._name.toLowerCase().trim() ==
            'stimuli logic ' + stimuliLogicNumFromProject,
    );

    if (foundSl) {
        affectedTasks.push(el.name);
    } else {
        const foundSlInGroup = el.stimuliLogicGroups.find((g) =>
            g.stimuliLogicList.find(
                (sl) =>
                    sl._name.toLowerCase().trim() ==
                    'stimuli logic ' + stimuliLogicNumFromProject,
            ),
        );
        if (foundSlInGroup) {
            affectedTasks.push(el.name);
        }
    }

    return affectedTasks;
};

/**
 * Function which checks which tasks are affected by changes in stimuli logic.
 *
 * @param { Object } project
 * @param { Number } stimuliLogicNumFromProject
 * @returns { String }
 **/
const findAffectedTasks = (project, stimuliLogicNumFromProject) => {
    let affectedTasks = [];
    project.tasks.forEach((t) => {
        affectedTasks = [
            ...affectedTasks,
            ...findInTask(t, stimuliLogicNumFromProject),
        ];
    });
    project?.groups.forEach((g) => {
        g.tasks.forEach((t) => {
            affectedTasks = [
                ...affectedTasks,
                ...findInTask(t, stimuliLogicNumFromProject),
            ];
        });
    });

    if (affectedTasks.length > 0) {
        return ` ${affectedTasks.join(', ')}`;
    }

    return '';
};

/**
 * Function which checks for changes made to stimuli logic in sdt and determines the diference between sdt and project.
 * Function iterates over existing stimuli logic list ( in project ) and for every stimuli logic in project it searches sdt data to find that stimuli logic. If it's not there than it is deleted etc.
 * Keep in mind that stimuli logic can be only in one cell! This means that all cells must be searched before displaying message that SL is deleted.
 *
 * @param { Object } project
 * @param { Object } sdtData
 **/
const notifyAndDetermineChangeForStimuliLogic = (project, sdtData) => {
    const messages = [];
    // debugger;
    project.stimuliLogicList.forEach((stimLogic, index) => {
        let alreadyFoundStimulusLogic = false; //! This is here cuz of situation when I have some stimuli logic in one cell, and in the other one i don't have it.

        if (stimLogic) {
            // This is needed because I can have null values for 'holes' in stimuli logics (because they can create stimuli logics in order of 1: {...}, 3: {...})
            // console.log("🚀 ~ file: ProjectDetails.jsx ~ line 187 ~ project.stimuliLogicList.forEach ~ stimLogic", stimLogic)
            const stimuliLogicNumFromProject = index + 1;

            for (let cellNumber in sdtData) {
                let existingStimLogic = null;

                Object.keys(sdtData).forEach((celNum) => {
                    let found = Object.keys(sdtData[celNum]).find(
                        (stimNumber) =>
                            stimNumber == stimuliLogicNumFromProject,
                    );

                    if (found) {
                        existingStimLogic = found;
                    }
                });

                if (!existingStimLogic) {
                    if (!alreadyFoundStimulusLogic) {
                        const affectedTasks = findAffectedTasks(
                            project,
                            stimuliLogicNumFromProject,
                        );
                        let message = `Stimuli logic ${stimuliLogicNumFromProject} has been deleted! 
              ${
                  affectedTasks.length > 0
                      ? 'Affected tasks: ' + affectedTasks
                      : ''
              }`;

                        messages.push({
                            message,
                            type: 'deleted',
                            messageDisplayLength: messageDisplayLength,
                        });
                        // notify.deleted(`💣 ${message}`, messageDisplayLength);
                    }
                } else {
                    alreadyFoundStimulusLogic = true;
                    for (let cellNameFromProject in stimLogic) {
                        const cellNameFromSDT = 'cell' + cellNumber;
                        if (cellNameFromSDT == cellNameFromProject) {
                            if (
                                stimLogic[cellNameFromProject] !=
                                sdtData[cellNumber][existingStimLogic]?.s
                            ) {
                                const oldStimuliFileNameSplit =
                                    stimLogic[cellNameFromProject].split('/');
                                const oldStimuliFileName =
                                    oldStimuliFileNameSplit[
                                        oldStimuliFileNameSplit.length - 1
                                    ];
                                let newStimuliFileNameSplit = [];
                                let newStimuliFileName = '';

                                if (sdtData[cellNumber][existingStimLogic]?.s) {
                                    newStimuliFileNameSplit =
                                        sdtData[cellNumber][
                                            existingStimLogic
                                        ].s.split('/');
                                    newStimuliFileName =
                                        newStimuliFileNameSplit[
                                            newStimuliFileNameSplit.length - 1
                                        ];
                                }
                                const affectedTasks = findAffectedTasks(
                                    project,
                                    stimuliLogicNumFromProject,
                                );

                                //There is situation where stimuli has been moved from one stimuli logic to another, and if one in which stimulus was previously is empty ( {} ), then I would go into this if block even if the stimulus was really moved
                                if (!newStimuliFileName) {
                                    let foundStimuliSomeWhereInSDT = null;
                                    let foundInTheSameStimuliLogic = false;

                                    for (let cellNum in sdtData) {
                                        for (let stimLogicNum in sdtData[
                                            cellNum
                                        ]) {
                                            //* Is stimulus the same as before change
                                            if (
                                                sdtData[cellNum][stimLogicNum]
                                                    .s ==
                                                stimLogic[cellNameFromProject]
                                            ) {
                                                // console.log(foundStimuliSomeWhereInSDT?.stimLogic)
                                                //* If stimulus is found in the same stimuli logic, that means no delete nor change for stimulus happened ( ofc for that stimulus in that stimuli logic )
                                                if (
                                                    stimuliLogicNumFromProject ==
                                                    stimLogicNum
                                                ) {
                                                    foundInTheSameStimuliLogic = true;
                                                } else {
                                                    foundStimuliSomeWhereInSDT =
                                                        {
                                                            stimLogic:
                                                                foundStimuliSomeWhereInSDT?.stimLogic
                                                                    ? [
                                                                          ...foundStimuliSomeWhereInSDT.stimLogic,
                                                                          stimLogicNum,
                                                                      ]
                                                                    : [
                                                                          stimLogicNum,
                                                                      ],
                                                        };
                                                }
                                            }
                                        }
                                    }

                                    if (!foundInTheSameStimuliLogic) {
                                        if (foundStimuliSomeWhereInSDT) {
                                            let stimLogics = '';

                                            foundStimuliSomeWhereInSDT.stimLogic =
                                                foundStimuliSomeWhereInSDT.stimLogic.reduce(
                                                    (
                                                        previousValue,
                                                        currentValue,
                                                    ) => {
                                                        if (
                                                            previousValue.find(
                                                                (v) =>
                                                                    v ==
                                                                    currentValue,
                                                            )
                                                        ) {
                                                            return [
                                                                ...previousValue,
                                                            ];
                                                        }

                                                        return [
                                                            ...previousValue,
                                                            currentValue,
                                                        ];
                                                    },
                                                    [],
                                                );

                                            foundStimuliSomeWhereInSDT.stimLogic.forEach(
                                                (sl, i) => {
                                                    const slObjectFromProject =
                                                        project
                                                            .stimuliLogicList[
                                                            sl - 1
                                                        ];
                                                    let isDeleted = false;

                                                    for (let cellNameInWantedObj in slObjectFromProject) {
                                                        const stimFileName =
                                                            slObjectFromProject[
                                                                cellNameInWantedObj
                                                            ].split('/')[
                                                                oldStimuliFileNameSplit.length -
                                                                    1
                                                            ];

                                                        if (
                                                            stimFileName ==
                                                            oldStimuliFileName
                                                        ) {
                                                            isDeleted = true;
                                                        }
                                                    }

                                                    if (isDeleted) {
                                                        let message = `Stimulus ${oldStimuliFileName} has been deleted from stimuli logic ${stimuliLogicNumFromProject}! Affected tasks: ${
                                                            affectedTasks.length >
                                                            0
                                                                ? '' +
                                                                  affectedTasks
                                                                : 'None.'
                                                        }`;

                                                        messages.push({
                                                            message,
                                                            type: 'deleted',
                                                            messageDisplayLength:
                                                                messageDisplayLength,
                                                        });
                                                    } else {
                                                        stimLogics += `stimuli logic ${sl}${
                                                            i ==
                                                            foundStimuliSomeWhereInSDT
                                                                .stimLogic
                                                                .length -
                                                                1
                                                                ? ''
                                                                : ', '
                                                        }`;

                                                        let message = `Stimulus ${oldStimuliFileName} has been moved to ${stimLogics}! Affected tasks: ${
                                                            affectedTasks.length >
                                                            0
                                                                ? '' +
                                                                  affectedTasks
                                                                : 'None.'
                                                        }`;

                                                        messages.push({
                                                            message,
                                                            type: 'changed',
                                                            messageDisplayLength:
                                                                messageDisplayLength,
                                                        });
                                                    }
                                                },
                                            );
                                        } else {
                                            let message = `Stimulus ${oldStimuliFileName} from stimuli logic ${stimuliLogicNumFromProject} has been deleted! Affected tasks: ${
                                                affectedTasks.length > 0
                                                    ? '' + affectedTasks
                                                    : 'None. '
                                            }`;
                                            // notify.deleted(message, messageDisplayLength);
                                            messages.push({
                                                message,
                                                type: 'deleted',
                                                messageDisplayLength:
                                                    messageDisplayLength,
                                            });
                                        }
                                    }
                                } else {
                                    let message = `Stimuli logic ${stimuliLogicNumFromProject} has changed stimulus from ${oldStimuliFileName} in ${cellNameFromProject} to ${newStimuliFileName}! ${
                                        affectedTasks.length > 0
                                            ? 'Affected tasks: ' + affectedTasks
                                            : ''
                                    }`;
                                    // notify.changed(message, messageDisplayLength);
                                    messages.push({
                                        message,
                                        type: 'changed',
                                        messageDisplayLength:
                                            messageDisplayLength,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    });

    notify.displayMessages(messages);
};
/**
 * When checking stimuli for notifications, there is no need to check if some stimuli are changed because that is already done in notifyAndDetermineChangeForStimuliLogic. Here I will only check for deleted stimuli and new added
 * Function first iterates over project stimuli to determine if there is any stimulus deleted.
 * Next it iterates over new sdtData, to find new stimuli if any.
 *
 * @param { project } project
 * @param { Object } sdtData
 **/
const notifyAndDetermineChangeForStimuli = (project, sdtData) => {
    const messages = [];

    //* Looking for deleted stimuli
    project.stimuli.forEach(
        /** @param {task_stimuli} s */
        (s) => {
            // s.path
            let isDeletedFromProject = true;
            let isDeletedFromStimuliLogic = true;

            //* Is stimulus deleted from stimuli logic where he was previously
            for (let cellNumber in sdtData) {
                const stimuliLogicInWhichStimulusWasInProject =
                    sdtData[cellNumber][s.logic];

                if (stimuliLogicInWhichStimulusWasInProject?.s == s?.path) {
                    isDeletedFromStimuliLogic = false;
                }
            }

            if (isDeletedFromStimuliLogic) {
                let message = `Stimulus ${s.name} has been deleted from stimuli logic ${s.logic} `; //*  and cells ${s.cells.join(', ')}

                messages.push({
                    type: 'deleted',
                    message,
                    messageDisplayLength,
                });
            }
            //* Is stimuli deleted from cell
            const diffCellsOnly = new Set();
            s.cells.forEach((cell) => diffCellsOnly.add(cell));

            [...diffCellsOnly].filter((cell) => {
                let isDeletedFromCell = true;

                for (let stimLogic in sdtData[cell]) {
                    if (sdtData[cell][stimLogic]?.s == s.path) {
                        if (stimLogic == s.logic) {
                            isDeletedFromCell = false;
                        }
                    }
                }

                if (isDeletedFromCell) {
                    messages.push({
                        type: 'deleted',
                        message: `Stimulus ${s.name} has been deleted from cell ${cell}`,
                        messageDisplayLength,
                    });
                }
            });

            for (let cellNumber in sdtData) {
                for (let stimuliLogicNumber in sdtData[cellNumber]) {
                    if (sdtData[cellNumber][stimuliLogicNumber].s == s.path) {
                        isDeletedFromProject = false;
                    }
                }
            }

            if (isDeletedFromProject) {
                let message = `Stimulus ${s.name} has been deleted from project!`;
                // notify.deleted(message, messageDisplayLength);
                messages.push({
                    type: 'deleted',
                    message,
                    messageDisplayLength,
                });
            }
        },
    );

    //* Looking for new stimuli
    for (let cellNumber in sdtData) {
        for (let stimuliLogicNumber in sdtData[cellNumber]) {
            let isNewInProject = true;
            let isNewInCell = true;
            let isNewInStimuliLogic = true;
            const stimuliPath = sdtData[cellNumber][stimuliLogicNumber].s;

            //* Looking for new stimuli in stimuli logic
            isNewInStimuliLogic = !!!project.stimuli
                .filter((stimuli) => stimuli.logic == stimuliLogicNumber)
                .find((stimuli) => stimuli.path == stimuliPath);

            if (isNewInStimuliLogic) {
                if (stimuliPath) {
                    const newStimuliFileNameSplit = stimuliPath.split('/');
                    const newStimuliFileName =
                        newStimuliFileNameSplit[
                            newStimuliFileNameSplit.length - 1
                        ];

                    let message = `New stimulus ${newStimuliFileName} has been added to stimuli logic ${stimuliLogicNumber} and cell${cellNumber}!`;

                    // notify.created(message, messageDisplayLength);
                    messages.push({
                        type: 'success',
                        message,
                        messageDisplayLength,
                    });
                }
            }

            //* Looking for new stimuli in cell
            // isNewInCell = !!project.stimuli
            //   .filter(stimuli => !stimuli.cells.includes(Number(cellNumber)) && stimuli.logic == stimuliLogicNumber)
            //   .find(stimuli => stimuli.path == stimuliPath)
            isNewInCell = !!project.stimuli.find(
                (stimuli) =>
                    stimuli.path == stimuliPath &&
                    !stimuli.cells.includes(Number(cellNumber)) &&
                    stimuli.logic == stimuliLogicNumber,
            );

            if (isNewInCell) {
                if (stimuliPath) {
                    const newStimuliFileNameSplit = stimuliPath.split('/');
                    const newStimuliFileName =
                        newStimuliFileNameSplit[
                            newStimuliFileNameSplit.length - 1
                        ];

                    let message = `New stimulus ${newStimuliFileName} has been added to cell${cellNumber}!`;

                    // notify.created(message, messageDisplayLength);
                    messages.push({
                        type: 'success',
                        message,
                        messageDisplayLength,
                    });
                }
            }

            //* Looking for new stimuli in project
            project.stimuli.forEach((s) => {
                if (stimuliPath == s.path) {
                    isNewInProject = false;
                }
            });

            if (isNewInProject) {
                if (stimuliPath) {
                    const newStimuliFileNameSplit = stimuliPath.split('/');
                    const newStimuliFileName =
                        newStimuliFileNameSplit[
                            newStimuliFileNameSplit.length - 1
                        ];

                    let message = `New stimulus ${newStimuliFileName} has been added to project!`;

                    // notify.created(message, messageDisplayLength);
                    messages.push({
                        type: 'success',
                        message,
                        messageDisplayLength,
                    });
                }
            }
        }
    }

    notify.displayMessages(messages);
};

export {
    findStimulisForStimuliLogic,
    getAlreadyDefinedStimulisIfNotChanged,
    notifyAndDetermineChangeForStimuliLogic,
    notifyAndDetermineChangeForStimuli,
    filterStimuliLogicsIfDeleted,
    addNewStimuliToTasks,
    messageDisplayLength,
};
