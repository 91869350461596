import './../typedefs';

/**
 * Function sorts new (or changed) tasks and groups from project (arrayWithRelevantValues prop) according to state that is displayed in the view (prop sortedArr).
 * This func is used when project needs to sync its atPosition property according to displayed view.
 *
 * @param { Array.<task | task_group | task_stimuli_logic | task_stimuli_logic_group> } arrayWithRelevantValues
 * @param { Array.<task | task_group | task_stimuli_logic | task_stimuli_logic_group> } sortedArr
 * @returns { Array.<task | task_group | task_stimuli_logic | task_stimuli_logic_group> }
 **/
const sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted = (
    arrayWithRelevantValues,
    sortedArr,
) => {
    var result = [];
    //* This is needed because user can drag and drop tasks to change order
    if (sortedArr.length > 0) {
        sortedArr.forEach(function (element) {
            var found = arrayWithRelevantValues.find(function (item) {
                if (item?.id == element?.id) {
                    return item;
                }
            });
            result.push(found ? found : element); //* Check cuz we can have new elements added to the list that are not in arrayWithRelevantValues
        });
    }
    return result;
};

/**
 * Sets the atPosition property of stimuli logic inside of group. This is done on drag n drop of stimuli logic in group, or after deleting or adding a new stimuli logic.
 *
 * @param { task_stimuli_logic_group } group
 * @param { setAtPositionForStimuliLogicInsideTheGroupOption } options -> properties pinnedElement = null, stimuliGroupChanged
 * @returns { Array.<task_stimuli_logic>}
 **/
const setAtPositionForStimuliLogicInsideTheGroup = (group, options) => {
    let arrayWithRelevantValues = [...group.stimuliLogicList];

    if (options?.pinnedElement) {
        arrayWithRelevantValues = arrayWithRelevantValues.map((el) =>
            el.id == options.pinnedElement.id ? options.pinnedElement : el,
        );
    }

    const sorted =
        sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
            arrayWithRelevantValues,
            options?.stimuliGroupChanged
                ? options.stimuliGroupChanged
                : options.stimuliLogicsInsideTheGroup,
        );

    const sortedWithAtPosition = sorted.map((element, i) => ({
        ...element,
        atPosition: element?._isPinned ? i + 1 : null,
    }));

    return sortedWithAtPosition;
};
/**
 * Sets the atPosition property of tasks and groups. This is done on drag n drop of task or group, after deleting or adding a new task or group.
 * @param { project } project
 * @param { setAtPositionForStimuliLogicAndGroupsOption } options
 * @returns {{ stimuliLogicList: Array.<task_stimuli_logic>, stimuliLogicGroups: Array.<task_stimuli_logic_group>}}
 **/
const setAtPositionForStimuliLogicAndGroups = (project, options) => {
    let wantedTask = options.useSentTask
        ? options.task
        : project.tasks.find((t) => t.id == options.task.id);

    if (!wantedTask) {
        project.groups.forEach((g) => {
            const wantedGroupTask = g.tasks.find(
                (gt) => gt.id == options.task.id,
            );
            if (wantedGroupTask) {
                wantedTask = wantedGroupTask;
            }
        });
    }

    let arrayWithRelevantValues = [
        ...wantedTask.stimuliLogicList,
        ...wantedTask.stimuliLogicGroups,
    ];

    if (options?.pinnedElement) {
        arrayWithRelevantValues = arrayWithRelevantValues.map((el) =>
            el.id == options.pinnedElement.id ? options.pinnedElement : el,
        );
    }

    const sorted =
        sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
            arrayWithRelevantValues,
            options?.stimuliLogicListAndGroupsChanged
                ? options.stimuliLogicListAndGroupsChanged
                : options.stimuliLogicListAndGroups,
        );

    const sortedWithAtPosition = sorted.map((element, i) => ({
        ...element,
        atPosition: element?._isPinned ? i + 1 : null,
    }));

    const stimuliLogicList = sortedWithAtPosition.filter(
        (t) => t._type == 'stimuli_logic',
    );
    const stimuliLogicGroups = sortedWithAtPosition.filter(
        (t) => t._type == 'stimuli_logic_group',
    );

    return {
        stimuliLogicList,
        stimuliLogicGroups,
    };
};
/**
 * Sets the atPosition property of tasks and groups. This is done on drag n drop of task or group, after deleting or adding a new task or group.
 * @param { project } project
 * @param { setAtPositionForElementsOption } options
 * @returns {{ tasks: Array.<task>, groups: Array.<task_group>}}
 **/
const setAtPositionForElements = (project, options) => {
    let arrayWithRelevantValues = [
        ...project.tasks.map((t) => ({ ...t, _type: 'task' })),
        ...(project.groups
            ? project.groups.map((g) => ({ ...g, _type: 'group' }))
            : []),
    ];

    if (options?.pinnedElement) {
        arrayWithRelevantValues = arrayWithRelevantValues.map((el) =>
            el.id == options.pinnedElement.id ? options.pinnedElement : el,
        );
    }

    const sorted =
        sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
            arrayWithRelevantValues,
            options?.groupsAndTasksChanged
                ? options.groupsAndTasksChanged
                : options.groupsAndTasks,
        );

    const sortedWithAtPosition = sorted.map((element, i) => ({
        ...element,
        atPosition: element._isPinned ? i + 1 : null,
    }));

    const tasks = sortedWithAtPosition.filter((t) => t._type == 'task');
    const groups = sortedWithAtPosition.filter((t) => t._type == 'group');

    return {
        tasks,
        groups,
    };
};

/**
 * Sorts tasks and groups by atPosition. Takes care of 'holes' in atPosition (1,3,5 -> 1,2,3).
 * @param { Array.<task | task_group | task_stimuli_logic | task_stimuli_logic_group> } arr
 * @returns { Array.<task | task_group | task_stimuli_logic | task_stimuli_logic_group> }
 **/
const sortByAtPosition = (arr) => {
    const withAtPosition = arr.filter((el) => el && !!el.atPosition),
        withoutAtPosition = arr.filter((el) => el && !el.atPosition);
    const sorted = [];

    for (let i = 1; i <= arr.length; i++) {
        const elAtPosition = withAtPosition.find((el) => el.atPosition == i);
        if (elAtPosition) {
            sorted.push(elAtPosition);
        } else if (withoutAtPosition.length == 0) {
            //* This means that I have situation when atPosition of first element is 2, and i could'nt find it because i is 1!
            const arrWithFixedAtPosition = [];
            for (let j = 1; j <= i; j++) {
                arrWithFixedAtPosition.push(sorted[j]);
            }
            //* There is also situation when i have a 'hole' in atPosition because some logic in the middle is deleted!
            return sortByAtPosition(
                arr.map((el) => {
                    const sortedWithAccurateAtPosition = sorted.find(
                        (s) => s.id == el.id,
                    );
                    if (sortedWithAccurateAtPosition) {
                        return sortedWithAccurateAtPosition;
                    }
                    return { ...el, atPosition: el.atPosition - 1 };
                }),
            );
        } else {
            // const rand = Math.round(Math.random())
            // sorted.push(rand == 0 ? withoutAtPosition.shift() : withoutAtPosition.pop()) //* Just for funzies
            sorted.push(withoutAtPosition.shift());
        }
    }

    return sorted;
};

/**
 * @param {project} project
 * @param {task} task
 * @returns { Promise }
 */
const removeTaskFromGroup = (project, task) => {
    /** @type { task_group } */
    const newGroupList = project.groups.map((g) => ({
        ...g,
        tasks: g.tasks.filter((t) => t.id != task.id),
    }));

    task._isPinned = false;
    task.atPosition = null;
    task._type = 'task';
    task._draggableType = 'task-and-group-dnd';

    const newTasks = [...project.tasks, task];
    const mergedGroupsAndTasks = [...newTasks, ...newGroupList];

    const sortedGroupsAndTask = sortByAtPosition(mergedGroupsAndTasks);
    return Promise.resolve(sortedGroupsAndTask);
};

export {
    setAtPositionForElements,
    setAtPositionForStimuliLogicAndGroups,
    sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted,
    setAtPositionForStimuliLogicInsideTheGroup,
    sortByAtPosition,
    removeTaskFromGroup,
};
