import React, { useState } from 'react';
import { api } from '../api';

export default function useUserData() {
    let [user, setUser] = useState({});

    let storeUserData = (userData) => {
        window.localStorage.setItem('user', JSON.stringify(userData));
    };

    let getUserData = () => {
        let userData = window.localStorage.getItem('user');
        return userData ? JSON.parse(userData) : null;
    };

    let checkUser = () => {
        return api
            .checkUser()
            .then((response) => {
                let userData = {
                    id: response.data.user_id,
                    name: response.data.user_name,
                    img: `${
                        process.env.REACT_APP_EYESEEHUB
                    }images/employees/${response.data.user_name.replace(
                        / /g,
                        '_',
                    )}.jpg`,
                };
                setUser(userData);
                storeUserData(userData);
                return Promise.resolve(userData);
            })
            .catch((err) => Promise.reject(err));
    };

    return {
        user,
        checkUser,
        getUserData,
    };
}
