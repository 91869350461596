import { generateOptions } from '../../helpers/TaskBody';
import React, { useContext } from 'react';
import ProjectContext from '../../context/ProjectContext';

const TaskOptions = ({ task, disabled }) => {
    const { updateTask } = useContext(ProjectContext);

    if (task.type === 'survey' || task.type === 'VS') return null;

    let handleChange = (e) => {
        let taskUpdated = { ...task };
        const { name, checked } = e.target;

        if (name === 'preload' && task.type === 'video') {
            taskUpdated.stimuliLogicList = taskUpdated.stimuliLogicList.map(
                (logic) => ({
                    ...logic,
                    preload: checked,
                }),
            );
        }

        if (
            name === 'fc' &&
            (taskUpdated.type === 'click' || taskUpdated.type === 'web')
        ) {
            if (checked) {
                taskUpdated.options['screen'] = true;
            }
        }

        taskUpdated.options[name] = checked;
        updateTask(taskUpdated);
    };

    const taskOptions = generateOptions(task.type);

    const mapCheckboxes = ({ value, label }) => {
        const checkboxDisabled =
            disabled ||
            ((task.type === 'click' || task.type === 'web') &&
                value === 'screen' &&
                task.options['fc']);

        return (
            <p key={value} className="task-option">
                <label>
                    <input
                        type="checkbox"
                        disabled={checkboxDisabled}
                        className={`filled-in ${
                            checkboxDisabled && 'disabled'
                        }`}
                        name={value}
                        checked={task.options[value]}
                        onChange={handleChange}
                    />
                    <span>{label}</span>
                </label>
            </p>
        );
    };

    return (
        <div className="segment-section">
            <label>Options</label>
            <div className="task-options">{taskOptions.map(mapCheckboxes)}</div>
        </div>
    );
};

export default TaskOptions;
