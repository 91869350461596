import React from 'react';
import { InputValidation } from '../UI Elements';
import { urlValidator } from '../../helpers/validators';

const SurveyURL = ({ onChange, task, disabled }) => {
    if (task.type !== 'survey') return null;

    return (
        <div className="text-input segment-section">
            <label>URL</label>
            <InputValidation validator={urlValidator}>
                <input
                    type="text"
                    name="iframeURL"
                    defaultValue={task.iframeURL}
                    onChange={onChange}
                    disabled={disabled}
                />
            </InputValidation>
        </div>
    );
};

export default SurveyURL;
