import React, { useContext, useEffect, useRef } from 'react';
import ProjectContext from '../../context/ProjectContext.js';
import { getDefaultOptions } from '../../helpers/TaskBody';
import {
    ClickTaskExit,
    CommonTaskOptions,
    SurveyURL,
    VSURL,
    Introslide,
    TaskOptions,
    TaskStimuli,
} from './index';

export default function TaskBody({ task }) {
    const initialLoading = useRef(true);
    const { project, updateTask } = useContext(ProjectContext);
    const cells = project.cells;

    useEffect(() => {
        const defaults = getDefaultOptions(task);
        let taskUpdated = { ...task };

        if (!initialLoading.current) {
            for (let option in taskUpdated.options) {
                taskUpdated.options[option] = false;
            }
            for (let option of defaults) {
                if (
                    (taskUpdated.type === 'click' ||
                        taskUpdated.type === 'web') &&
                    option === 'fc'
                ) {
                    taskUpdated.options['screen'] = true;
                }
                taskUpdated.options[option] = true;
            }
        }

        updateTask(taskUpdated);
        initialLoading.current = false;
    }, [task.type]);

    let onChange = (e) => {
        const { name, value } = e.target;
        let taskUpdated = { ...task };
        taskUpdated[name] = value;
        updateTask(taskUpdated);
    };

    const itemProps = {
        onChange,
        task,
        cells,
        element: task,
        update: updateTask,
        disabled: !!project.ptIntegration,
    };

    const items = [
        CommonTaskOptions,
        TaskOptions,
        Introslide,
        TaskStimuli,
        ClickTaskExit,
        SurveyURL,
        VSURL,
    ];

    return (
        <div className="segment">
            {items.map((Component) => (
                <Component {...itemProps} />
            ))}
        </div>
    );
}
