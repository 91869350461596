import React from 'react';
import ProjectContext from '../../context/ProjectContext';
import {
    findParentElement,
    isTimeoutGreatherThanMinExposure,
    validateElement,
} from '../../helpers/validators';

export default function TaskExposureInput({
    task,
    defaultValue,
    inputName,
    labelText,
    errorLabelText,
    wrapperClasses,
}) {
    const { project, updateTask } = React.useContext(ProjectContext);

    const onChangeTimeout = (e) => {
        let taskUpdated = { ...task };
        taskUpdated[e.target.name] = Number(e.target.value);
        updateTask(taskUpdated);
        const timeoutsWrapper = findParentElement(
            e.target,
            task.type == 'click' ? 'collapsible-body' : 'timeouts-wrapper',
        );
        const errorMessageLabels =
            timeoutsWrapper.querySelectorAll('.error-msg');

        validateElement(
            e,
            ['timeout', 'minExposureTimeout'],
            (val) => val > 0 && isTimeoutGreatherThanMinExposure(taskUpdated),
            errorMessageLabels,
        );
    };

    return (
        <div className={wrapperClasses}>
            <label>{labelText}</label>
            <br />
            <label className="error-msg active hidden error-msg__no-left-margin">
                {errorLabelText}
            </label>
            <input
                type="number"
                name={inputName}
                disabled={!!project.ptIntegration}
                value={
                    task[inputName] === undefined
                        ? defaultValue
                        : task[inputName]
                }
                onChange={onChangeTimeout}
                min="0"
            />
        </div>
    );
}
