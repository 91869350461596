import React from 'react';
import ReactDOM from 'react-dom';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import '@eyesee/eyesee-hub-npm-ui/dist/index.css';
import './style/index.css';
import App from './App';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_ENV || 'development',
    enabledReleaseStages: ['test', 'production', 'stage'],
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

M.AutoInit();

ReactDOM.render(
    <ErrorBoundary>
        <React.StrictMode>
            <App />
        </React.StrictMode>
        ,
    </ErrorBoundary>,
    document.getElementById('root'),
);
