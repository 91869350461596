import React from 'react';

export default function useAfterSyncSortInfo(initialState) {
    let [canSortTasksAndGroups, setCanSortTasksAndGroups] =
        React.useState(initialState);
    let [isProjectSynced, setIsProjectSynced] = React.useState(initialState);

    return {
        canSortTasksAndGroups,
        setCanSortTasksAndGroups,
        isProjectSynced,
        setIsProjectSynced,
    };
}
