import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(function (response) {
    if (response.data.status === 400) {
        let responseMsgMap = {
            'No user':
                'It seems that your token is invalid. Please, login to EyeSee Hub and try to access the application again.',
        };
        return Promise.reject(
            responseMsgMap[response.data.msg] ||
                response.data.msg ||
                "Something went wrong, we're sorry for the inconveniece.",
        );
    }
    return Promise.resolve(response);
});

export default instance;
