import { useState } from 'react';
import { api } from '../api';

export default function useProject() {
    let [project, setProject] = useState({
        active: 1,
        cancelLink: '',
        isArchived: false,
        language: '',
        name: '',
        redirectLink: '',
        slides: [],
        stimuli: [],
        stimuliLogicList: [],
        tasks: [],
        groups: [],
        // tasksRandomization: ""
    });

    let deleteProject = (projectID) => {
        return api.deleteProject(projectID);
    };

    let updateProject = (property, value) => {
        setProject({ ...project, [property]: value });
    };

    let saveProject = (project_id, project) => {
        return api.saveProject(Number(project_id), {
            project: JSON.stringify(project),
        });
    };

    const createProject = (project_name, project) => {
        return api.createProject(project_name, project);
    };

    const fetchProject = (id) => {
        return api
            .fetchProject(id)
            .then((res) => {
                console.log("🚀 ~ file: useProject.js:42 ~ .then ~ res", res)
                if (res.status == 200) {
                    var project = typeof res.data.message === 'string' ? JSON.parse(res.data.message) : res.data.message
                    setProject(project);
                    return Promise.resolve(res);
                } else {
                    return Promise.reject('Something went wrong');
                }
            })
            .catch((err) => {
                console.error(err);
                return Promise.reject(err);
            });
    };

    return {
        project,
        setProject,
        saveProject,
        createProject,
        deleteProject,
        updateProject,
        fetchProject,
    };
}
