import React, { useState } from 'react';
import TaskStimulus from './TaskStimulus.jsx';

export default function StimuliOverview({ stimuli }) {
    let [areVisible, setAreVisible] = useState(false);

    let toggleVisibility = () => {
        setAreVisible(!areVisible);
    };

    return (
        <div className="segment all-stimuli">
            <div className="segment-title flex space-between">
                <div className="flex align-center">
                    <i className="material-icons">filter</i>
                    <h6>Project's stimuli</h6>
                </div>

                <div className="cell-visibility" onClick={toggleVisibility}>
                    {areVisible ? (
                        <i
                            className="material-icons link text-eyesee-gray"
                            title="Hide"
                        >
                            visibility_off
                        </i>
                    ) : (
                        <i
                            className="material-icons link text-eyesee-gray"
                            title="Show"
                        >
                            visibility
                        </i>
                    )}
                </div>
            </div>

            <div className="segment-body stimuli-list">
                <div className="flex align-center">
                    {areVisible
                        ? stimuli
                              ?.sort((a, b) => a.logic - b.logic)
                              ?.map((stimulus, i) => (
                                  <TaskStimulus stimulus={stimulus} key={i} />
                              ))
                        : null}
                </div>
            </div>
        </div>
    );
}
