import React, { useContext, useState } from 'react';
import { palette } from '../../palette.js';
import './../../typedefs';
import { randomIntFromInterval } from '../../helpers/index.js';
import ProjectContext from '../../context/ProjectContext.js';
import { updateStimulus } from '../../helpers/TaskStimuli.js';

export default function TaskStimulus({ task, stimulus }) {
    let [areTagsVisible, setAreTagsVisible] = useState(false);
    let [backgroundColor, setBackgroundColor] = useState(
        `${palette[stimulus.logic % palette.length]} lighten-4`,
    );
    const [isScrollChecked, setIsScrollChecked] = useState(false);
    const { updateTask } = useContext(ProjectContext);

    let showTags = (e) => {
        setAreTagsVisible(true);
    };

    let hideTags = (e) => setAreTagsVisible(false);

    const onScrollCheckedChange = (e) => {
        /** @type {task_stimuli} */
        setIsScrollChecked(e.target.checked);
        const stimuliUpdated = {
            ...stimulus,
            scroll: e.target.checked,
        };
        updateStimulus(stimuliUpdated, task, updateTask);
    };

    const onChangeInput = (e) => {
        let stimuliChanged = {
            ...stimulus,
            [e.target.name]: e.target.value,
        };

        updateStimulus(stimuliChanged, task, updateTask);
    };

    const randomInt = randomIntFromInterval(1, 100000);

    //* Problem was that some simulus were displaying same cell numbers multiple times.
    //* This should not happen in real situation but in testing we encoutered it!
    const cellsToDisplay = stimulus.cells.reduce((prevValue, currentValue) => {
        if (prevValue.includes(currentValue)) {
            return [...prevValue];
        }
        return [...prevValue, currentValue];
    }, []);

    return (
        <div className="stimulus-wrapper">
            <div className={`${backgroundColor} stimulus-header`}>
                {task?.type === 'click' && (
                    <div className="switch">
                        <label>
                            Scroll
                            <input
                                checked={
                                    stimulus.scroll !== undefined
                                        ? stimulus.scroll
                                        : isScrollChecked
                                }
                                type="checkbox"
                                onChange={onScrollCheckedChange}
                            />
                            <span className="lever"></span>
                        </label>
                    </div>
                )}
                <p className="stimulus-name">{stimulus.name}</p>
            </div>

            <div
                className="stimulus-preview"
                onMouseEnter={showTags}
                onMouseLeave={hideTags}
            >
                {stimulus.path.indexOf('.mp4') > -1 ? (
                    <video
                        className="stimulus"
                        src={stimulus.path + '?nocache=' + randomInt}
                    ></video>
                ) : (
                    <img
                        className="stimulus"
                        src={stimulus.path + '?nocache=' + randomInt}
                        alt=""
                    />
                )}
                {areTagsVisible ? (
                    <div className="cell-tags">
                        {cellsToDisplay.map((cell) => (
                            <div className="cell-tag" key={cell}>
                                {cell}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            {task?.type == 'web' ? (
                <div>
                    <div>
                        <label>URL</label>
                        <div className="web-task-stimuli-input-container web-task-url">
                            <a target="_blank" href={stimulus.url}>
                                {stimulus.url}
                            </a>
                        </div>
                    </div>
                </div>
            ) : null}

            <p
                className={`${backgroundColor} flex align-center justify-center logic-number`}
            >
                <span>stimuli logic {stimulus.logic}</span>
            </p>
        </div>
    );
}
