import './../typedefs';

/**
 * Function which generates names for groups in project and in stimuli logic.
 * @param { Array.<task_group | task_stimuli_logic_group> } arr
 * @param { string } propToSplit
 * @returns { Number }
 */
const getNextNameNumber = (arr, propToSplit) => {
    if (!arr || arr.length == 0) {
        return 1;
    }

    const numInNames = arr.map((g) => {
        const letters = g[propToSplit].split('');
        const numbers = letters.filter((el) => !isNaN(el));
        const num = numbers.map((n) => n + '').join('');
        return +num;
    });

    numInNames.push(arr.length);
    const maxNum = Math.max(...numInNames);

    return maxNum + 1;
};

export { getNextNameNumber };
