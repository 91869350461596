import { sortByAtPosition } from './at-position-helpers';
import { getAllTasksFromProject } from './getters';
import { getNextNameNumber } from './task-naming-helpers';

export const deepCopy = (elementToBeCoppied) =>
    JSON.parse(
        JSON.stringify(elementToBeCoppied, (key, val) =>
            val === undefined ? null : val,
        ),
    ); //* This is needed because maybe I have undefined vals in some objects or arrays

export const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Changes task.type property, and also properties in task object that are depending on the selected task type
 * @param {Event} e
 * @param {task} task
 * @param {project_cell[]} cells
 * @returns {task}
 */
export function setTaskTypeAndTypeDependentProps(e, task, cells) {
    /** @type {task} */
    let taskUpdated = { ...task };
    let type = e.target.value;
    taskUpdated.type = type;

    taskUpdated.stimuliLogicList = [];
    taskUpdated.stimuliLogicGroups = [];

    if (type == 'web') {
        taskUpdated.timeout = 300;
        // taskUpdated.minExposureTimeout = 3;
        taskUpdated.stimuli = taskUpdated.stimuli.map((s) => ({
            ...s,
            screenHeight: 10000,
        }));
    } else {
        taskUpdated.stimuli = taskUpdated.stimuli.map((s) => {
            delete s.screenHeight;
            return s;
        });
    }

    if (type == 'watch') {
        taskUpdated.timeout = 10;
    }

    if (type == 'click') {
        taskUpdated.timeout = 60;
        taskUpdated.minExposureTimeout = 10;
        taskUpdated.maxClickNum = 1;
    } else {
        delete taskUpdated.maxClickNum;
    }

    if (type != 'click' && type != 'web') {
        delete taskUpdated.minExposureTimeout;
    }

    if (!['web', 'VS', 'survey'].includes(type)) {
        delete taskUpdated.appendIframeParams;
    } else {
        taskUpdated.appendIframeParams = true;
        //* If we want to keep previous value of appendIframeParams when changing from web to vs or survey. ->
        // taskUpdated.appendIframeParams = taskUpdated.appendIframeParams === undefined ? true : taskUpdated.appendIframeParams;
    }

    if (type === 'VS') {
        if (!taskUpdated.links) {
            taskUpdated.links = cells.map((cell) => ({
                cell: cell.name,
                path: process.env.REACT_APP_VS_URL,
            }));
        }
    }

    return taskUpdated;
}

export let getEnabledStimuliLogicsForTaskType = (project, task) => {
    return project.stimuli
        .filter((s) => (task.type === 'web' ? !!s.url : !s.url))
        .map((s) => Number(s.logic));
};
/**
 *
 * @param {project} project
 * @param {Number} atPosition
 * @returns {task_group}
 */
export function createNewGroupObj(project, atPosition) {
    const groupName = `Group${getNextNameNumber(project.groups, 'name')}`;

    /** @type { task_group } */
    let newGroup = {
        id: `group${Date.now()}${randomIntFromInterval(1, 100000)}`,
        name: groupName,
        type: 'randomizeAll',
        tasks: [],
        recalibrate: false,
        introslides: [],
        showIntroslides: true,
        showGenericInstructions: true,
        atPosition,
        _draggableType: 'task-and-group-dnd',
        _type: 'group',
        _isPinned: true, //false,
    };

    return newGroup;
}
/**
 *
 * @param {project} project
 * @param {Number} atPosition
 * @returns {task}
 */
export function createNewTaskObj(project, atPosition) {
    /** @type { task } */
    let newTask = {
        id: `task${Date.now()}${randomIntFromInterval(1, 100000)}`,
        name:
            'Task' + getNextNameNumber(getAllTasksFromProject(project), 'name'),
        atPosition,
        type: 'watch',
        introslides: [],
        showIntroslides: true,
        showGenericInstructions: true,
        // showGenericTaskIntroSlides: true,
        timeout: 10,
        exitTrigger: 'clickOnExitButton',
        // maxClickNum: 1,
        // minExposureTimeout: 10,
        options: { et: true },
        stimuliLogicList: [],
        stimuliLogicGroups: [],
        stimuli: [],
        _isPinned: true, //false,
        _draggableType: 'task-and-group-dnd',
        _type: 'task',
    };

    return newTask;
}
/**
 *
 * @param {project} project
 * @returns {task}
 */
export function createNewTaskObjectForGroup(project) {
    /** @type {task} */
    let newTask = {
        id: `task${Date.now()}${randomIntFromInterval(1, 100000)}`,
        name:
            'Task' + getNextNameNumber(getAllTasksFromProject(project), 'name'),
        type: 'watch',
        atPosition: null,
        introslides: [],
        showIntroslides: false,
        timeout: 10,
        exitTrigger: 'clickOnExitButton',
        // maxClickNum: 1,
        // minExposureTimeout: 10,
        options: { et: true },
        _draggableType: 'group-task-and-group-dnd',
        _type: 'group_task',
        stimuliLogicList: [],
        stimuliLogicGroups: [],
        stimuli: [],
    };

    return newTask;
}
/**
 *
 * @param {project} project
 * @param {task | task_group} elementToBeCoppied
 * @param {Array.<task | task_group>} groupsAndTasks
 * @returns { Promise }
 */
export const copyTaskInRoot = (project, elementToBeCoppied, groupsAndTasks) => {
    /** @type {task | task_group} */
    const copiedElement = deepCopy(elementToBeCoppied);
    const allTasks = getAllTasksFromProject(project);

    if (copiedElement._type == 'group') {
        copiedElement.id = `group${Date.now()}${randomIntFromInterval(
            1,
            100000,
        )}`;
        copiedElement.name = `Group${getNextNameNumber(
            project.groups,
            'name',
        )}`;

        //* Need to change task ids because of name changing in addParenthesesAndSetGroupsAndTasks.
        copiedElement.tasks = copiedElement.tasks.map((t, i) => ({
            ...t,
            id: `task${Date.now()}${randomIntFromInterval(1, 100000)}`,
            name: 'Task' + (getNextNameNumber(allTasks, 'name') + i),
        }));

        if (copiedElement._isPinned) {
            copiedElement.atPosition =
                findLastPositionAmongSiblings(groupsAndTasks);
        }
        return Promise.resolve(copiedElement);
    }

    if (copiedElement._type == 'task') {
        copiedElement.id = `task${Date.now()}${randomIntFromInterval(
            1,
            100000,
        )}`;
        copiedElement.name = 'Task' + getNextNameNumber(allTasks, 'name');
        if (copiedElement._isPinned) {
            copiedElement.atPosition =
                findLastPositionAmongSiblings(groupsAndTasks);
        }
        return Promise.resolve(copiedElement);
    }
};

/**
 * @param {project} project
 * @param { task } taskToBeCoppied
 * @returns { Promise }
 */
export function copyTaskInGroup(project, taskToBeCoppied) {
    const copiedElement = deepCopy(taskToBeCoppied);
    copiedElement.id = `task${Date.now()}${randomIntFromInterval(1, 100000)}`;
    // copiedElement.name = `${copiedElement.name}${copiedElement.name.indexOf('Copy') > -1 ? '' : 'Copy'}`
    copiedElement.name =
        'Task' + getNextNameNumber(getAllTasksFromProject(project), 'name');

    copiedElement._isPinned = false; //* Task in group can't be pinned
    copiedElement.atPosition = null; //* Task in group can't be pinned

    return Promise.resolve(copiedElement);
}

/**
 * @param {Array.<task | task_group>} array
 * @returns {number}
 */
export const findLastPositionAmongSiblings = (array) => {
    if (array.length <= 0) {
        return 1;
    }
    return array.length + 1;
};

export const areAllStimulisWatermarked = (newStimuliList) =>
    newStimuliList.filter((s) => s.isWatermarked).length ===
    newStimuliList.length;
