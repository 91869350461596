import React, { useEffect } from 'react';
import respondent from "./images/respondent.png";
import eyeseeHub from "./images/eyesee-hub.png";
import M from "materialize-css";
import "./user-menu.css";
import {token} from '../../token';

let UserMenu = ({ user, appName }) => {
  useEffect(() => {
    var elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems, {
      constrainWidth: false
    });
  }, []);


  let redirectToHub = (e) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_API_URL}eyeseehub?t=${token.getEncoded()}`;
    window.open(url);
  }

  let logout = e => {
    e.preventDefault();
    let url = `https://eyesee-hub.com/logout?t=${token.getEncoded()}&app_name=${appName}`;
    window.location.href = url;
  }

  return (
    <>
      <div className="user-menu">
        <img src={user.img || respondent} alt={user.name} />
        <p>{user.name}</p>
        <i className="material-icons dropdown-trigger" data-target='user-dropdown'>arrow_drop_down</i>
      </div>
      <ul id='user-dropdown' className='dropdown-content'>
        <li>
          <a href="#" onClick={redirectToHub}>
            <img src={eyeseeHub} alt="EyeSee hub" title="Go to EyeSee hub" />
          </a>
        </li>

        <li>
          <a href="#" onClick={logout}>Log out</a>
        </li>
      </ul>
    </>
  )
}

export default UserMenu;
