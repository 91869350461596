import React from 'react';
import ProjectContext from '../../context/ProjectContext';
import { getEnabledStimuliLogicsForTaskType } from '../../helpers';

export default function StimuliLogicSelect({ task, onChange }) {
    const stimLogics = task.stimuliLogicGroups.map((g) =>
        g?.stimuliLogicList?.map((s) => s.index),
    );
    const { project } = React.useContext(ProjectContext);

    return (
        <div
            className={`multiselect-wrapper ${
                ['survey', 'VS'].includes(task.type) ? 'multiselect-hidden' : ''
            } `}
        >
            <select
                multiple
                className={`multiselect ${task.id} `}
                onChange={onChange}
                value={[
                    ...task.stimuliLogicList.map((s) => s.index),
                    ...stimLogics.flat(),
                ]}
                disabled={!!project.ptIntegration}
            >
                {project.stimuliLogicList?.length > 0
                    ? project.stimuliLogicList.map((sl, i) => {
                          if (sl) {
                              return (
                                  <option
                                      value={i}
                                      name={i}
                                      key={i}
                                      disabled={
                                          !getEnabledStimuliLogicsForTaskType(
                                              project,
                                              task,
                                          )?.includes(i + 1)
                                      }
                                  >
                                      stimuli logic {i + 1}
                                  </option>
                              );
                          }
                      })
                    : null}
            </select>
        </div>
    );
}
