import React, { useState } from 'react';
import CellList from './CellList.jsx';

export default function Cells({ languages }) {
    let [areVisible, setAreVisible] = useState(true);

    let toggleVisibility = () => setAreVisible(!areVisible);

    return (
        <div className="segment cells-wrapper">
            <div className="segment-title flex space-between">
                <div className="w-full flex space-between">
                    <div className="flex align-center">
                        <i className="material-icons">view_comfy</i>
                        <h6>Cells</h6>
                    </div>

                    <div className="cell-visibility" onClick={toggleVisibility}>
                        {areVisible ? (
                            <i
                                className="material-icons link text-eyesee-gray"
                                title="Click to Hide"
                            >
                                visibility_off
                            </i>
                        ) : (
                            <i
                                className="material-icons link text-eyesee-gray"
                                title="Click to Show"
                            >
                                visibility
                            </i>
                        )}
                    </div>
                </div>
            </div>
            <CellList languages={languages} areVisible={areVisible} />
        </div>
    );
}
