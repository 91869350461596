import React from 'react';

export default function InputToggle({
    isChecked,
    handleChange,
    labels,
    disabled,
    verticalAlign,
}) {
    let setValue = (e) => {
        handleChange(Number(e.target.checked));
    };

    return (
        <div className={`switch ${disabled && 'disabled'}`}>
            <label className={`${verticalAlign && 'flex-column'}`}>
                {labels[0]}
                <input
                    type="checkbox"
                    name="active"
                    checked={isChecked}
                    onChange={setValue}
                    disabled={disabled}
                />
                <span
                    className="lever"
                    style={verticalAlign && { marginTop: '10px' }}
                ></span>
                {labels[1]}
            </label>
        </div>
    );
}
