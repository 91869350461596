import React, { useState, useEffect } from 'react';
import { SpinnerSmall } from '@eyesee/eyesee-hub-npm-ui';
import axios from 'axios';
import { notify } from '../../notify';
import { useMutation } from 'react-query';
import { api } from '../../api';
import { token } from '../../token';
import { queryClient } from '../../api/query';
import { useHistory } from 'react-router-dom';
import { project } from '../../project.js';

export default function CreateProject({ hideModal }) {
    const [isLoading, setIsLoading] = useState(false);
    const [projectID, setProjectID] = useState();
    const history = useHistory();

    const createProject = useMutation(
        'createProject',
        (params) => api.createProject(...params),
        {
            onSettled: (data, error) => {
                if (!error) {
                    notify.success('The project is successfully created!');
                    history.push(
                        `/project/${data?.data?.message}?t=${token.getEncoded()}`,
                    );
                    queryClient.invalidateQueries('projects');
                    queryClient.removeQueries('projects');
                } else {
                    notify.error(error);
                }
                setIsLoading(false);
            },
        },
    );

    useEffect(() => {
        let modalContentEl = document.querySelector('.app-modal-content');
        modalContentEl.classList.add('app-modal-confirm');

        return () => {
            modalContentEl.classList.remove('app-modal-confirm');
        };
    }, []);

    let confirm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        return axios
            .get(
                `https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/${projectID}/utils/out.json`,
            )
            .then((response) => {
                let newProject = project.createNew(projectID, response.data);
                createProject.mutate([projectID, newProject]);
            })
            .catch((err) => {
                notify.error(
                    "Sorry, we couldn't find a project with the ID you entered. Please, check if the ID is correct, and try again.",
                );
                setIsLoading(false);
            });
    };

    return (
        <div>
            <div className="app-modal-header">
                <p>CreateProject</p>
            </div>

            <div className="app-modal-body">
                <div className="text-input">
                    <label>Enter project ID:</label>
                    <input
                        type="text"
                        value={projectID}
                        onChange={(e) => setProjectID(e.target.value)}
                    />
                </div>
            </div>

            <form onSubmit={confirm} className="app-modal-footer btns">
                <button
                    className="btn btn-small btn-secondary"
                    onClick={hideModal}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-small eyesee-blue loading-btn"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ? <SpinnerSmall /> : null}
                    <span className="white-text">Sync</span>
                </button>
            </form>
        </div>
    );
}
