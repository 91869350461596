import React, { useState, useEffect, useContext } from 'react';
import { isValidHttpUrl, validateElement } from '../../helpers/validators';
import { token } from '../../token';
import ProjectContext from './../../context/ProjectContext';
import CellOptions from './CellOptions';
import Link from './Link';
import Select from '../ui/Select';

export default function CellList({ languages = [], areVisible }) {
    const { project, onSetProject } = React.useContext(ProjectContext);
    const [popUpLinkData, setPopUpLinkData] = React.useState({
        link: '',
        style: { display: 'none', top: '0px', left: '0px' },
    });
    const [redirectAndCancelLinkStyle, setRedirectAndCancelLinkStyle] =
        useState({ color: '' });

    useEffect(() => {
        document.addEventListener('click', shouldHideLink);

        return () => {
            document.removeEventListener('click', shouldHideLink);
        };
    }, []);

    // If referrer link is enabled, redirect and cancel links should be hidden and disabled for every cell
    useEffect(() => {
        if (project.referrerLink)
            setRedirectAndCancelLinkStyle({ color: 'transparent' });
        else {
            setRedirectAndCancelLinkStyle({ color: '' });
        }
    }, [project.referrerLink]);

    function updateCell(e, i) {
        let p = { ...project };
        let cellsUpdated = [...p.cells];
        cellsUpdated[i][e.target.name] = e.target.value;
        p.cells = cellsUpdated;
        onSetProject(p);
        validateElement(e, ['cancelLink', 'redirectLink'], (string) =>
            isValidHttpUrl(string, true),
        );
    }

    const hideLink = () => {
        let link = {
            link: '',
            style: { display: 'none', top: '0px', left: '0px' },
        };
        setPopUpLinkData({ link });
    };

    const shouldHideLink = (e) => {
        if (!e.target.getAttribute('islink')) {
            hideLink();
        }
    };

    const withoutSpace = (cellName) =>
        cellName.indexOf(' ') > -1 ? cellName.replace(' ', '') : cellName;

    const showLink = (e, cellName) => {
        let link = {
            link: `${process.env.REACT_APP_API_ROOT_URL}landing?project=${
                project.name
            }-${withoutSpace(cellName)}`,
            style: {
                display: 'flex',
                top: `${e.clientY + 10}px`,
                left: `${e.clientX - 400}px`,
            },
        };
        setPopUpLinkData(link);
    };

    return (
        <ul className="cells collapsible">
            {areVisible &&
                project.cells?.map((cell, i) => (
                    <li>
                        <div key={cell.id} className="cell collapsible-header">
                            <div className="cell-information">
                                <div className="cell-name">
                                    <p
                                        className={`project-status-indicator ${
                                            cell.active ? 'green' : 'red'
                                        } lighten-2`}
                                    ></p>
                                    <span> {cell.name}</span>
                                </div>
                                <Select
                                    className="transparent"
                                    value={cell.language}
                                    name="language"
                                    onChange={(e) => {
                                        updateCell(e, i);
                                    }}
                                    disabled={!!project.ptIntegration}
                                    options={languages}
                                    title="Choose Language"
                                />

                                <div className="flex-column">
                                    <label className="error-msg active hidden">
                                        URL must be valid and also contain http
                                        or https
                                    </label>
                                    <input
                                        type="text"
                                        className="transparent"
                                        value={cell.redirectLink}
                                        name="redirectLink"
                                        onChange={(e) => updateCell(e, i)}
                                        disabled={
                                            !!project.ptIntegration ||
                                            !!project.referrerLink
                                        }
                                        style={redirectAndCancelLinkStyle}
                                    />
                                </div>

                                <div className="flex-column">
                                    <label className="error-msg active hidden">
                                        URL must be valid and also contain http
                                        or https
                                    </label>
                                    <input
                                        type="text"
                                        className="transparent"
                                        value={cell.cancelLink}
                                        name="cancelLink"
                                        onChange={(e) => updateCell(e, i)}
                                        disabled={
                                            !!project.ptIntegration ||
                                            !!project.referrerLink
                                        }
                                        style={redirectAndCancelLinkStyle}
                                    />
                                </div>
                            </div>

                            <div className="cell-statistics">
                                <a
                                    href={`${
                                        process.env.REACT_APP_API_URL
                                    }export-video-logs?t=${token.getEncoded()}&projectName=${
                                        project.name
                                    }&cellName=${cell.name}`}
                                    target="_blank"
                                    className="text-eyesee-gray"
                                    title="Download video analysis"
                                >
                                    <i className="material-icons query_stats_text">
                                        query_stats
                                    </i>
                                </a>
                                <i
                                    className={`material-icons link text-eyesee-gray ${
                                        !!project.ptIntegration && 'events-none'
                                    }`}
                                    title="Copy Link"
                                    islink="true"
                                    onClick={(e) =>
                                        !project.ptIntegration &&
                                        showLink(e, cell.name)
                                    }
                                >
                                    {' '}
                                    link{' '}
                                </i>
                                <a
                                    className={`${
                                        !!project.ptIntegration && 'events-none'
                                    }`}
                                    href={`${
                                        process.env.REACT_APP_API_URL
                                    }stats/project/${project.name}-${
                                        cell.name
                                    }?t=${token.getEncoded()}`}
                                    target="_blank"
                                    title="Statistics"
                                >
                                    <i className="material-icons link text-eyesee-gray">
                                        bar_chart
                                    </i>
                                </a>
                                <div>
                                    <i className="material-icons">
                                        keyboard_arrow_down
                                    </i>
                                </div>
                            </div>
                        </div>
                        <div className="collapsible-body">
                            <CellOptions
                                project={project}
                                cell={cell}
                                updateCell={updateCell}
                                i={i}
                                handleCellActiveChange
                            />
                        </div>
                    </li>
                ))}
            <Link link={popUpLinkData.link} style={popUpLinkData.style} />
        </ul>
    );
}
