import M from 'materialize-css';
import './typedefs';

export const notify = {
    success: (msg, displayLength = 4000) => {
        M.toast({
            html: msg,
            classes: 'apart green lighten-1 white-text',
            displayLength,
        });
    },
    created: (msg, displayLength = 4000) => {
        M.toast({
            html: msg,
            classes: 'apart green lighten-1 white-text',
            displayLength,
        });
    },
    error: (msg) => {
        M.toast({ html: msg, classes: 'red lighten-2 white-text' });
    },
    dismissAllToasts: (msg, displayLength = 4000) => {
        M.toast({
            html: msg,
            classes: 'blue darken-1 white-text',
            displayLength,
            completeCallback: () => {
                M.Toast.dismissAll();
            },
        });
    },
    deleted: (msg, displayLength = 4000) => {
        M.toast({
            html: msg,
            classes: 'apart red darken-1 white-text pulse',
            displayLength,
        });
    },

    changed: (msg, displayLength = 4000) => {
        M.toast({
            html: msg,
            classes: 'apart yellow darken-3 bold white-text',
            displayLength,
        });
    },
    /**
     * @param {notify_message} messages
     */
    displayMessages: (messages) => {
        const messagesWithoutDuplicates = messages.reduce(
            (previousValue, currentValue) => {
                if (
                    previousValue.find((v) => v.message == currentValue.message)
                ) {
                    return [...previousValue];
                }
                return [...previousValue, currentValue];
            },
            [],
        );

        messagesWithoutDuplicates.forEach((m) => {
            const emoji = {
                success: '🚀 ',
                deleted: '💣 ',
                changed: '💫💡 ',
            };
            const message = `${emoji[m.type]} ${m.message}`;
            notify[m.type](message, m.messageDisplayLength);
        });
    },
};
