/**
 * Gets all task names in project
 * @param { project } project
 * @returns Array.<String>
 */
function findAllTaskNames(project) {
    return [
        ...project.tasks.map((task) => task.name),
        ...project.groups.flatMap((group) => {
            return group.tasks.map((task) => task.name);
        }),
    ].filter((el) => el);
}

/**
 * Finds all web and click tasks
 * @param { project } project
 * @returns Array.<task>
 */
function findAllWebTasks(project) {
    return [
        ...project.tasks.filter((task) => task.type == 'web'),
        ...project.groups.flatMap((group) =>
            group.tasks.filter((task) => task.type == 'web'),
        ),
    ];
}

/**
 * Finds all web and click tasks
 * @param { project } project
 * @returns Array.<task>
 */
function findAllClickTasks(project) {
    return [
        ...project.tasks.filter((task) => task.type == 'click'),
        ...project.groups.flatMap((group) =>
            group.tasks.filter((task) => task.type == 'click'),
        ),
    ];
}

/**
 * Finds all minExposureTimeout which are not falsy
 * @param { project } project
 * @returns Array.<Number>
 */
function findAllMinTimeouts(project) {
    return [
        ...findMinTimeoutsInTasks(project.tasks),
        ...project.groups.flatMap((group) => {
            return findMinTimeoutsInTasks(group.tasks);
        }),
    ].filter((el) => el || el === 0);
}

/**
 * Finds all minExposureTimeout INSIDE OF TASKS which are not falsy
 * @param {Array.<task>} tasks
 * @returns {Array.<Number>}
 */
function findMinTimeoutsInTasks(tasks) {
    return tasks
        .map((task) =>
            ['click', 'watch', 'web'].includes(task.type)
                ? task.minExposureTimeout
                : null,
        )
        .filter((seconds) => seconds !== null);
}

/**
 * Finds all stimuli exposures in tasks which have it
 * @param { project } project
 * @returns {Array.<Number>}
 */
function findAllStimuliExposures(project) {
    return [
        ...findAllExposuresFromTasks(project.tasks),
        ...project.groups.flatMap((group) => {
            return findAllExposuresFromTasks(group.tasks);
        }),
    ].filter((el) => el || el === 0);
}

/**
 * Finds all timeout INSIDE OF TASKS which are not falsy
 * @param {Array} tasks
 * @returns {Array.<Number>}
 */
function findAllExposuresFromTasks(tasks) {
    return tasks
        .map((task) =>
            ['click', 'watch', 'web'].includes(task.type) ? task.timeout : null,
        )
        .filter((seconds) => seconds !== null);
}

/**
 * Finds all redirect and cancel urls in project
 * @param { project } project
 * @returns { Array.<String>}
 */
function findAllRedirectAndCancelLinks(project) {
    return [
        project.cancelLink,
        project.redirectLink,
        ...project.cells.map((cell) => {
            if (cell.cancelLink.length > 0) {
                return cell.cancelLink;
            }
        }),
        ...project.cells.map((cell) => {
            if (cell.redirectLink.length > 0) {
                return cell.redirectLink;
            }
        }),
    ].filter((url) => url && url?.trim());
}

/**
 * Finds all urls in survey web and VS tasks
 * @param { project } project
 * @returns { Array.<String>}
 */
function findAllUrlsInProject(project) {
    return [
        ...findAllUrlsFromSurveyWebVSTasks(project.tasks),
        ...project.groups.flatMap((group) => {
            return findAllUrlsFromSurveyWebVSTasks(group.tasks);
        }),
    ].filter((url) => url && url?.trim());
}

/**
 * Finds all stimuli
 * @param { project } project
 * @returns { Array.<task_stimuli>}
 */
function findAllStimuliInTasks(project) {
    return [
        ...project.tasks.flatMap((t) => t.stimuli),
        ...project.groups.flatMap((group) => {
            return group.tasks.flatMap((t) => t.stimuli);
        }),
    ];
}

/**
 * Finds all stimuli
 * @param { project } project
 * @returns { Array.<task_stimuli>}
 */
function findAllStimuliInWebTasks(project) {
    return [...findAllWebTasks(project).flatMap((t) => t.stimuli)];
}

/**
 * Finds all urls inside of tasks which have it
 * @param {Array.<task>} tasks
 * @returns {Array.<String>}
 */
function findAllUrlsFromSurveyWebVSTasks(tasks) {
    return tasks
        .filter((task) => ['survey', 'VS', 'web'].includes(task.type))
        .map((task) => task.iframeURL);
}

/**
 * Searches the project and finds the same task names in project
 * @param {project} project
 * @returns Array.<String>
 */
function findAllSameTaskNames(project) {
    const taskNames = findAllTaskNames(project);

    const objWithTaskNames = {};
    taskNames.forEach((taskName) => {
        if (objWithTaskNames[taskName]) {
            objWithTaskNames[taskName] += 1;
        } else {
            objWithTaskNames[taskName] = 1;
        }
    });

    const wantedTaskNames = Object.keys(objWithTaskNames).filter(
        (key) => objWithTaskNames[key] > 1,
    );
    return wantedTaskNames;
}

function getAllTasksFromProject(project) {
    return [
        ...project.tasks,
        ...project.groups.flatMap((group) => group.tasks),
    ];
}

const findTaskInProject = (project, ID) => {
    let task = project.tasks.find((t) => t.id == ID); // id doesn't change so there will be no problem with clojure and prev value;
    if (!task) {
        //He must be in group
        project.groups.forEach((g) =>
            g.tasks.forEach((t) => (t.id == ID ? (task = t) : null)),
        );
    }
    return task;
};

const findGroupInProject = (project, ID) => {
    let element = project.tasks.find((t) => t.id == ID); // id doesn't change so there will be no problem with clojure and prev value;
    if (!element) {
        //He must be in group
        project.groups.forEach((g) =>
            g.tasks.forEach((t) => (t.id == ID ? (element = t) : null)),
        );
    }
    if (!element) {
        //He must be in group
        project.groups.forEach((g) => (g.id == ID ? (element = g) : null));
    }
    return element;
};

export {
    findAllUrlsInProject,
    findAllRedirectAndCancelLinks,
    findAllStimuliExposures,
    findAllMinTimeouts,
    findAllClickTasks,
    findAllWebTasks,
    findAllSameTaskNames,
    findAllTaskNames,
    findAllStimuliInTasks,
    findAllStimuliInWebTasks,
    getAllTasksFromProject,
    findTaskInProject,
    findGroupInProject,
};
