import React from 'react';
import { getFullLabelByValue } from '../../utils/getFullLabel';

export default function Select({
    options,
    multiple = false,
    name,
    title,
    onChange,
    disabledOptions = [],
    disabled,
    value = '',
    className,
}) {
    const isLanguages = name === 'language';

    return (
        <select
            name={name}
            className={`browser-default ${className}`}
            onChange={onChange}
            multiple={multiple}
            value={value || title}
            disabled={disabled}
        >
            {title ? (
                <option value={title} disabled>
                    {title}
                </option>
            ) : null}
            {options.map((option, i) => (
                <option
                    key={i}
                    value={option}
                    disabled={disabledOptions.indexOf(option) >= 0}
                >
                    {isLanguages ? getFullLabelByValue(option) : option}
                </option>
            ))}
        </select>
    );
}
