import React from 'react';
import ProjectContext from '../../context/ProjectContext';
import { updateStimuliLogicPreload } from '../../helpers/TaskStimuli';
import TaskStimulus from './TaskStimulus';

export default function StimuliLogicView({
    task,
    stimuliLogic,
    stimulisToDisplay,
    inputsDisabled,
}) {
    const { updateTask } = React.useContext(ProjectContext);

    return (
        <>
            {task.type === 'video' ? (
                <div className="preload-container">
                    <label>
                        <input
                            type="checkbox"
                            className={`filled-in ${
                                inputsDisabled && 'disabled'
                            }`}
                            name="preload"
                            checked={stimuliLogic.preload}
                            onChange={(e) =>
                                updateStimuliLogicPreload(
                                    e,
                                    task,
                                    stimuliLogic,
                                    updateTask,
                                )
                            }
                            disabled={inputsDisabled}
                        />
                        <span className="preload-container--label">
                            preload
                        </span>
                    </label>
                </div>
            ) : null}
            <div
                className={`segment stimuli-preview-container ${
                    stimulisToDisplay.length === 3
                        ? 'stimuli-preview-container--between'
                        : ''
                }`}
            >
                {stimulisToDisplay?.map((stimulus) => (
                    <div key={stimulus.name}>
                        <TaskStimulus stimulus={stimulus} task={task} />
                    </div>
                ))}
            </div>
        </>
    );
}
