export const getFullLabelByValue = (value) => {
    switch (value) {
        case 'cn-simplified':
            return 'Chinese simplified';
        case 'fr':
            return 'French';
        case 'de':
            return 'German';
        case 'en':
            return 'English';
        case 'es-sp':
            return 'Spanish';
        case 'id':
            return 'Indonesian Bahasa';
        case 'pt-br':
            return 'Brazilian Portuguese';
        case 'it':
            return 'Italian';
        case 'ko-kr':
            return 'Korean';
        case 'tr':
            return 'Turkish';
        case 'th':
            return 'Thai';
        case 'nl':
            return 'Dutch';
        case 'jp':
            return 'Japanese';
        case 'pl':
            return 'Polish';
        case 'pt-pt':
            return 'Portuguese';
        case 'ru':
            return 'Russian';
        case 'sa-arabic':
            return 'Arabic';
        case 'my':
            return 'Malay';
        case 'ph-tg':
            return 'Tagalog (Philippines)';
        case 'se':
            return 'Swedish';
        default:
            return ''; // Return empty string for unknown languages
    }
};
