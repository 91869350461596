import { Select } from '@eyesee/eyesee-hub-npm-ui';
import React from 'react';

export const ProjectsTablePagination = ({
    paginationData,
    pagination,
    setPagination,
}) => {
    const lastPage = Math.ceil(paginationData.total / pagination.perPage);
    const { perPage, page: currentPage } = pagination;
    const pageOptions = [5, 10, 20, 50, 100];
    const noPages = paginationData.total === 0;

    const onSetPerPage = (e) =>
        setPagination({ page: 1, perPage: +e.target.value });
    const setPage = (page) => setPagination({ page });
    const handleSetPage = (e) => {
        let newPage = +e.target.value;
        if (!e.target.value) return;
        if (e.target.value >= lastPage) {
            newPage = lastPage;
        }
        if (e.target.value < 0) {
            newPage = 1;
        }
        setPage(newPage);
    };

    return (
        <div className="pagination-wrapper">
            <div className="per-page">
                <Select
                    options={pageOptions}
                    value={perPage}
                    onChange={onSetPerPage}
                    name="perPage"
                />
                <span className="active">items per page </span>
            </div>
            <div className="pagination">
                <button
                    className="btn btn-small eyesee-blue white-text"
                    disabled={currentPage === 1}
                    onClick={() => setPage(1)}
                >
                    First Page
                </button>
                <button
                    className="btn btn-small eyesee-blue white-text"
                    disabled={currentPage === 1}
                    onClick={() => setPage(currentPage - 1)}
                >
                    Previous
                </button>
                <div className="page-input">
                    <input
                        disabled={noPages}
                        className={'browser-default'}
                        type="number"
                        value={currentPage}
                        onClick={(e) => e.target.select()}
                        onChange={handleSetPage}
                        name="perPage"
                    />
                    <span>/ {lastPage}</span>
                </div>
                <button
                    className="btn btn-small eyesee-blue white-text"
                    disabled={noPages || currentPage === lastPage}
                    onClick={() => setPage(currentPage + 1)}
                >
                    Next
                </button>
                <button
                    className="btn btn-small eyesee-blue white-text"
                    disabled={noPages || currentPage === lastPage}
                    onClick={() => setPage(lastPage)}
                >
                    Last Page
                </button>
            </div>
        </div>
    );
};
