import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner.jsx';
import Cells from './Cells';
import ProjectDetails from './ProjectDetails.jsx';
import StimuliOverview from './StimuliOverview';
import Tasks from './Tasks';
import useProject from '../../hooks/useProject';
import useUserData from '../../hooks/useUserData';
import { notify } from '../../notify';
import ProjectContext from './../../context/ProjectContext';
import AfterSyncInfoContext from './../../context/AfterSyncInfoContext';
import useAfterSyncSortInfo from '../../hooks/useAfterSyncSortInfo.js';
import { validateProject, isProjectValid } from '../../helpers/validators.js';
import './../../typedefs';
import { findAllSameTaskNames } from '../../helpers/getters.js';
import { api } from '../../api/index.js';
import Navigation from './../Navigation/Navigation';

export default function Project({ ...routeParams }) {
    let {
        canSortTasksAndGroups,
        setCanSortTasksAndGroups,
        isProjectSynced,
        setIsProjectSynced,
    } = useAfterSyncSortInfo(false);

    let [isSpinning, setIsSpinning] = useState(true);
    let [languages, setLanguages] = useState([]);
    let { user, checkUser } = useUserData();
    let { project, setProject, saveProject, updateProject, fetchProject } =
        useProject();
    let project_id = React.useRef();
    let saveProjectIconRef = React.useRef();

    useEffect(() => {
        project_id.current = routeParams.match.params.id;
        Promise.all([
            fetchProject(project_id.current),
            checkUser(),
            api.getAllLanguages(),
        ])
            .then((responses) => {
                const allLanguages = responses[2].data.message.languages;
                // the following translations are currently up to date
                const allowedLanguages = [
                    'cn-simplified',
                    'fr',
                    'de',
                    'en',
                    'es-sp',
                    'id',
                    'pt-br',
                    'it',
                    'ko-kr',
                    'tr',
                    'th',
                    'nl',
                    'jp',
                    'pl',
                    'pt-pt',
                    'ru',
                    'sa-arabic',
                    'my',
                    'ph-tg',
                    'se',
                ];
                const languages = allLanguages
                    .filter((language) => allowedLanguages.includes(language))
                    .sort();
                setLanguages(languages);
                setCanSortTasksAndGroups(true);
                setIsSpinning(false);
            })
            .catch((err) => alert(err));
    }, []);

    let onSetProject = (project) => {
        setProject(project);
        validateProjectInputData(project);
    };

    let onSaveProject = () => {
        /** @type project */
        project.tasks = project.tasks.map((/** @type task */ t) => {
            const objToReturn = {
                ...t,
                name: t.name.replaceAll(' ', '_'),
                showGenericInstructions: !!t?.showGenericInstructions,
                // showGenericTaskIntroSlides: !!t?.showGenericTaskIntroSlides
            };
            if (t.type === 'survey' || t.type === 'VS') {
                return {
                    ...objToReturn,
                    stimuli: [],
                    stimuliLogicList: [],
                    stimuliLogicGroups: [],
                };
            } else if (t.type === 'web') {
                return {
                    ...objToReturn,
                    stimuli: t.stimuli.map((stim) => ({
                        ...stim,
                        screenHeight: Number(stim.screenHeight) || 10000,
                    })),
                };
            } else {
                return objToReturn;
            }
        });

        if (!isProjectValid(project)) {
            notify.error(
                'Project could not be saved. Each task should have defined at least one stimuli logic or URL address that will be tested.',
            );
            return;
        }

        const taskNames = findAllSameTaskNames(project);
        if (taskNames.length > 0) {
            notify.error(
                `Project could not be saved. Each task should have unique task name. There is multiple tasks with this task ${
                    taskNames.length > 1 ? 'names' : 'name'
                } ${taskNames.join(', ')}.`,
            );
            return;
        }
        setIsSpinning(true);
        saveProject(project_id.current, project)
            .then((response) => {
                notify.success('The project is successfully saved!');
            })
            .catch((err) =>
                notify.error(
                    "Sorry, something went wrong - the project couldn't be updated.",
                ),
            )
            .finally(() => setIsSpinning(false));
    };

    /**
     * @param {task} taskUpdated
     */
    let updateTask = (taskUpdated) => {
        let task = project.tasks.find((t) => t.id === taskUpdated.id);

        //* Task is in list of tasks
        if (task) {
            let tasks = project.tasks.map((t) =>
                t.id === taskUpdated.id ? taskUpdated : t,
            );
            let p = { ...project, tasks };
            onSetProject(p);
        }
        //* Task is in group of tasks
        else {
            let groups = project.groups.map((group) => ({
                ...group,
                tasks: group.tasks.map((gt) =>
                    gt.id === taskUpdated.id ? taskUpdated : gt,
                ),
            }));

            let p = { ...project, groups };
            onSetProject(p);
        }
    };
    /**
     * @param {task_group} group
     */
    const updateGroup = (group) => {
        /** @type { project } */
        const newProject = {
            ...project,
            groups: project.groups?.map((g) => (g.id == group.id ? group : g)),
        };
        onSetProject(newProject);
    };

    /**
     * Called on every change in project.
     * @param {project} project
     */
    let validateProjectInputData = (project) => {
        toggleDisabledForSaveButon(validateProject(project));
    };

    /**
     * Toggles class for disabling save button
     * @param {Boolean} isValid
     */
    let toggleDisabledForSaveButon = (isValid) => {
        if (saveProjectIconRef.current) {
            if (!isValid) {
                saveProjectIconRef.current.classList.add('disabled');
                saveProjectIconRef.current.classList.remove('text-eyesee-blue');
                saveProjectIconRef.current.classList.add('text-eyesee-gray');
            } else {
                saveProjectIconRef.current.classList.remove('disabled');
                saveProjectIconRef.current.classList.add('text-eyesee-blue');
                saveProjectIconRef.current.classList.remove('text-eyesee-gray');
            }
        }
    };

    return (
        <>
            {
                <div className="project-page-wrapper">
                    <Navigation user={user} />
                    <ProjectContext.Provider
                        value={{
                            project,
                            onSetProject,
                            updateTask,
                            updateGroup,
                        }}
                    >
                        <div id="project">
                            <ProjectDetails
                                languages={languages}
                                updateProject={updateProject}
                                setIsSpinning={setIsSpinning}
                                setIsProjectSynced={setIsProjectSynced}
                            />

                            <Cells languages={languages} />

                            <AfterSyncInfoContext.Provider
                                value={{
                                    canSortTasksAndGroups,
                                    isProjectSynced,
                                }}
                            >
                                <Tasks />
                            </AfterSyncInfoContext.Provider>

                            <StimuliOverview stimuli={project.stimuli} />
                        </div>
                    </ProjectContext.Provider>

                    <div className="project-ctrl-btns">
                        <i
                            className="material-icons link text-eyesee-blue"
                            ref={saveProjectIconRef}
                            onClick={onSaveProject}
                            title="Save"
                        >
                            save
                        </i>
                    </div>
                </div>
            }
            {isSpinning ? <Spinner /> : null}
        </>
    );
}
