import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    removeTaskFromGroup,
    setAtPositionForElements,
    sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted,
    sortByAtPosition,
} from '../../helpers/at-position-helpers.js';
import { getNextNameNumber } from './../../helpers/task-naming-helpers';

import DraggableContainer from './DraggableContainer.jsx';
import TaskBody from './TaskBody.jsx';
import TaskGroup from './TaskGroup.jsx';
import TaskIcon from './TaskIcon.jsx';
import ProjectContext from './../../context/ProjectContext';
import AfterSyncInfoContext from './../../context/AfterSyncInfoContext';
import './../../typedefs';
import {
    copyTaskInRoot,
    createNewGroupObj,
    createNewTaskObj,
    deepCopy,
    findLastPositionAmongSiblings,
    randomIntFromInterval,
} from '../../helpers/index.js';
import { getAllTasksFromProject } from '../../helpers/getters.js';

export default function Tasks(props) {
    const [groupsAndTasks, setGroupsAndTasks] = useState([]);
    const { project, onSetProject, updateTask, updateGroup } =
        React.useContext(ProjectContext);
    const { canSortTasksAndGroups, isProjectSynced } =
        React.useContext(AfterSyncInfoContext);

    useEffect(() => {
        /** @type Array.<task | task_group> */
        const existingTasksWithTaskType = [
            ...project.tasks.map((t) => ({ ...t, _type: 'task' })),
            ...(project.groups
                ? project.groups.map((g) => ({ ...g, _type: 'group' }))
                : []),
        ];

        const sort =
            sortArrayWithRelevantValuesAccordingToSecondArrayWhichIsSorted(
                existingTasksWithTaskType,
                groupsAndTasks,
            );
        const sortedGroupsAndTask = sortByAtPosition(sort);
        setGroupsAndTasks(
            sortedGroupsAndTask.length > 0
                ? sortedGroupsAndTask
                : existingTasksWithTaskType,
        );
    }, [project]);

    useEffect(() => {
        if (groupsAndTasks.length > 0) {
            const sortedGroupsAndTask = sortByAtPosition(groupsAndTasks);
            setGroupsAndTasks(sortedGroupsAndTask);
        }
    }, [canSortTasksAndGroups, isProjectSynced]);

    let moveCardHandler = (dragIndex, hoverIndex) => {
        let dragTask = groupsAndTasks[dragIndex];

        if (dragTask) {
            let groupsAndTasksCopy = [...groupsAndTasks];
            let prev = groupsAndTasksCopy.splice(hoverIndex, 1, dragTask);
            groupsAndTasksCopy.splice(dragIndex, 1, prev[0]);
            setGroupsAndTasks(groupsAndTasksCopy);
            // setAtPositionForElements(null, groupsAndTasksCopy)
            const tasksAndGroupsWithPosition = setAtPositionForElements(
                project,
                { groupsAndTasksChanged: groupsAndTasksCopy },
            );
            setGroupsAndTasksInProject(
                tasksAndGroupsWithPosition.tasks,
                tasksAndGroupsWithPosition.groups,
            );
        }
    };

    /**
     * @param {Array.<task>} tasks
     * @param {Array.<task_group>} groups
     */
    const setGroupsAndTasksInProject = (tasks, groups) => {
        /** @type {project} */
        let p = { ...project, tasks, groups };
        onSetProject(p);
    };
    /**
     * @param {task} task
     */
    let deleteTask = (task) => {
        /** @type { Array.<task | task_group>} */
        const newGroupsAndTasks = groupsAndTasks.filter((t) => t.id != task.id);
        setGroupsAndTasks(newGroupsAndTasks);
        // setAtPositionForElements(null, newGroupsAndTasks)
        const tasksAndGroupsWithPosition = setAtPositionForElements(project, {
            groupsAndTasksChanged: newGroupsAndTasks,
        });
        setGroupsAndTasksInProject(
            tasksAndGroupsWithPosition.tasks,
            tasksAndGroupsWithPosition.groups,
        );
    };

    let createTask = () => {
        const atPosition = findLastPositionAmongSiblings(groupsAndTasks);
        const newTask = createNewTaskObj(project, atPosition);

        setGroupsAndTasks((prevState) => [...prevState, newTask]);
        //! This is needed because setGroupsAndTasks overwrites previously set task
        // setTimeout(() => {
        const tasksAndGroupsWithPosition = setAtPositionForElements(project, {
            groupsAndTasksChanged: [...groupsAndTasks, newTask],
        });
        setGroupsAndTasksInProject(
            tasksAndGroupsWithPosition.tasks,
            tasksAndGroupsWithPosition.groups,
        );
        //setGroupsAndTasksInProject(tasks, groups)
        // }, 0)
    };

    const createGroup = () => {
        /** @type { project } */
        let newProject = { ...project };
        const atPosition = findLastPositionAmongSiblings(groupsAndTasks);
        const newGroup = createNewGroupObj(project, atPosition);

        newProject.groups = newProject?.groups?.length
            ? [...newProject.groups, newGroup]
            : [newGroup];
        setGroupsAndTasks((prevState) => [...prevState, newGroup]);
        onSetProject(newProject);
    };

    /**
     * @param {task_group} group
     * @param {Array.<task>} tasks
     */
    const addTaskToGroup = (group, tasks) => {
        const newGroup = { ...group };
        newGroup.tasks = tasks;
        updateGroup(newGroup);
        const groups = project.groups.map((g) =>
            g.id == newGroup.id ? newGroup : g,
        );
        setGroupsAndTasksInProject(project.tasks, groups);
    };
    /**
     * @param {task_group} groupToDelete
     */
    const deleteGroup = (groupToDelete) => {
        const newGroupsAndTasks = groupsAndTasks.filter(
            (t) => t.id != groupToDelete.id,
        );
        setGroupsAndTasks(newGroupsAndTasks);

        const tasksAndGroupsWithPosition = setAtPositionForElements(project, {
            groupsAndTasksChanged: newGroupsAndTasks,
        });
        setGroupsAndTasksInProject(
            tasksAndGroupsWithPosition.tasks,
            tasksAndGroupsWithPosition.groups,
        );
    };

    /**
     * @param {task | task_group | task_stimuli_logic | task_stimuli_logic_group} element
     * @returns { Function }
     */
    const pinElement = (element) => (e, isPinned, updateFn) => {
        //This is not mistake, using closure for saving element which will be targeted for pin
        e.stopPropagation();
        // e.nativeEvent.stopImmediatePropagation()
        const newEl = { ...element };
        newEl._isPinned = isPinned;

        updateFn(newEl, newEl.id);
        const tasksAndGroupsWithPosition = setAtPositionForElements(project, {
            pinnedElement: newEl,
            groupsAndTasks: groupsAndTasks,
        });
        setGroupsAndTasksInProject(
            tasksAndGroupsWithPosition.tasks,
            tasksAndGroupsWithPosition.groups,
        );
    };
    /**
     * @param {task} task
     */
    const removeFromGroup = (task) => {
        removeTaskFromGroup(project, task).then((sortedGroupsAndTask) => {
            setGroupsAndTasks(sortedGroupsAndTask);
            setGroupsAndTasksInProject(
                sortedGroupsAndTask.filter((el) => el._type === 'task'),
                sortedGroupsAndTask.filter((el) => el._type === 'group'),
            );
        });
    };
    /**
     * @param {Event} e
     * @param { task | task_group } elementToBeCoppied
     */
    function copyElement(e, elementToBeCoppied) {
        e.stopPropagation();
        copyTaskInRoot(project, elementToBeCoppied, groupsAndTasks).then(
            (copiedElement) => {
                setGroupsAndTasks((prevState) => [...prevState, copiedElement]);
                if (elementToBeCoppied._type == 'group') {
                    setGroupsAndTasksInProject(project.tasks, [
                        ...project.groups,
                        copiedElement,
                    ]);
                }

                if (elementToBeCoppied._type == 'task') {
                    setGroupsAndTasksInProject(
                        [...project.tasks, copiedElement],
                        project.groups,
                    );
                }
            },
        );
    }

    return (
        <div className="segment tasks">
            <div className="flex space-between align-center segment-title">
                <div className="flex space-between w-full">
                    <div className="flex align-center">
                        <i className="material-icons">help_outline</i>
                        <h6>Tasks and Groups</h6>
                    </div>
                    <div>
                        <button
                            className={`btn btn-small eyesee-red-button white-text create-button--move-right ${
                                !!project.ptIntegration && 'events-none'
                            }`}
                            onClick={createGroup}
                        >
                            Create Group
                        </button>
                        <button
                            className={`btn btn-small eyesee-blue white-text create-button--move-right ${
                                !!project.ptIntegration && 'events-none'
                            }`}
                            onClick={createTask}
                        >
                            Create Task
                        </button>
                    </div>
                </div>
            </div>

            <DndProvider backend={HTML5Backend}>
                <ul className="task-list segment-section">
                    {groupsAndTasks?.map((el, index) =>
                        el ? (
                            <DraggableContainer
                                // hasDragIcon={true}
                                // canBePined={true}
                                // hasDeleteIcon={true}
                                // hasCopyIcon={true}
                                element={el}
                                key={el.id}
                                index={index}
                                deleteFn={
                                    el._type == 'task'
                                        ? deleteTask
                                        : deleteGroup
                                }
                                updateFn={
                                    el._type == 'task'
                                        ? updateTask
                                        : updateGroup
                                }
                                copyFn={copyElement}
                                moveCardHandler={moveCardHandler}
                                hasDragIcon={!!!project.ptIntegration}
                                draggableType={el._draggableType}
                                pinElement={pinElement(el)} //This is not mistake, using closure for saving element which will be targeted for pin
                                canBePined={!!!project.ptIntegration}
                                hasDeleteIcon={!!!project.ptIntegration}
                                hasCopyIcon={!!!project.ptIntegration}
                                iconNextToName={
                                    el._type == 'task' ? (
                                        <TaskIcon taskType={el.type}></TaskIcon>
                                    ) : (
                                        <i className="material-icons task-icon text-eyesee-gray">
                                            <span className="material-icons">
                                                ballot
                                            </span>
                                        </i>
                                    )
                                }
                            >
                                {el._type == 'task' ? (
                                    <TaskBody
                                        task={el}
                                        // project={project}
                                        setGroupsAndTasksInProject={
                                            setGroupsAndTasksInProject
                                        }
                                        type={el._type}
                                    />
                                ) : (
                                    <TaskGroup
                                        group={el}
                                        // project={project}
                                        setGroupsAndTasksInProject={
                                            setGroupsAndTasksInProject
                                        }
                                        addTaskToGroup={addTaskToGroup}
                                        removeFromGroup={removeFromGroup}
                                    />
                                )}
                            </DraggableContainer>
                        ) : null,
                    )}
                </ul>
            </DndProvider>
        </div>
    );
}
