import { api } from './index';
import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

export const fetchProjects = (params) => {
    return api.fetchProjects(params).then((response) => {
        let allProjects = response.data.message.projects.data;
        let pagination = getPaginationInfoFromResponse(response);
        return Promise.resolve({
            allProjects,
            languages: response.data.message.languages,
            pagination,
        });
    });
};

const getPaginationInfoFromResponse = (response) => {
    return {
        current_page: response.data.message.projects.current_page,
        first_page_url: response.data.message.projects.first_page_url,
        from: response.data.message.projects.last_page,
        last_page_url: response.data.message.projects.last_page_url,
        next_page_url: response.data.message.projects.next_page_url,
        path: response.data.message.projects.path,
        per_page: response.data.message.projects.per_page,
        prev_page_url: response.data.message.projects.prev_page_url,
        to: response.data.message.projects.to,
        total: response.data.message.projects.total,
    };
};

const storeUserData = (userData) => {
    window.localStorage.setItem('user', JSON.stringify(userData));
};

export const getUserData = () => {
    let userData = window.localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
};

export const checkUser = () => {
    return api
        .checkUser()
        .then((response) => {
            let userData = {
                id: response.data.user_id,
                name: response.data.user_name,
                img: `${
                    process.env.REACT_APP_EYESEEHUB
                }images/employees/${response.data.user_name.replace(
                    / /g,
                    '_',
                )}.jpg`,
            };
            storeUserData(userData);
            return Promise.resolve(userData);
        })
        .catch((err) => Promise.reject(err));
};
