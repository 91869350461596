import React, { useState, useEffect } from 'react';

export default function TaskIcon({ taskType }) {
    let [icon, setIcon] = useState('');

    useEffect(() => {
        let i = null;
        switch (taskType) {
            case 'watch':
                i = 'filter';
                break;
            case 'video':
                i = 'live_tv';
                break;
            case 'click':
                i = 'mouse';
                break;
            case 'survey':
                i = 'assignment';
                break;
            case 'VS':
                i = 'view_list';
                break;
            case 'web':
                i = 'language';
                break;
            default:
                i = 'filter';
                break;
        }
        setIcon(i);
    }, [taskType]);

    return <i className="material-icons task-icon text-eyesee-gray">{icon}</i>;
}
