import React, { useState, cloneElement } from 'react';

/**
 * Wrapper component for providing validation for inputs
 * @component
 * @param {ReactElement} children
 * @param {function} validator - validator function, should return an error message or an empty string if the input is valid
 * @param {function} [onInvalid] - failed validation callback
 * @param {function} [onValid] - successful validation callback
 * @param {Object} [style] - style for error message
 * @param {Object} [otherProps] - other props to pass to the input
 * @param {Object} [wrapperClassname] -
 */
const InputValidation = ({
    children,
    validator,
    onInvalid = () => {},
    onValid = () => {},
    style = {},
    otherProps = {},
    wrapperClassname
}) => {
    const [validationError, setValidationError] = useState('');
    const errorStyle = { color: 'red', position: 'absolute', ...style };

    const handleChange = (e) => {
        const validation = validator(e);
        setValidationError(validation);
        if (validation !== '') onInvalid(children.props.name);
        else onValid(children.props.name);
    };

    return (
        <div onChange={handleChange} className={wrapperClassname}>
            {cloneElement(children, otherProps)}
            <div style={errorStyle} className="input-validation-message">
                {validationError}
            </div>
        </div>
    );
};

export default InputValidation;
