import React, { useEffect } from 'react';
import { Tabs } from 'materialize-css';
import Spinner from '../Spinner.jsx';
import ProjectsTable from './ProjectsTable.jsx';
import { token } from '../../token';
import Modal from '../Modal/Modal';
import ConfirmationModal from '../Modal/ConfirmationModal';
import CreateProject from '../Modal/CreateProject';
import useModal from '../Modal/useModal';
import { debounce } from 'lodash';
import { ProjectsTablePagination } from './ProjectsTablePagination';
import { useProjects } from './useProjects';
import { notify } from '../../notify';
import { queryClient } from '../../api/query';
import { tabs } from './utils';
import Navigation from './../Navigation/Navigation'

export default function Projects() {
    let { isModalVisible, showModal, hideModal, modalComponent } = useModal();

    const queryCallbacks = {
        createProject: {},
        deleteProject: {
            onSettled: (data, error) => {
                if (!error) {
                    notify.success('The project is successfully deleted!');
                    queryClient.invalidateQueries('projects').then(() => {
                        queryClient.removeQueries('projects');
                        setPagination((pagination) => ({
                            ...pagination,
                            page: 1,
                        }));
                        hideModal();
                    });
                } else {
                    notify.error(error);
                    notify.error("Sorry, the project couldn't be deleted.");
                    hideModal();
                }
            },
        },
        archiveProject: {
            onSettled: (data, error, params) => {
                if (!error) {
                    queryClient.invalidateQueries('projects').then(() => {
                        queryClient.removeQueries('projects');
                        notify.success(
                            `Project is successfully ${
                                params[1] ? '' : 'un'
                            }archived!`,
                        );
                    });
                } else {
                    notify.error(error);
                }
            },
        },
        getProjects: {
            onSuccess: ({ languages }) => {
                localStorage.setItem(
                    'eyesee_platform_languages',
                    JSON.stringify(languages),
                );
            },
        },
    };

    const {
        state: { spinner, filters, pagination, tab },
        projects,
        isPreviousData,
        isFetching,
        user,
        setPagination,
        setFilters,
        setTab,
        deleteProject,
        archiveProject,
    } = useProjects({ queryCallbacks });

    const showProgress = isFetching || isPreviousData;

    const initializeTabs = () =>
        Tabs.init(document.getElementsByClassName('tabs')[0], {});
    useEffect(initializeTabs, []);

    if (spinner || !projects || !user) return <Spinner />;

    let onTabChange = (tab) => {
        setTab(tab);
        setFilters({ activeTab: tab, isArchived: tab === 'archived' ? 1 : 0 });
        setPagination({ ...pagination, page: 1 });
    };

    const openCreateProjectModal = () =>
        showModal(<CreateProject hideModal={hideModal} />);
    const onDeleteProject = (project) => deleteProject({ id: project.id });
    const onToggleArchiveProject = (project) => {
        archiveProject([project.id, project.is_archived == 0 ? 1 : 0])
    };
    //searchWhat is here if in the future there is some other search type
    const onSearchProjects = debounce((searchWhat, searchFor) => {
        setFilters({ search: searchFor });
        setPagination({ page: 1 });
    }, 350);
    const onSortProjects = (criteria, order) =>
        setFilters({
            sortTable: criteria,
            ascOrDesc: order,
        });
    const openDeleteProjectModal = (project) => {
        showModal(
            <ConfirmationModal
                title="Delete project"
                text={`You are about to delete project ${project.id}. This action is irreversible - all the project's data on this platform will be permanently lost. Are you sure you want to proceed?`}
                confirmText="Delete"
                disableOnConfirm="true"
                onConfirm={() => onDeleteProject(project)}
                hideModal={() => hideModal()}
            />,
        );
    };

    const mappedTabs = tabs.map((tab) => (
        <li
            className="tab col s3"
            onClick={() => onTabChange(tab.name)}
            key={tab.name}
        >
            <a
                href={`#${tab.name}`}
                className={tab.name === filters.activeTab ? 'active' : ''}
            >
                {tab.label}
            </a>
        </li>
    ));

    return (
        <div className="projects">
            <Modal
                isVisible={isModalVisible}
                component={modalComponent}
                hide={hideModal}
            />
            <Navigation user={user} appName="eyesee-platform" />
            <div className="page-wrapper">
                <div className="projects-header">
                    <div className="col s12">
                        <ul className="tabs">{mappedTabs}</ul>
                    </div>
                    <div className="projects-header-section">
                        <button
                            className="btn btn-small eyesee-blue white-text"
                            onClick={openCreateProjectModal}
                        >
                            Add project
                        </button>
                        <a
                            href={`${
                                process.env.REACT_APP_API_URL
                            }stats/projects?t=${token.getEncoded()}`}
                            title="Statistics"
                            target="_blank"
                        >
                            <i className="material-icons link text-eyesee-gray">
                                bar_chart
                            </i>
                        </a>
                    </div>
                </div>
                <ProjectsTable
                    projects={projects?.allProjects}
                    searchAndSortFilters={filters}
                    onToggleArchiveProject={onToggleArchiveProject}
                    openCreateProjectModal={openCreateProjectModal}
                    openDeleteProjectModal={openDeleteProjectModal}
                    onSearchProjects={onSearchProjects}
                    onSortProjects={onSortProjects}
                />
                {showProgress && (
                    <div className="progress">
                        <div className="indeterminate" />
                    </div>
                )}
                <ProjectsTablePagination
                    pagination={pagination}
                    setPagination={setPagination}
                    paginationData={projects?.pagination}
                />
            </div>
        </div>
    );
}
