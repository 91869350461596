import { useReducer } from 'react';
import { useMutation, useQuery } from 'react-query';
import { checkUser, fetchProjects, getUserData } from '../../api/query';
import { api } from '../../api';

const initialState = {
    spinner: false,
    filters: {
        activeTab: 'active',
        sortTable: 'created_at',
        ascOrDesc: 'desc',
    },
    pagination: {
        perPage: 10,
        page: 1,
    },
    tab: 'active',
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_SPINNER':
            return { ...state, spinner: payload };
        case 'SET_PAGINATION':
            return {
                ...state,
                pagination: { ...state.pagination, ...payload },
            };
        case 'SET_FILTERS':
            return { ...state, filters: { ...state.filters, ...payload } };
        case 'SET_TAB':
            return { ...state, tab: payload };
    }
};

export const useProjects = ({ queryCallbacks }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { filters, pagination, tab } = state;

    const params = {
        page: pagination.page,
        is_archived: tab === 'archived' ? 1 : 0,
        limit: pagination.perPage,
        ...filters,
    };
    const { data: user } = useQuery('userData', checkUser, {
        keepPreviousData: true,
        initialData: getUserData(),
        ...queryCallbacks.getUser,
    });

    const {
        data: projects,
        isFetching,
        isPreviousData,
    } = useQuery(['projects', params], () => fetchProjects(params), {
        disabled: !user,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        ...queryCallbacks.getProjects,
    });

    const deleteProject = useMutation('deleteProject', api.deleteProject, {
        ...queryCallbacks.deleteProject,
    });

    const archiveProject = useMutation(
        'mutateProject',
        (params) => api.changeArchivedStatus(...params),
        {
            ...queryCallbacks.archiveProject,
        },
    );

    return {
        state,
        user,
        projects,
        isFetching,
        isPreviousData,
        archiveProject: archiveProject.mutate,
        deleteProject: deleteProject.mutate,
        setSpinner: (isSpinning) =>
            dispatch({ type: 'SET_SPINNER', payload: isSpinning }),
        setPagination: (pagination) =>
            dispatch({ type: 'SET_PAGINATION', payload: pagination }),
        setFilters: (filters) =>
            dispatch({ type: 'SET_FILTERS', payload: filters }),
        setTab: (tab) => dispatch({ type: 'SET_TAB', payload: tab }),
    };
};
