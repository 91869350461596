import React, { useState, useRef } from 'react';
import ProjectContext from '../../context/ProjectContext';
import InputToggle from '../InputToggle.jsx';

export default function CellOptions({ cell, updateCell, i }) {
    const { project, onSetProject } = React.useContext(ProjectContext);
    const [valueRange, setValueRange] = useState(cell.videoQualityRange);
    const [faceIlluminationRange, setFaceIlluminationRange] = useState(
        cell.faceIllumination || 40,
    );
    const cellWrapperRef = useRef(null);

    const faceIlluminationPointerPosition = Math.floor(
        (faceIlluminationRange * 100) / 255,
    );
    const videoQualityPointerPosition = Math.floor((valueRange * 100) / 100);

    const getMarginPosition = (videoQualityPointerPosition) => {
        if (
            videoQualityPointerPosition >= 0 &&
            videoQualityPointerPosition <= 20
        ) {
            return 8;
        } else if (
            videoQualityPointerPosition >= 21 &&
            videoQualityPointerPosition <= 40
        ) {
            return 12;
        } else if (
            videoQualityPointerPosition >= 41 &&
            videoQualityPointerPosition <= 60
        ) {
            return 16;
        } else if (
            videoQualityPointerPosition >= 61 &&
            videoQualityPointerPosition <= 80
        ) {
            return 20;
        } else if (
            videoQualityPointerPosition >= 81 &&
            videoQualityPointerPosition <= 100
        ) {
            return 24;
        }
    };

    const handleVideoQualityChange = (e) => {
        setValueRange(e.target.value);
        updateCell(e, i);
    };

    const handleFaceIlluminationSliderChange = (e) => {
        setFaceIlluminationRange(e.target.value);
        updateCell(e, i);
    };

    function updateCellCheckCondition(prop, checked) {
        let p = { ...project };
        let cellsUpdated = [...p.cells];
        cellsUpdated[i][prop] = checked;

        p.cells = cellsUpdated;
        onSetProject(p);
    }

    return (
        <div className="row">
            <div className="col s2">
                <div className="cell-wrapper">
                    <label>Processing options</label>
                </div>
                <div className="cell-wrapper radio-buttons">
                    <select
                        className="browser-default transparent"
                        style={{ width: '100%' }}
                        value={+cell.processingOption}
                        name="processingOption"
                        onChange={(e) => updateCell(e, i)}
                    >
                        <option value="0">L2CS</option>
                        <option value="1">PL Gaze</option>
                    </select>
                </div>
            </div>

            <div className="col s5">
                <div className="row">
                    <div className="col s3">
                        <InputToggle
                            isChecked={cell.faceSize}
                            handleChange={(checked) =>
                                updateCellCheckCondition('faceSize', checked)
                            }
                            labels={['Face size']}
                            verticalAlign
                        />
                    </div>
                    <div className="col s2">
                        <InputToggle
                            isChecked={cell.glasses}
                            handleChange={(checked) =>
                                updateCellCheckCondition('glasses', checked)
                            }
                            labels={['Glasses']}
                            verticalAlign
                        />
                    </div>
                    <div className="col s2">
                        <InputToggle
                            isChecked={cell.blinkingEnabled}
                            handleChange={(checked) =>
                                updateCellCheckCondition(
                                    'blinkingEnabled',
                                    checked,
                                )
                            }
                            labels={['Blinking']}
                            verticalAlign
                        />
                    </div>
                    <div className="col s3">
                        <InputToggle
                            isChecked={cell.videoQualityRange}
                            handleChange={(checked) =>
                                updateCellCheckCondition(
                                    'videoQualityRange',
                                    checked,
                                )
                            }
                            labels={['Video quality']}
                            verticalAlign
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col s4">
                        <InputToggle
                            isChecked={cell.clickCalibration}
                            handleChange={(checked) =>
                                updateCellCheckCondition(
                                    'clickCalibration',
                                    checked,
                                )
                            }
                            labels={['Click calibration']}
                            verticalAlign
                        />
                    </div>
                    <div className="col s4">
                        <InputToggle
                            isChecked={cell.faceIllumination}
                            handleChange={(checked) => {
                                updateCellCheckCondition(
                                    'faceIllumination',
                                    checked ? 40 : 0,
                                );
                            }}
                            labels={['Face Illumination']}
                            verticalAlign
                        />
                    </div>
                </div>
            </div>
            <div className="col s4">
                <div className="row ">
                    {cell.videoQualityRange > 0 && (
                        <div
                            className="col s12"
                            ref={cellWrapperRef}
                            style={{ marginTop: '-3px' }}
                        >
                            <label htmlFor="sliderValue">
                                Video quality minimal score %
                            </label>
                            <div className="cell-slider-container">
                                <input
                                    id="slider"
                                    type="range"
                                    min="1"
                                    max="100"
                                    step="1"
                                    name="videoQualityRange"
                                    value={valueRange}
                                    onChange={(e) =>
                                        handleVideoQualityChange(e)
                                    }
                                />
                                <span
                                    className="cell-slider-value-display"
                                    style={{
                                        left: `calc(${videoQualityPointerPosition}% - ${getMarginPosition(
                                            videoQualityPointerPosition,
                                        )}px)`,
                                    }}
                                >
                                    {valueRange}%
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="row">
                    {cell.faceIllumination > 0 && (
                        <div
                            className="col s12"
                            ref={cellWrapperRef}
                            style={{ marginTop: '-3px' }}
                        >
                            <label htmlFor="sliderValue">
                                Face Illumination minimal score %
                            </label>
                            <div className="cell-slider-container">
                                <input
                                    id="slider-illumination"
                                    type="range"
                                    min="1"
                                    max="255"
                                    step="1"
                                    name="faceIllumination"
                                    value={faceIlluminationRange}
                                    onChange={(e) =>
                                        handleFaceIlluminationSliderChange(e)
                                    }
                                />
                                <span
                                    className="cell-slider-value-display"
                                    style={{
                                        left: `calc(${faceIlluminationPointerPosition}% - ${getMarginPosition(
                                            faceIlluminationPointerPosition,
                                        )}px)`,
                                    }}
                                >
                                    {Math.round(
                                        (faceIlluminationRange / 255) * 100,
                                    )}
                                    %
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
