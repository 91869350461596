import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slides from './Slides/Slides.jsx';
import InputToggle from '../InputToggle.jsx';
import { notify } from './../../notify';
import ConfirmationModal from '../Modal/ConfirmationModal.jsx';
import useModal from '../Modal/useModal.js';
import Modal from '../Modal/Modal.jsx';
import { project as projectHelper } from './../../project';
import ProjectContext from './../../context/ProjectContext';
import { Link } from 'react-router-dom';
import { token } from '../../token.js';

import {
    findStimulisForStimuliLogic,
    getAlreadyDefinedStimulisIfNotChanged,
    notifyAndDetermineChangeForStimuliLogic,
    notifyAndDetermineChangeForStimuli,
    filterStimuliLogicsIfDeleted,
    addNewStimuliToTasks,
    messageDisplayLength,
} from '../../helpers/sync-notification-helpers.js';
import { isValidHttpUrl, validateElement } from '../../helpers/validators.js';
import {
    areAllStimulisWatermarked,
    randomIntFromInterval,
} from '../../helpers/index.js';
import Select from '../ui/Select.jsx';

export default function ProjectDetails({
    languages,
    setIsSpinning,
    setIsProjectSynced,
}) {
    let { isModalVisible, showModal, hideModal, modalComponent } = useModal();
    const { project, onSetProject } = React.useContext(ProjectContext);
    const [redirectAndCancelLinkStyle, setRedirectAndCancelLinkStyle] =
        useState({ color: '' });

    // If referrer link is enabled, redirect and cancel link should be hidden and disabled on project level
    useEffect(() => {
        if (project.referrerLink)
            setRedirectAndCancelLinkStyle({ color: 'transparent' });
        else {
            setRedirectAndCancelLinkStyle({ color: '' });
        }
    }, [project.referrerLink]);

    let updateCellProperty = (propertyName, propertyValue) => {
        let cells = [...project.cells];
        cells.forEach((cell) => (cell[propertyName] = propertyValue));
        return cells;
    };
    /**
     * @param {Event} e
     */
    let onChange = (e) => {
        /** @type project */
        let p = { ...project };
        p.cells = updateCellProperty(e.target.name, e.target.value);
        p[e.target.name] = e.target.value;
        onSetProject(p);
        validateElement(e, ['cancelLink', 'redirectLink'], (string) =>
            isValidHttpUrl(string, true),
        );
    };
    /**
     * @param {String} name
     * @param {Boolean} checked
     */
    let toggleReferrerLinkProperty = (name, checked) => {
        /** @type project */
        let p = { ...project };
        p.cells = updateCellProperty(name, checked);
        p[name] = checked;
        onSetProject(p);
    };
    /**
     * @param {String} name
     * @param {Boolean} checked
     */
    let toggleProjectProperty = (name, checked) => {
        /** @type project */
        let p = { ...project };
        p[name] = checked;
        onSetProject(p);
    };

    let onSetActive = (checked) => {
        /** @type project */
        let p = { ...project };
        p.active = checked;
        p.cells.forEach((cell) => (cell.active = p.active));
        onSetProject(p);
    };

    /**
     *
     * @param {project_cell[]} newCellsList
     * @param {task} task
     * @param {*} messages
     */
    const generateNewLinksForVS = (newCellsList, task, messages) => {
        const { links, name } = task;

        const numOfCells = Math.abs(newCellsList.length - links.length);
        if (numOfCells > 0) {
            messages.push({
                name,
                message:
                    newCellsList.length > links.length
                        ? `${numOfCells} links have been added to the VS tasks`
                        : `${numOfCells} links have been deleted from VS tasks`,
                type: 'deleted',
            });
        }

        return newCellsList.map((cell) => ({
            cell: cell.name,
            path: links?.find((l) => l.cell === cell.name)
                ? links?.find((l) => l.cell === cell.name)?.path
                : process.env.REACT_APP_VS_URL,
        }));
    };

    const parseResponseAndUpdateProjectData = ({ data: sdtData }) => {
        notify.dismissAllToasts(
            'Swipe this to dismiss all notifications!',
            2220000,
        );

        // notify.deleted('All exit triggers in all stimuli are reseted to click on exit button! ', messageDisplayLength)

        const newCellsList = projectHelper.mergeNewListOfCellsFromSDT(
            sdtData,
            project,
        );

        const newStimuliLogicList =
            projectHelper.mergeNewStimuliLogicListFromSDT(sdtData);
        const newStimuliList =
            projectHelper.mergeNewListOfStimuliFromSDT(sdtData);

        notifyAndDetermineChangeForStimuliLogic(project, sdtData);
        notifyAndDetermineChangeForStimuli(project, sdtData);

        //* ADDING NEW/CHANGED STIMULI TO TASKS AND SEARCHING FOR DELETED / CHANGED STIMULI TO DISPLAY MESSAGE
        /** @type Array.<task> */
        const tasks = project.tasks.map((t) => ({
            ...t,
            stimuli:
                t.stimuli && t.stimuli?.length > 0
                    ? t.stimuli
                          .map((s) => addNewStimuliToTasks(s, newStimuliList))
                          .filter((el) => !!el)
                    : [],
        }));

        /** @type Array.<task_group> */
        const groups = project.groups.map((g) => ({
            ...g,
            tasks: g.tasks.map((t) => ({
                ...t,
                stimuli:
                    t.stimuli && t.stimuli?.length > 0
                        ? t.stimuli
                              .map((s) =>
                                  addNewStimuliToTasks(s, newStimuliList),
                              )
                              .filter((el) => !!el)
                        : [],
            })),
        }));

        const messagesAboutStimuliLogicCreation = [];
        // For new stimuli added for stimuliLogic that was already selected
        const newTasksWithNewData = tasks.map((t) => {
            const newStimuliListWithNewAddedStimuliForStimuliLogic =
                findStimulisForStimuliLogic(
                    newStimuliLogicList,
                    newStimuliList,
                );
            //* Taking the old stimulis if they stayed the same
            const newStimulis = getAlreadyDefinedStimulisIfNotChanged(
                t,
                newStimuliListWithNewAddedStimuliForStimuliLogic,
            ); //* otherwise create new

            const { stimuliList, stimuliListInGroup } =
                filterStimuliLogicsIfDeleted(
                    project,
                    t,
                    newStimulis,
                    messagesAboutStimuliLogicCreation,
                );
            const slg = t.stimuliLogicGroups.map((g) => ({
                ...g,
                stimuliLogicList: stimuliListInGroup,
            }));

            if (t.type === 'VS') {
                t.links = generateNewLinksForVS(
                    newCellsList,
                    t,
                    messagesAboutStimuliLogicCreation,
                );
            }

            return {
                ...t,
                stimuli: newStimulis,
                stimuliLogicList: stimuliList,
                stimuliLogicGroups: slg,
            };
        });

        const newGroupsWithNewData = groups.map((g) => ({
            ...g,
            tasks: g.tasks.map((t) => {
                const newStimuliListWithNewAddedStimuliForStimuliLogic =
                    findStimulisForStimuliLogic(
                        newStimuliLogicList,
                        newStimuliList,
                    );
                //* Taking the old stimulis if they stayed the same
                const newStimulis = getAlreadyDefinedStimulisIfNotChanged(
                    t,
                    newStimuliListWithNewAddedStimuliForStimuliLogic,
                ); //* otherwise create new

                const { stimuliList, stimuliListInGroup } =
                    filterStimuliLogicsIfDeleted(
                        project,
                        t,
                        newStimulis,
                        messagesAboutStimuliLogicCreation,
                    );
                const slg = t.stimuliLogicGroups.map((g) => ({
                    ...g,
                    stimuliLogicList: stimuliListInGroup,
                }));

                if (t.type === 'VS') {
                    t.links = generateNewLinksForVS(
                        newCellsList,
                        t,
                        messagesAboutStimuliLogicCreation,
                    );
                }

                return {
                    ...t,
                    stimuli: newStimulis,
                    stimuliLogicList: stimuliList,
                    stimuliLogicGroups: slg,
                };
            }),
        }));

        const messages = messagesAboutStimuliLogicCreation.map((t) => {
            return {
                ...t,
                messageDisplayLength,
                message: `${t.name}: ${t.message}!`,
            };
        });

        notify.displayMessages(messages, 2000);

        onSetProject({
            ...project,
            isWatermarked: areAllStimulisWatermarked(newStimuliList),
            cells: newCellsList,
            stimuli: newStimuliList,
            stimuliLogicList: newStimuliLogicList,
            tasks: newTasksWithNewData,
            groups: newGroupsWithNewData,
        });

        notify.success(
            'The project is now in sync with SDT! Implicit changes made to this are: ',
            1000,
        );
        setTimeout(() => {
            setIsProjectSynced(true);
        }, 0);
    };

    const openModal = () => {
        showModal(
            <ConfirmationModal
                title="Sync project with SDT"
                text={`You are about to sync project ${
                    project.id || project.name
                }. This may result in some changes in tasks that you may have created. Are you sure you want to proceed?`}
                confirmText="Sync"
                disableOnConfirm="true"
                onConfirm={() => syncWithSDT()}
                hideModal={() => hideModal()}
            />,
        );
    };

    const syncWithSDT = () => {
        setIsSpinning(true);
        const out_json = {
            1: {
                7: {
                    s: 'https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_4.jpg',
                    p: [],
                    w: true,
                },
                //  "8":{
                //   "s":"https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_6.mp4",
                //   "p":[

                //   ]
                //  },
                //  "1":{
                //     "s":"https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_1.mp4",
                //     "p":[

                //     ]
                //  }
            },
            2: {
                2: {
                    s: 'https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_7.jpg',
                    p: [],
                    w: true,
                },
                //  "8":{
                //     "s":"https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_3.jpg",
                //     "p":[

                //     ]
                //  },
                //  "1":{
                //     "s":"https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_5.jpg",
                //     "p":[

                //     ]
                //  }
            },
            3: {
                1: {
                    s: 'https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/-2021666/assets/stimuli_7.jpg',
                    p: [],
                    w: true,
                },
            },
        };

        const randomInt = randomIntFromInterval(1, 100000);
        axios
            .get(
                `https://eyesee-raw-stimuli-storage.s3-eu-west-1.amazonaws.com/${project.name}/utils/out.json?nocache=${randomInt}`,
            )
            .then(
                (sdtData) => {
                    console.log('SVE OK');
                    // parseResponseAndUpdateProjectData(sdtData);
                    parseResponseAndUpdateProjectData({ data: out_json });
                    setIsSpinning(false);
                    hideModal();
                },
                (rej) => {
                    console.log('ERROR');
                    notify.error(
                        'There was some error with syncing data from SDT, please try again later!',
                        10000,
                    );
                    console.error(rej);
                    setIsSpinning(false);
                    hideModal();
                },
            );
    };

    return (
        <div className="project-details segment">
            <Modal
                isVisible={isModalVisible}
                component={modalComponent}
                hide={hideModal}
            />

            <div className="exit-to-projects" title="Go to Projects">
                <Link to={`/?t=${token.getEncoded()}`}>
                    <span className="material-icons">exit_to_app</span>
                </Link>
            </div>

            <div className="segment-title flex align-center space-between">
                <div className="flex align-center">
                    <i className="material-icons">folder_open</i>
                    <h6>
                        {project.name} {project.isWatermarked && '-'}{' '}
                        {project.isWatermarked && (
                            <span className="project-watermarked">
                                Watermarked
                            </span>
                        )}
                    </h6>

                    <span
                        className={`material-icons sync-project ${
                            !!project.ptIntegration && 'disabled'
                        }`}
                        onClick={(e) => {
                            !!!project.ptIntegration && openModal(e);
                        }}
                        title="Sync with changes on SDT"
                    >
                        sync
                    </span>
                    {/* <button className="btn btn-small eyesee-blue white-text sync-project">
          </button> */}
                </div>

                <div>
                    <InputToggle
                        isChecked={project.active}
                        disabled={!!project.ptIntegration}
                        handleChange={onSetActive}
                        labels={['Inactive', 'Active']}
                    />
                    <br />
                </div>
            </div>

            <div className="segment-section languages">
                <label>Languages</label>
                <Select
                    value={project.language}
                    name="language"
                    onChange={onChange}
                    disabled={
                        !!project.ptIntegration ||
                        project.cells?.some(
                            (cell) => cell.language !== project.language,
                        )
                    }
                    options={languages}
                />
            </div>

            <div className="segment-section row">
                <div class="col s3">
                    <p>
                        <label>
                            <input
                                type="checkbox"
                                className="filled-in"
                                name="referrerLink"
                                checked={!!project.referrerLink}
                                onChange={(e) =>
                                    toggleReferrerLinkProperty(
                                        e.target.name,
                                        Number(e.target.checked),
                                    )
                                }
                            />
                            <span>Referrer link</span>
                        </label>
                    </p>
                </div>
                <div class="col s3">
                    <p>
                        <label>
                            <input
                                type="checkbox"
                                className="filled-in"
                                name="ptIntegration"
                                checked={!!project.ptIntegration}
                                onChange={(e) =>
                                    toggleProjectProperty(
                                        e.target.name,
                                        Number(e.target.checked),
                                    )
                                }
                            />
                            <span>PT - FC Integration</span>
                        </label>
                    </p>
                </div>
            </div>

            <div className="segment-section links flex align-center space-between">
                <div className="segment-section-left flex align-center">
                    <div className="text-input project-link">
                        <label>Redirect link</label>
                        <label className="error-msg active hidden">
                            URL must be valid and also contain http or https
                        </label>

                        <input
                            type="text"
                            name="redirectLink"
                            value={project.redirectLink}
                            onChange={onChange}
                            disabled={
                                !!project.ptIntegration ||
                                !!project.referrerLink ||
                                project.cells?.some(
                                    (cell) =>
                                        cell.redirectLink !==
                                        project.redirectLink,
                                )
                            }
                            style={redirectAndCancelLinkStyle}
                        />
                    </div>
                    <div className="text-input project-link">
                        <label>Cancel link</label>
                        <label className="error-msg active hidden">
                            URL must be valid and also contain http or https
                        </label>

                        <input
                            type="text"
                            name="cancelLink"
                            value={project.cancelLink}
                            onChange={onChange}
                            disabled={
                                !!project.ptIntegration ||
                                !!project.referrerLink ||
                                project.cells?.some(
                                    (cell) =>
                                        cell.cancelLink !== project.cancelLink,
                                )
                            }
                            style={redirectAndCancelLinkStyle}
                        />
                    </div>
                </div>

                {/* <div className="segment-section-right">
                    <p>
                        <label>
                            <input
                                type="checkbox"
                                className="filled-in"
                                name="ptIntegration"
                                checked={!!project.ptIntegration}
                                onChange={(e) =>
                                    toggleProjectProperty(
                                        e.target.name,
                                        Number(e.target.checked),
                                    )
                                }
                            />
                            <span>PT - FC Integration</span>
                        </label>
                    </p>
                </div> */}
            </div>

            <div className="segment-section">
                <Slides />
            </div>
        </div>
    );
}
