import React, { useEffect } from 'react';
import M from 'materialize-css';
import './slides.css';
import ProjectContext from './../../../context/ProjectContext';

export default function Slides({ }) {
    const { project, onSetProject } = React.useContext(ProjectContext);

    useEffect(() => {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, { accordion: false });
    }, []);

    let onChange = (e, i) => {
        let p = { ...project };
        p.slides[i].show = e.target.checked;
        onSetProject(p);
    };

    return (
        <ul className="collapsible slides">
            <li>
                <div className="collapsible-header slides-title">
                    <div className="slides-title-main">
                        <i className="material-icons">dvr</i>
                        <span>Slides</span>
                    </div>
                    <div>
                        <i className="material-icons">keyboard_arrow_down</i>
                    </div>
                </div>

                <div className="collapsible-body">
                    <ul className="collection">
                        {project.slides?.map((slide, i) => {
                            if (slide.id === 4) return;
                            return (
                                <li className="collection-item slide" key={i}>
                                    <p>{slide.label}</p>

                                    <div
                                        className={`switch ${!!project.ptIntegration && 'disabled'
                                            }`}
                                    >
                                        <label>
                                            hide
                                            <input
                                                type="checkbox"
                                                checked={slide.show}
                                                onChange={(e) => onChange(e, i)}
                                                disabled={!!project.ptIntegration}
                                            />
                                            <span className="lever"></span>
                                            show
                                        </label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </li>
        </ul>
    );
}
