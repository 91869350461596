import React from 'react';
import spinner from '../spinner.gif';

export default function Spinner() {
    return (
        <div className="spinner-wrapper flex justify-center align-center">
            <img src={spinner} alt="Please, wait" title="Please, wait" />
        </div>
    );
}
