import axios from './config.js';
import { token } from '../token.js';

let getUserData = () => {
    let userData = window.localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
};

export const api = {
    fetchProjects(params) {
        return axios.get(`/projects?t=${token.getEncoded()}`, {
            params,
        });
    },

    fetchProject(id) {
        return axios.get(
            `/project/data/${id}?t=${token.getEncoded()}`,
        );
    },
    getAllLanguages() {
        return axios.get(`/languages?t=${token.getEncoded()}`);
    },

    saveProject(project_id, data) {
        data = { ...data, t: token.getEncoded() }; // userid: `${getUserData()?.id}`,
        return axios({
            url: `project/update/${project_id}`,
            method: 'POST',
            data,
        });
    },

    createProject(project_name, initProjectJSON) {
        const data = {
            project: JSON.stringify(initProjectJSON),
            t: token.getEncoded(),
            project_name,
        }; //userid: getUserData()?.id,

        return axios({
            url: `project/create`,
            method: 'POST',
            data,
        });
    },

    deleteProject(params) {
        const data = { t: token.getEncoded() };
        return axios({
            url: `project/delete/${params.id}`,
            method: 'POST',
            data,
        });
    },

    updateProjectsTable(url, params = {}) {
        let data = { t: token.getEncoded(), ...params };
        return axios({
            url: url,
            method: 'GET',
            params: data,
        });
    },

    changeArchivedStatus(project_id, isArchived) {
        return axios({
            url: `project/archived/${project_id}`,
            method: 'POST',
            params: { t: token.getEncoded(), isArchived },
        });
    },

    changePerPageProjectsTable(url) {
        let data = { t: token.getEncoded() };
        return axios({
            url: url,
            method: 'GET',
            params: data,
        });
    },

    checkUser() {
        return axios({
            url: 'user/check',
            params: { t: token.getEncoded() },
        });
    },

    logout(data) {
        let url = `https://eyesee-hub.com/logout?t=${token.getEncoded()}&app_name=eyesee-platform`;
        window.location.href = url;
    },

    getUserImage(data) {
        return axios.get(`user/image?t=${token.getEncoded()}&user_ids[]=269`);
    },

    filterTableData(data) {
        return axios.get(
            `filterTable?limit=${data.limit}&start=${data.start}&sort=${
                data.sort
            }&sortby=${data.sortBy}&filterName=${
                data.name
            }&t=${token.getEncoded()}`,
        );
    },

    // uploadMedia (data) {
    //   data.append('t', getToken());
    //   return axios({
    //     url: 'project/file/upload',
    //     method: 'POST',
    //     headers: {'Content-type': 'multipart/form-data'},
    //     data
    //   });
    // },

    // deleteMedia (data) {
    //   data = {...data, t: getToken()};
    //   return axios({
    //     url: 'project/file/delete',
    //     method: 'POST',
    //     data
    //   });
    // },

    // deleteAllMedia (data) {
    //   data = {...data, t: getToken()};
    //   return axios({
    //     url: 'project/files/delete',
    //     method: 'POST',
    //     data
    //   });
    // },

    // createUser(data) {
    //   data = {...data, t: getToken()};
    //   return axios({
    //     url: 'user/create',
    //     method: 'POST',
    //     data
    //   });
    // },

    // syncID(data) {
    //   data = {...data, t: getToken()};
    //   return axios({
    //     url: 'createTestStimuli',
    //     method: 'GET',
    //     params: data
    //   });
    // },

    // updateHistory(data) {
    //   data = {...data, t: getToken()};
    //   return axios({
    //     url: 'user/history',
    //     method: 'POST',
    //     data
    //   });
    // }
};
