import React from 'react';

export default function Link({ link, style }) {
    let copyLink = () => navigator.clipboard.writeText(link);

    return (
        <div
            className="link-preview"
            style={style}
            onClick={(e) => e.stopPropagation()}
            islink="true"
        >
            <input
                type="text"
                value={link}
                id="link"
                onChange={() => {}}
                islink="true"
            />
            <i className="material-icons link" onClick={copyLink} islink="true">
                file_copy
            </i>

            <a href={link} target="_blank" islink="true">
                <i className="material-icons link open-link" islink="true">
                    open_in_new
                </i>
            </a>
        </div>
    );
}
