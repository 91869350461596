import React, { useState, useEffect } from 'react';
import './modal.css';

let Modal = ({ isVisible, hide, component = null }) => {
    let [modalStyle, setModalStyle] = useState({ display: 'none' });

    useEffect(toggleVisibility, [isVisible]);

    function toggleVisibility() {
        if (isVisible) {
            setModalStyle({ display: 'block' });
            return;
        }
        setModalStyle({ display: 'none' });
    }

    return (
        <div id="app-modal" style={modalStyle}>
            <div className="app-modal-overlay" onClick={hide}>
                <div
                    className="app-modal-content"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {component}
                </div>
            </div>
        </div>
    );
};

export default Modal;
