import React, { useEffect } from 'react';
import DraggableContainer from './DraggableContainer.jsx';
import TaskBody from './TaskBody.jsx';
import TaskIcon from './TaskIcon.jsx';
import ProjectContext from './../../context/ProjectContext';
import './../../typedefs';
import {
    createNewTaskObjectForGroup,
    copyTaskInGroup,
} from '../../helpers/index.js';

export default function GroupTasks({
    group,
    setGroupsAndTasksInProject,
    addTaskToGroup,
    removeFromGroup,
}) {
    const { project, updateGroup, updateTask } =
        React.useContext(ProjectContext);

    useEffect(() => {
        const newGroup = { ...group };
        if (
            newGroup.tasks?.some(
                (task) =>
                    task.showIntroslides === 1 || task.showIntroslides === true,
            ) &&
            (newGroup.showIntroslides === 1 ||
                newGroup.showIntroslides === true)
        ) {
            newGroup.showIntroslides = false;
            newGroup.introslides = [];
            updateGroup(newGroup);
        }
    }, [group]);
    /**
     * @param {Number} dragIndex
     * @param {Number} hoverIndex
     */
    let moveCardHandler = (dragIndex, hoverIndex) => {
        /** @type {task} */
        let dragTask = group.tasks[dragIndex];

        if (dragTask) {
            let tasksCopy = [...group.tasks];
            let prev = tasksCopy.splice(hoverIndex, 1, dragTask);
            tasksCopy.splice(dragIndex, 1, prev[0]);

            /** @type {task_group} */
            const newGroup = { ...group };
            newGroup.tasks = tasksCopy;
            updateGroup(newGroup);
        }
    };
    /**
     * @param {task} task
     */
    let deleteTask = (task) => {
        const newGroup = { ...group };
        newGroup.tasks = newGroup.tasks.filter((t) => t.id != task.id);
        updateGroup(newGroup);
        setGroupsAndTasksInProject(
            project.tasks,
            project.groups.map((g) => (g.id == newGroup.id ? newGroup : g)),
        );
    };
    /**
     * @param {task_group} group
     * @returns {Array.<task>}
     */
    const getTaskListInGroup = (group) =>
        group?.tasks?.length > 0 ? [...group.tasks] : [];

    let createTask = () => {
        /** @type {Array.<task>} */
        let taskList = getTaskListInGroup(group);
        const newTask = createNewTaskObjectForGroup(project);

        taskList.push(newTask);
        addTaskToGroup(group, taskList);
    };

    /**
     * @param {Event} e
     * @param { task } taskToBeCoppied
     */
    function copyTaskInsideOfGroup(e, taskToBeCoppied) {
        e.stopPropagation();

        copyTaskInGroup(project, taskToBeCoppied).then((copiedElement) => {
            let taskList = getTaskListInGroup(group);
            taskList.push(copiedElement);
            addTaskToGroup(group, taskList);
        });
    }

    return (
        <div className="segment tasks">
            <div className="flex space-between align-center">
                <span className="segment-title">
                    <i className="material-icons">help_outline</i>
                    <h6>Tasks</h6>
                </span>
                <span>
                    <button
                        className={`btn btn-small eyesee-blue white-text create-button--move-left ${
                            !!project.ptIntegration && 'events-none'
                        }`}
                        onClick={createTask}
                    >
                        Create Task
                    </button>
                </span>
            </div>

            <ul className="task-list segment-section">
                {group.tasks?.map((el, index) => (
                    <DraggableContainer
                        element={el}
                        key={el.id}
                        index={index}
                        deleteFn={deleteTask}
                        updateFn={updateTask}
                        copyFn={copyTaskInsideOfGroup}
                        moveCardHandler={moveCardHandler}
                        // type='task'
                        hasDragIcon={false}
                        type={el._type}
                        draggableType={el._draggableType}
                        canBePined={false}
                        // hasDeleteIcon={true}
                        // hasCopyIcon={true}
                        // hasRemoveFromGroupIcon={true}
                        hasDeleteIcon={!project.ptIntegration}
                        hasCopyIcon={!project.ptIntegration}
                        hasRemoveFromGroupIcon={!project.ptIntegration}
                        iconNextToName={
                            <TaskIcon taskType={el.type}></TaskIcon>
                        }
                        removeFromGroup={() => removeFromGroup(el)}
                    >
                        <TaskBody
                            task={el}
                            // project={project}
                            setGroupsAndTasksInProject={
                                setGroupsAndTasksInProject
                            }
                            type={el._type}
                        />
                    </DraggableContainer>
                ))}
            </ul>
        </div>
    );
}
