import { areAllStimuliLogicsPreloaded, isValidHttpUrl } from './validators';

export function generateOptions(taskType) {
    switch (taskType) {
        case 'watch':
            return [
                { value: 'et', label: 'ET' },
                { value: 'fc', label: 'FC' },
                { value: 'audio', label: 'Audio' },
                { value: 'recalibrate', label: 'Recalibrate' },
            ];
        case 'video':
            return [
                { value: 'et', label: 'ET' },
                { value: 'fc', label: 'FC' },
                { value: 'audio', label: 'Audio' },
                { value: 'recalibrate', label: 'Recalibrate' },
                { value: 'preload', label: 'Preload' },
            ];
        case 'click':
            return [
                { value: 'et', label: 'ET' },
                { value: 'fc', label: 'FC' },
                { value: 'audio', label: 'Audio' },
                { value: 'screen', label: 'Screen' },
                { value: 'recalibrate', label: 'Recalibrate' },
            ];
        case 'web':
            return [
                { value: 'et', label: 'ET' },
                { value: 'fc', label: 'FC' },
                { value: 'audio', label: 'Audio' },
                { value: 'clicks', label: 'Clicks' },
                { value: 'screen', label: 'Screen' },
                // {value: 'urls', label: 'URLs'},
                { value: 'recalibrate', label: 'Recalibrate' },
            ];
        default:
            return [];
    }
}

export function getDefaultOptions(task) {
    switch (task.type) {
        case 'watch':
            return ['et'];
        case 'video':
            const arr = ['et'];
            if (areAllStimuliLogicsPreloaded(task)) {
                arr.push('preload');
            }
            return arr;
        case 'click':
            return ['et', 'clicks'];
        case 'web':
            // return ['et', 'clicks', 'urls', 'screen'];
            return ['et', 'clicks', 'screen'];
        default:
            return [];
    }
}
