import React, { useState, useEffect } from 'react';
import { SpinnerSmall } from '@eyesee/eyesee-hub-npm-ui';

export default function ConfirmationModal({
    title,
    text,
    onConfirm,
    disableOnConfirm,
    hideModal,
    cancelText = 'Cancel',
    confirmText = 'Yes',
}) {
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let modalContentEl = document.querySelector('.app-modal-content');
        modalContentEl.classList.add('app-modal-confirm');

        return () => {
            modalContentEl.classList.remove('app-modal-confirm');
        };
    }, []);

    let confirm = () => {
        if (disableOnConfirm) {
            setIsLoading(true);
        }
        onConfirm();
    };

    return (
        <div className="issue-revoke">
            <div className="app-modal-header">
                <p>{title}</p>
            </div>

            <div className="app-modal-body">
                <p> {text} </p>
            </div>

            <div className="app-modal-footer btns">
                <button
                    className="btn btn-small btn-secondary"
                    onClick={hideModal}
                >
                    {cancelText}
                </button>
                <button
                    className="btn btn-small eyesee-blue loading-btn"
                    onClick={confirm}
                    disabled={isLoading}
                >
                    {isLoading ? <SpinnerSmall /> : null}
                    <span className="white-text">{confirmText}</span>
                </button>
            </div>
        </div>
    );
}
