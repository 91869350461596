import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SortableColumn } from '@eyesee/eyesee-hub-npm-ui';
import { token } from '../../token.js';

export default function ProjectsTable({
    projects,
    openDeleteProjectModal,
    onToggleArchiveProject,
    onSearchProjects,
    onSortProjects,
    searchAndSortFilters,
}) {
    return (
        <>
            <table className="projects-table striped">
                <thead>
                    <tr>
                        <th title="Press enter to search projects by name">
                            <div className={`input-field searchable`}>
                                <i className="material-icons">search</i>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) =>
                                        onSearchProjects('name', e.target.value)
                                    }
                                    name="name"
                                    defaultValue={searchAndSortFilters.search}
                                />
                            </div>
                        </th>
                        <SortableColumn
                            title="Created by"
                            onSort={onSortProjects}
                            sortBy={'user_name'}
                            sortOrder={'asc'}
                            name="user_name"
                            type="string"
                        />
                        <SortableColumn
                            title="Created at"
                            onSort={onSortProjects}
                            sortBy={'created_at'}
                            sortOrder={'asc'}
                            name="created_at"
                            type="date"
                        />
                        <th className="narrow-col">PT integration</th>
                        <th className="narrow-col"></th>
                    </tr>
                </thead>

                <tbody>
                    {projects.map((project, i) => (
                        <tr key={project.id}>
                            <td>
                                <div className="flex align-center">
                                    <div
                                        className={
                                            'project-status-indicator ' +
                                            (project.active
                                                ? 'green lighten-2'
                                                : 'red lighten-2')
                                        }
                                        title={
                                            project.active
                                                ? 'Active'
                                                : 'Inactive'
                                        }
                                    ></div>
                                    <Link
                                        to={`/project/${
                                            project.id
                                        }?t=${token.getEncoded()}`}
                                    >
                                        {project.name}
                                    </Link>
                                </div>
                            </td>
                            <td>{project.user_name || 'Unknown'}</td>
                            <td>{moment(project.created_at).format("D-MM-YYYY, h:mm:ss a")}</td>
                            <td className="narrow-col">
                                {!!project.ptIntegration ? (
                                    <i className="material-icons green-text text-lighten-2">
                                        check
                                    </i>
                                ) : (
                                    <i className="material-icons red-text text-lighten-2">
                                        close
                                    </i>
                                )}
                            </td>
                            <td className="narrow-col">
                                <span>
                                    {project.is_archived == 1 ? (
                                        <i
                                            className="material-icons grey-text text-lighten-1 link"
                                            title="Unarchive project"
                                            onClick={() =>
                                                onToggleArchiveProject(project)
                                            }
                                        >
                                            unarchive
                                        </i>
                                    ) : (
                                        <i
                                            className="material-icons grey-text text-lighten-1 link"
                                            title="Archive project"
                                            onClick={() =>
                                                onToggleArchiveProject(project)
                                            }
                                        >
                                            archive
                                        </i>
                                    )}
                                </span>
                                <span>
                                    <i
                                        className="material-icons grey-text text-lighten-1 link"
                                        title="Delete project"
                                        onClick={() =>
                                            openDeleteProjectModal(project)
                                        }
                                    >
                                        delete
                                    </i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}
