import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import M from 'materialize-css';

export default function DraggableContainer({
    index,
    moveCardHandler,
    deleteFn,
    updateFn,
    copyFn,
    element,
    pinElement,
    children,
    draggableType = null,
    hasDeleteIcon,
    hasCopyIcon,
    hasDragIcon = true,
    hasRemoveFromGroupIcon,
    removeFromGroup = null,
    canBePined,
    iconNextToName,
}) {
    let ref = useRef();

    useEffect(() => {
        let elems = document.querySelectorAll('.task.collapsible');
        M.Collapsible.init(elems, { accordion: false });
    }, []);

    let [{ isDragging }, drag] = useDrag({
        item: { index, id: element.id, type: draggableType },
        //* This was here before we had inner dnd's. After adding inner dnd problem was immediate closing of all accordions when started dragging.
        // begin: monitor => {
        //   let collapsibles = document.querySelectorAll('.collapsible-body')
        //   for (let i = 0; i < collapsibles.length; i++) {
        //     collapsibles[i].style.display = 'none';
        //   }
        // },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    let [collection, drop] = useDrop({
        accept: draggableType,
        drop(item, monitor) {
            if (!item) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            // if (dragIndex === hoverIndex) {
            //     return;
            // }
            // // Determine rectangle on screen
            // const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // // Get vertical middle
            // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // // Determine mouse position
            // const clientOffset = monitor.getClientOffset();
            // // Get pixels to the top
            // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // // Only perform the move when the mouse has crossed half of the items height
            // // When dragging downwards, only move when the cursor is below 50%
            // // When dragging upwards, only move when the cursor is above 50%
            // // Dragging downwards
            // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            //     return;
            // }
            // // Dragging upwards
            // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            //     return;
            // }
            moveCardHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    // drag(drop(ref));

    const background = isDragging ? '#f5f5f5' : 'white';

    return (
        <ul className="task collapsible" style={{ background }}>
            <li>
                <div
                    className="task-header flex align-center space-between collapsible-header"
                    ref={drop}
                >
                    <div className="task-header-left flex align-center">
                        {hasDragIcon ? (
                            <i
                                className="material-icons text-eyesee-gray drag-indicator"
                                ref={drag}
                            >
                                drag_indicator
                            </i>
                        ) : null}

                        {iconNextToName}
                        <span>{element.name || element._name}</span>
                    </div>

                    <div className="task-header-right">
                        {hasCopyIcon ? (
                            <i
                                className="material-icons-outlined text-eyesee-gray link"
                                onClickCapture={(e) => copyFn(e, element)}
                                title="Copy element"
                            >
                                content_copy
                            </i>
                        ) : null}
                        {hasDeleteIcon ? (
                            <i
                                className="material-icons text-eyesee-gray link"
                                onClick={(e) => deleteFn(element)}
                                title="Delete"
                            >
                                delete
                            </i>
                        ) : null}
                        {hasRemoveFromGroupIcon ? (
                            <i
                                className="material-icons text-eyesee-gray link"
                                onClick={(e) => removeFromGroup(element)}
                                title="Remove from group"
                            >
                                eject
                            </i>
                        ) : null}
                        {canBePined ? (
                            <i
                                className={`${
                                    element._isPinned
                                        ? 'material-icons is-pinned'
                                        : 'material-icons-outlined is-not-pinned'
                                } text-eyesee-gray link`}
                                title={
                                    element._isPinned
                                        ? 'Click to detach'
                                        : 'Click to fix position'
                                }
                                onClickCapture={(e) =>
                                    pinElement(e, !element._isPinned, updateFn)
                                }
                            >
                                push_pin
                            </i>
                        ) : null}
                    </div>
                </div>
                <div className="collapsible-body stimuli-list-in-group-container">
                    {children}
                </div>
            </li>
        </ul>
    );
}
