import React, { useContext, useEffect, useState } from 'react';
import { VirtualShoppingLink } from './VirtualShoppingLink';
import ProjectContext from '../../context/ProjectContext';

const VSURL = ({ task, disabled, cells }) => {
    if (task.type !== 'VS') return null;

    const [areVisible, setAreVisible] = useState(false);
    const { updateTask } = useContext(ProjectContext);

    useEffect(() => {
        if(!task.links) {
            updateTask({
                ...task,
                links: cells.map(cell => ({ cell:cell.name, path: process.env.REACT_APP_VS_URL}))
            })
        }
    }, [task])

    let toggleVisibility = () => setAreVisible(areVisible => !areVisible);

    return (
        <div className="text-input segment-section">
            <div className='eye-wrapper'>
            {
                areVisible ? (
                    <i
                        className="material-icons link text-eyesee-gray"
                        title="Click to Hide"
                        onClick={toggleVisibility}
                    >
                        visibility_off
                    </i>
                ) : (
                    <i
                        className="material-icons link text-eyesee-gray"
                        title="Click to Show"
                        onClick={toggleVisibility}
                    >
                        visibility
                    </i>
                )
            }
            </div>
            {
                areVisible && task?.links?.map(
                    (link) => <VirtualShoppingLink 
                        task={task} 
                        cellName={link.cell} 
                        path={link.path} 
                        disabled={disabled} 
                        updateTask={updateTask}/>
                    )  
            }
        </div>
    );
};

export default VSURL;
