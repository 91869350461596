import React from 'react';
import { useContext } from 'react';
import ProjectContext from '../../context/ProjectContext';
import {
    clearErrorMessagesInWrapper,
    displayErrorMessagesInWrapper,
    findParentElement,
    isTimeoutGreatherThanMinExposure,
    validateElement,
} from '../../helpers/validators';

export default function ClickTaskExit({ task, disabled }) {
    const { updateTask } = useContext(ProjectContext);

    if (task.type !== 'click') return null;

    function onExitTriggerChange(e) {
        const taskUpdated = { ...task };
        const wrapper = findParentElement(e.target, 'collapsible-body');

        if (e.target.value === 'clickOnStimulus') {
            taskUpdated.maxClickNum = 1;
            clearErrorMessagesInWrapper(wrapper, '.error-msg');
            delete taskUpdated.minExposureTimeout;
        } else if (e.target.value === 'clickOnExitButton') {
            taskUpdated.minExposureTimeout = 10;
            delete taskUpdated.maxClickNum;

            if (!isTimeoutGreatherThanMinExposure(taskUpdated)) {
                displayErrorMessagesInWrapper(wrapper, '.error-msg');
            }
        }

        updateTask({ ...taskUpdated, exitTrigger: e.target.value }, task.id);
    }

    function onMinimumExposureChange(e) {
        let minExposureTimeout = Number(e.target.value);
        if (isNaN(minExposureTimeout) || minExposureTimeout < 0)
            minExposureTimeout = 0;
        const updatedTask = { ...task, minExposureTimeout };

        updateTask(updatedTask);
        validateAndDisplayError(e, minExposureTimeout, updatedTask);
    }

    function validateAndDisplayError(e, minExposureTimeout, updatedTask) {
        const wrapper = findParentElement(e.target, 'collapsible-body');
        const errorMessageLabels = wrapper.querySelectorAll('.error-msg');
        e.target.value = minExposureTimeout;

        validateElement(
            e,
            ['timeout', 'minExposureTimeout'],
            (val) => val >= 0 && isTimeoutGreatherThanMinExposure(updatedTask),
            errorMessageLabels,
        );
    }

    function onMaxClicksChange(e) {
        let maxClickNum = Number(e.target.value);
        if (isNaN(maxClickNum) || maxClickNum < 1) maxClickNum = 1;
        updateTask({ ...task, maxClickNum }, task.id);
    }

    return (
        <div className="segment-section click-task-exit-wrapper">
            <label>Choose how to finish each stimulus: </label>
            <br />
            <label className="error-msg active hidden no-margin-left">
                {task.exitTrigger === 'clickOnExitButton'
                    ? 'No numbers less than 1 and min exposure must be less than stimuli timeout'
                    : ''}
            </label>

            <div className="click-task-exit">
                <select
                    className="browser-default exit-trigger"
                    value={task.exitTrigger}
                    name="exitTrigger"
                    onChange={onExitTriggerChange}
                    disabled={disabled}
                >
                    <option value="clickOnExitButton">
                        {' '}
                        click on the 'Next' button{' '}
                    </option>
                    <option value="clickOnStimulus">
                        {' '}
                        click on the stimulus{' '}
                    </option>
                </select>

                {task.exitTrigger === 'clickOnExitButton' && (
                    <p className="click-task-exit-additional">
                        <span>after minimum </span>
                        <input
                            type="number"
                            name="minExposureTimeout"
                            // value={task.minExposureTimeout || 10}
                            value={
                                task.minExposureTimeout === undefined
                                    ? 10
                                    : task.minExposureTimeout
                            }
                            onChange={onMinimumExposureChange}
                            disabled={disabled}
                            min="0"
                        />
                        <span>{`second${
                            task.minExposureTimeout !== 1 ? 's' : ''
                        }.`}</span>
                    </p>
                )}
                {task.exitTrigger === 'clickOnStimulus' && (
                    <p className="click-task-exit-additional">
                        <input
                            type="number"
                            // value={task.maxClickNum || 1}
                            value={
                                task.maxClickNum === undefined
                                    ? 1
                                    : task.maxClickNum
                            }
                            onChange={onMaxClicksChange}
                            disabled={disabled}
                            min="1"
                        />
                        <span>{`time${
                            task.maxClickNum !== 1 ? 's' : ''
                        }.`}</span>
                    </p>
                )}
            </div>
        </div>
    );
}
