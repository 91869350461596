import InputToggle from '../InputToggle';
import { InputValidation } from '../UI Elements';
import TaskType from './TaskType';
import React from 'react';
import { nameValidator } from '../../helpers/validators';
import { useContext } from 'react';
import ProjectContext from '../../context/ProjectContext';

const CommonTaskOptions = ({ onChange, task, disabled }) => {
    const isTaskWithIframe = ['web', 'VS', 'survey'].includes(task.type);
    const { updateTask } = useContext(ProjectContext);

    const toggleTaskOption = (checked, prop) => {
        let taskUpdated = { ...task };
        taskUpdated[prop] = checked;
        updateTask(taskUpdated);
    };

    const genericInstructionsToggle = (
        <div className="text-input segment-section show-generic-instructions-wrapper">
            <label>Show generic instructions</label>
            <InputToggle
                isChecked={task.showGenericInstructions}
                handleChange={(checked) =>
                    toggleTaskOption(checked, 'showGenericInstructions')
                }
                labels={['Hide', 'Show']}
                disabled={disabled}
            />
        </div>
    );

    const urlInput = (
        <div>
            <label>
                <input
                    checked={task.appendIframeParams}
                    type="checkbox"
                    disabled={disabled}
                    onChange={(e) =>
                        toggleTaskOption(e.target.checked, 'appendIframeParams')
                    }
                />
                <span>Append parameters to URL</span>
            </label>
        </div>
    );

    return (
        <div className="flex justify-between align-center">
            <div className="text-input segment-section">
                <label htmlFor="name">Name:</label>
                <InputValidation validator={nameValidator}>
                    <input
                        type="text"
                        name="name"
                        defaultValue={task.name}
                        onChange={onChange}
                        disabled
                    />
                </InputValidation>
            </div>
            <TaskType task={task} disabled={disabled} />
            {task.type !== 'group' && genericInstructionsToggle}
            {isTaskWithIframe && urlInput}
        </div>
    );
};

export default CommonTaskOptions;
