import React, { useState } from 'react';

export default function useModal() {
    let [isModalVisible, setIsModalVisible] = useState(false);
    let [modalComponent, setComponent] = useState(null);

    let showModal = (component) => {
        setIsModalVisible(true);
        setComponent(component);
    };

    let hideModal = () => {
        setIsModalVisible(false);
        setComponent(null);
    };

    return {
        isModalVisible,
        modalComponent,
        showModal,
        hideModal,
    };
}
