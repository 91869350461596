import React from 'react'
import { cloneDeep } from 'lodash';
import { urlValidator } from '../../helpers/validators'
import { InputValidation } from '../UI Elements';
import { useState } from 'react';

export const VirtualShoppingLink = ({task, cellName, disabled, path = '', updateTask}) => {
    const [url, setUrl] = useState(path)
    const [isEditing, setIsEditing] = useState(false)

    const onChange = (e) => {
        setUrl(e.target.value)
    }

    const saveNewUrl = () => {
        const updatedTask = cloneDeep(task);
        updatedTask.links = updatedTask.links.map(el => el.cell === cellName ? {...el, path: url} : el)
        
        updateTask(updatedTask)
        setIsEditing(false)
    }

    const discardChanges = () => {
        const url = task.links.find(l => cellName === l.cell).path
        setUrl(url)
        setIsEditing(false)
    }

    const turnOnEditMode = () => {
        setIsEditing(true)
    }

    return <div className='survey-task-url-wrapper' key={cellName}>
        <div className='label-wrapper'>
            <label>
                URL - {cellName}
            </label>
        </div>
        <InputValidation validator={urlValidator} wrapperClassname="survey-task-url-wrapper--input">
            <input
                type="text"
                name="iframeURL"
                // defaultValue={url}
                value={url}
                onChange={(e) => onChange(e, cellName)}
                disabled={!isEditing || disabled}
            />
        </InputValidation>


        <div className='vs-task-button-wrapper'>
        {
            isEditing ? <>
                <button className='save-btn btn btn-small eyesee-blue white-text' onClick={saveNewUrl}>Save</button>
                <button className='discard-btn btn btn-small eyesee-red-button white-text' onClick={discardChanges}>Discard</button>
            </>
            : 
            <button className='save-btn btn btn-small eyesee-blue white-text' onClick={turnOnEditMode}>Edit</button>
        } 
        </div>
    </div>
}